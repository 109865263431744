import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { generateClient, get } from "aws-amplify/api"
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, 
    MDBTableBody, MDBTableHead, MDBBtnGroup, MDBIcon,
    MDBSelect,
    MDBInput,
    MDBTooltip
     } from "mdb-react-ui-kit"
import { Link } from "react-router-dom"
import { getUrl } from "aws-amplify/storage"
import { getLocalizedText } from "../../utils/localizedText"
import * as XLSX from 'xlsx';

const client = generateClient()

export default function BookedBooths() {
    const { t, i18n } = useTranslation()

    const [events, setEvents] = useState([])
    const[booths, setBooths] = useState([])
    const[filteredBooths, setFilteredBooths] = useState([])
    const[loading, setLoading] = useState(true)
    const[searchTerm, setSearchTerm] = useState("")
    const [filters, setFilters] = useState({});

    const [carpetFilter, setCarpetFilter] = useState('all');
    const [graphicsFilter, setGraphicsFilter] = useState('all');
    const [equipmentFilter, setEquipmentFilter] = useState('all');

    const handleSearch = (value) => {
        setSearchTerm(value);
        applyFilters(value, carpetFilter);
    };

    const handleCarpetFilter = (value) => {
        setCarpetFilter(value);
        applyFilters(searchTerm, value);
    };

    const handleGraphicsFilter = (value) => {
        setGraphicsFilter(value);
        applyFilters(searchTerm, carpetFilter, value, equipmentFilter);
    };
    
    const handleEquipmentFilter = (value) => {
        setEquipmentFilter(value);
        applyFilters(searchTerm, carpetFilter, graphicsFilter, value);
    };

    const applyFilters = (search, carpetStatus, graphicsStatus, equipmentStatus) => {
        let filtered = booths;
        
        // Apply company name search
        if (search) {
            filtered = filtered.filter(booth => 
                booth.company.name.toLowerCase().includes(search.toLowerCase())
            );
        }
    
        // Apply carpet filter
        if (carpetStatus && carpetStatus !== 'all') {
            filtered = filtered.filter(booth => {
                const carpetEquipment = booth.equipment.items.find(item => 
                    item.orderLineItem?.product?.subType === "carpet"
                );
    
                switch (carpetStatus) {
                    case 'selected':
                        return carpetEquipment && carpetEquipment.data;
                    case 'notSelected':
                        return carpetEquipment && !carpetEquipment.data;
                    case 'notAvailable':
                        return !carpetEquipment;
                    default:
                        return true;
                }
            });
        }
    
        // Apply graphics wall filter
        if (graphicsStatus && graphicsStatus !== 'all') {
            filtered = filtered.filter(booth => {
                const graphicsEquipment = booth.equipment.items.find(item => 
                    item.orderLineItem?.product?.subType === "display_print"
                );
    
                switch (graphicsStatus) {
                    case 'uploaded':
                        return graphicsEquipment && graphicsEquipment.data;
                    case 'notUploaded':
                        return graphicsEquipment && !graphicsEquipment.data;
                    default:
                        return true;
                }
            });
        }
    
        // Apply additional equipment filter
        if (equipmentStatus && equipmentStatus !== 'all') {
            filtered = filtered.filter(booth => {
                const hasAdditionalEquipment = booth.equipment.items.some(item => 
                    item.orderLineItem?.order?.id !== booth.orderLineItem.order.id
                );
    
                return equipmentStatus === 'booked' ? hasAdditionalEquipment : !hasAdditionalEquipment;
            });
        }
        
        setFilteredBooths(filtered);
    };

    const getEvents = async () => {
        try {
            const events = await client.graphql({
                query: /* GraphQL */ `
                query ListEvents {
                    listEvents(filter: { type: { eq: "fair" } }) {
                        items {
                            id
                            type
                            name {
                                text
                                language
                            }
                            startDate
                            endDate
                            status
                        }
                    }
                }
                `,
                variables: {
                    filter: {
                        startDate: {
                            ge: new Date().toISOString().substring(0, 10)
                        },
                        type: { eq: "fair" }
                    }
                }
            });
            setEvents(events.data.listEvents.items.filter(event => event.status === "active" && new Date(event.endDate) >= new Date()));
            return events.data.listEvents.items;
        } catch (error) {
            console.log("error on fetching events", error);
        }
    }

    const getBooths = async (filters) => {
        try {
          let requestFilter = {};
          if (filters && Object.keys(filters).length > 0) {
            Object.keys(filters).forEach(key => {
              if (filters[key]) {
                requestFilter[key] = { eq: filters[key] };
              }
            });
          }
            let boothsRes = await client.graphql({
                query: /* GraphQL */ `
                query SearchBookedBooths(
                  $filter: SearchableBookedBoothFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  searchBookedBooths(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                      id
                      orderLineItem {
                        id
                        order {
                            id
                            status
                            billingEmail
                            billingAddress {
                                company
                                city
                                contactPerson
                                countryCode
                                firstName
                                lastName
                                street
                                streetNumber
                                street2
                                zip
                            }
                            user {
                                id
                                firstName
                                lastName
                                salutation
                                email
                                contact {
                                    phone
                                }
                            }
                        }
                        product {
                            id
                            type
                            name {
                                language
                                text
                            }
                        }
                      }
                      event {
                        id
                        startDate
                        endDate
                        name {
                            language
                            text
                        }
                      }
                      boothNumber
                      company {
                        id
                        name
                        image {
                            main {
                                fileName
                            }
                        }
                        contact {
                            email
                            address {
                                city
                                countryCode
                                street
                                streetNumber
                                zip
                            }
                        }
                      }
                      status
                      equipment {
                        items {
                            id
                            qty
                            data
                            updatedAt
                            files {
                                fileName
                                s3Path
                            }
                            orderLineItem {
                                id
                                order {
                                    id
                                    status
                                }
                                product {
                                    id
                                    type
                                    subType
                                    name {
                                        language
                                        text
                                    }
                                    image {
                                        main {
                                            fileName
                                        }
                                    }
                                }
                            }
                        }
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              `,
                variables: {
                    filter: requestFilter,
                    limit: 500
                }
            });

            let allBooths = boothsRes.data.searchBookedBooths.items;
            let nextToken = boothsRes.data.searchBookedBooths.nextToken;

            while (nextToken) {
                boothsRes = await client.graphql({
                    query: /* GraphQL */ `
                    query SearchBookedBooths(
                      $filter: SearchableBookedBoothFilterInput
                      $limit: Int
                      $nextToken: String
                    ) {
                      searchBookedBooths(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                          id
                          orderLineItem {
                            id
                            order {
                                status
                                billingAddress {
                                    company
                                    city
                                    contactPerson
                                    countryCode
                                    firstName
                                    lastName
                                    street
                                    streetNumber
                                    street2
                                    zip
                                }
                                user {
                                    id
                                    firstName
                                    lastName
                                    salutation
                                    email
                                    contact {
                                        phone
                                    }
                                }
                            }
                            product {
                                id
                                type
                                name {
                                    language
                                    text
                                }
                            }
                          }
                          event {
                            id
                            startDate
                            endDate
                            name {
                                language
                                text
                            }
                          }
                          boothNumber
                          company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                }
                            }
                            contact {
                                address {
                                    city
                                    countryCode
                                    street
                                    streetNumber
                                    zip
                                }
                            }
                          }
                          status
                          equipment {
                            items {
                                id
                                qty
                                data
                                files {
                                    fileName
                                    s3Path
                                }
                                orderLineItem {
                                    id
                                    order {
                                        status
                                    }
                                    product {
                                        id
                                        type
                                        subType
                                        name {
                                            language
                                            text
                                        }
                                        image {
                                            main {
                                                fileName
                                            }
                                        }
                                    }
                                }
                            }
                          }
                          createdAt
                          updatedAt
                        }
                        nextToken
                      }
                    }
                  `,
                    variables: {
                        filter: requestFilter,
                        limit: 500,
                        nextToken: nextToken
                    }
                });

                allBooths = allBooths.concat(boothsRes.data.searchBookedBooths.items);
                nextToken = boothsRes.data.searchBookedBooths.nextToken;
            }

            const sortedBooths = allBooths.sort((a, b) => 
                a.company.name.localeCompare(b.company.name)
            );
            setBooths(sortedBooths);
            console.log(sortedBooths.filter(b => !b.orderLineItem))
            setFilteredBooths(sortedBooths);
            setLoading(false)
        } catch (error) {
            console.warn("error fetching booths", error)
        }
    }

    

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            getBooths(filters)
        }
    }, [filters])

    const downloadFile = async (key) => {
        try {
          const url = await getUrl({ 
            key,
            options: { level: 'protected' }
         });
          console.log('Download URL:', url);
          window.open(url.url, '_blank');
        } catch (error) {
          console.error('Error downloading file:', error);
        }
    };

    const [openEditBoothNumberBoothId, setOpenEditBoothNumberBoothId] = useState(null)
    const [openEditBoothNumberBoothNumber, setOpenEditBoothNumberBoothNumber] = useState(null)
    
    const updateBoothNumber = async (boothId, boothNumber) => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateBookedBooth(
                  $input: UpdateBookedBoothInput!
                  $condition: ModelBookedBoothConditionInput
                ) {
                  updateBookedBooth(input: $input, condition: $condition) {
                    id
                    boothNumber
                  }
                }
              `,
                variables: {
                    input: {
                        id: boothId,
                        boothNumber
                    }
                }
            })
            const updatedBooths = booths.map(booth => 
                booth.id === boothId ? { ...booth, boothNumber } : booth
            );
            setBooths(updatedBooths);
            setFilteredBooths(updatedBooths.filter(booth =>
                booth.company.name.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } catch (error) {
            console.warn("error updating booth number", error)
        }
    }

    const [eventCarpets, setEventCarpets] = useState([]);
    const getEventCarpets = async () => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
        query ListEventCarpets(
          $filter: ModelEventCarpetFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listEventCarpets(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              order
              name {
                language
                text
                __typename
              }
              description {
                language
                text
                __typename
              }
              color
              image {
                fileName
                __typename
              }
              availableInEvents {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
        }
      `
      });
      setEventCarpets(response.data.listEventCarpets.items);
    } catch (error) {
      console.error('Error fetching event carpets:', error);
    }
    };

    const handleEventSelection = (eventId) => {
        setFilters({ ...filters, eventBookedBoothsId: eventId });
        if (eventId) {
            getBooths({ eventBookedBoothsId: eventId });
        }
    };

  useEffect(() => {
    getEvents()

    getEventCarpets()
}, [])

const sortEquipmentItems = (items, mainOrderId) => {
    return items
        .filter(item => 
            item.orderLineItem?.product && 
            item.orderLineItem.product?.subType !== "display_print" && 
            item.orderLineItem.product?.subType !== "carpet"
        )
        .sort((a, b) => {
            // Vergleiche ob die Items zur Hauptbestellung gehören
            const aIsMain = a.orderLineItem?.order?.id === mainOrderId;
            const bIsMain = b.orderLineItem?.order?.id === mainOrderId;
            
            // Wenn eines zur Hauptbestellung gehört und das andere nicht,
            // soll das Hauptbestellungs-Item zuerst kommen
            if (aIsMain && !bIsMain) return -1;
            if (!aIsMain && bIsMain) return 1;
            
            // Bei gleicher Bestellungszugehörigkeit alphabetisch nach Produktname sortieren
            const aName = getLocalizedText(a.orderLineItem.product.name || a.orderLineItem.product.name[0]);
            const bName = getLocalizedText(b.orderLineItem.product.name || b.orderLineItem.product.name[0]);
            
            return aName.localeCompare(bName);
        });
};

const [graphicsSortDirection, setGraphicsSortDirection] = useState(null); // null, 'asc', or 'desc'

    const handleGraphicsSort = () => {
        // Toggle between null -> 'asc' -> 'desc' -> null
        const nextDirection = graphicsSortDirection === null ? 'asc' : 
                            graphicsSortDirection === 'asc' ? 'desc' : null;
        setGraphicsSortDirection(nextDirection);
        
        if (nextDirection === null) {
            // Reset to original filtered order
            applyFilters(searchTerm, carpetFilter, graphicsFilter, equipmentFilter);
            return;
        }

        const sortedBooths = [...filteredBooths].sort((a, b) => {
            const graphicsA = a.equipment.items.find(item => 
                item.orderLineItem?.product?.subType === "display_print"
            );
            const graphicsB = b.equipment.items.find(item => 
                item.orderLineItem?.product?.subType === "display_print"
            );
            
            // Get upload dates, default to empty string if no date
            const dateA = graphicsA?.updatedAt || '';
            const dateB = graphicsB?.updatedAt || '';
            
            if (nextDirection === 'asc') {
                return dateA.localeCompare(dateB);
            } else {
                return dateB.localeCompare(dateA);
            }
        });

        setFilteredBooths(sortedBooths);
    };

const handleDownloadExcel = () => {
    // Daten für Excel vorbereiten
    const excelData = [];
    
    // Header hinzufügen
    excelData.push([
        "Veranstaltung",
        "Firmenname",
        "Standvariante",
        "Standnummer",
        "Firmen Account E-Mail",
        "Rechnungs E-Mail",
        "Firmen Straße",
        "Firmen PLZ",
        "Firmen Ort",
        "Firmen Land",
        "Firmen Telefon",
        "Admin E-Mail",
        "Admin Vorname",
        "Admin Nachname",
        "Admin Straße",
        "Admin PLZ",
        "Admin Ort",
        "Admin Telefon",
        "Ausgewählter Teppich",
        "Ausstattung",
        "Zusatzausstattung"
    ]);
    
    // Daten für jede Firma hinzufügen
    filteredBooths
    .filter(booth => booth.orderLineItem.order.status !== "canceled")
    .forEach(booth => {
        // Separiere Haupt- und Zusatzausstattung
        const mainOrderId = booth.orderLineItem.order.id;
        const equipment = sortEquipmentItems(booth.equipment.items, mainOrderId);
        
        const mainEquipment = equipment
            .filter(item => item.orderLineItem.order.id === mainOrderId)
            .map(item => `${item.qty}x ${getLocalizedText(item.orderLineItem.product.name)}`)
            .join('\n');

        const additionalEquipment = equipment
            .filter(item => item.orderLineItem.order.id !== mainOrderId)
            .map(item => `${item.qty}x ${getLocalizedText(item.orderLineItem.product.name)}`)
            .join('\n');

        // Finde den ausgewählten Teppich
        const carpet = booth.equipment.items
            .find(item => item.orderLineItem?.product?.subType === "carpet");
        const carpetName = carpet?.data ? 
            getLocalizedText(eventCarpets.find(c => c.id === carpet.data)?.name) : '';

        excelData.push([
            getLocalizedText(booth.event.name),
            booth.company.name,
            getLocalizedText(booth.orderLineItem?.product.name),
            booth.boothNumber || '',
            booth.company.contact?.email || '',
            booth.orderLineItem.order.billingEmail || '',
            booth.company.contact?.address?.street || '',
            booth.company.contact?.address?.zip || '',
            booth.company.contact?.address?.city || '',
            booth.company.contact?.address?.countryCode || '',
            booth.company.contact?.phone || '',
            booth.orderLineItem.order.user.email,
            booth.orderLineItem.order.user.firstName,
            booth.orderLineItem.order.user.lastName,
            booth.orderLineItem.order.billingAddress?.street || '',
            booth.orderLineItem.order.billingAddress?.zip || '',
            booth.orderLineItem.order.billingAddress?.city || '',
            booth.orderLineItem.order.user.contact?.phone || '',
            carpetName,
            mainEquipment,
            additionalEquipment
        ]);
    });

    // Arbeitsmappe erstellen
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(excelData);

    // Spaltenbreiten automatisch anpassen
    ws['!cols'] = [
        { wch: 20 }, // Event
        { wch: 30 }, // Company Name
        { wch: 20 }, // Booth Type
        { wch: 15 }, // Booth Number
        { wch: 30 }, // Company Account Email
        { wch: 30 }, // Company Billing Email
        { wch: 30 }, // Company Street
        { wch: 10 }, // Company ZIP
        { wch: 20 }, // Company City
        { wch: 10 }, // Company Country
        { wch: 15 }, // Company Phone
        { wch: 30 }, // Admin Email
        { wch: 20 }, // Admin First Name
        { wch: 20 }, // Admin Last Name
        { wch: 30 }, // Admin Street
        { wch: 10 }, // Admin ZIP
        { wch: 20 }, // Admin City
        { wch: 15 }, // Admin Phone
        { wch: 20 }, // Selected Carpet
        { wch: 40 }, // Equipment
        { wch: 40 }  // Additional Equipment
    ];
    

    // Worksheet zur Arbeitsmappe hinzufügen
    XLSX.utils.book_append_sheet(wb, ws, 'Booths');

    // Excel-Datei herunterladen
    XLSX.writeFile(wb, `Booths-${getLocalizedText(events.find(e => e.id === filters.eventBookedBoothsId)?.name)}.xlsx`);
};

  return (
    <>
    <MDBRow>
        <MDBCol>
            <MDBCard>
                <MDBCardBody>
                    <MDBCardTitle>
                        {t("Booked Booths")}
                        <MDBBtn
                            className="ms-3"
                            size="sm"
                            outline
                            color="primary" 
                            onClick={handleDownloadExcel}
                            disabled={filteredBooths.length === 0}
                            >
                            <MDBIcon fas icon="file-excel" className="me-2" />
                            {t("Download")}
                        </MDBBtn>
                    </MDBCardTitle>
                    {!filters.eventBookedBoothsId ? (
                            <MDBCard className="text-center">
                                <MDBCardBody className="py-5">
                                    <MDBIcon fas icon="calendar-alt" size="3x" className="mb-3 text-primary" />
                                    <h4 className="mb-3">{t("Please Select an Event")}</h4>
                                    <p className="text-muted mb-4">{t("Select an event to view its booked booths")}</p>
                                    <div className="w-50 mx-auto">
                                        <MDBSelect 
                                            label={t("Event")}
                                            onChange={event => handleEventSelection(event.value)}
                                            data={[
                                                { text: t("Select an event..."), value: "" },
                                                ...events.map(event => ({ 
                                                    text: getLocalizedText(event.name), 
                                                    value: event.id 
                                                }))
                                            ]}
                                        />
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        ) : (
<>
    <MDBRow className="mt-2 mt-xl-3">
        <MDBCol md="3">
            <MDBSelect 
                label={t("Event")}
                onChange={event => setFilters({ ...filters, eventBookedBoothsId: event.value })}
                value={filters.eventBookedBoothsId?.eq}
                data={[
                    { text: t("All"), value: null },
                    ...events.map(event => ({ 
                        text: getLocalizedText(event.name), 
                        value: event.id 
                    }))
                ]}
            />
        </MDBCol>
        <MDBCol md="2">
            <MDBInput
                label={t("Search Company")}
                type="text"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
            />
        </MDBCol>
        <MDBCol md="2">
            <MDBSelect
                label={t("Carpet Selection")}
                onChange={event => handleCarpetFilter(event.value)}
                value={carpetFilter}
                data={[
                    { text: t("All"), value: "all" },
                    { text: t("Selected"), value: "selected" },
                    { text: t("Not Selected"), value: "notSelected" },
                    { text: t("Not Available"), value: "notAvailable" }
                ]}
            />
        </MDBCol>
        <MDBCol md="2">
            <MDBSelect
                label={t("Graphics Wall")}
                onChange={event => handleGraphicsFilter(event.value)}
                value={graphicsFilter}
                data={[
                    { text: t("All"), value: "all" },
                    { text: t("Uploaded"), value: "uploaded" },
                    { text: t("Not Uploaded"), value: "notUploaded" }
                ]}
            />
        </MDBCol>
        <MDBCol md="3">
            <MDBSelect
                label={t("Additional Equipment")}
                onChange={event => handleEquipmentFilter(event.value)}
                value={equipmentFilter}
                data={[
                    { text: t("All"), value: "all" },
                    { text: t("Additional Booked"), value: "booked" },
                    { text: t("No Additional"), value: "none" }
                ]}
            />
        </MDBCol>
    </MDBRow>
                    <MDBTable align="middle">
                        <MDBTableHead>
                            <tr>
                                <th>{t("Event")}</th>
                                <th>{t("Company")}</th>
                                <th>{t("Booth")}</th>
                                <th className="cursor-pointer" onClick={handleGraphicsSort}>
                    <div className="d-flex align-items-center">
                        {t("Graphics")}
                        <div className="ms-2">
                            {graphicsSortDirection === null && (
                                <MDBIcon fas icon="sort" className="text-muted" />
                            )}
                            {graphicsSortDirection === 'asc' && (
                                <MDBIcon fas icon="sort-up" className="text-primary" />
                            )}
                            {graphicsSortDirection === 'desc' && (
                                <MDBIcon fas icon="sort-down" className="text-primary" />
                            )}
                        </div>
                    </div>
                </th>
                                <th>{t("Floor Selection")}</th>
                                <th>{t("Equipment")}</th>
                                <th>{t("Actions")}</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {filteredBooths.map((booth, index) => (
                                <tr key={index} className={booth.orderLineItem.order?.status === "canceled" && "text-danger"}>
                                    <td>
                                       {getLocalizedText(booth.event.name)}
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            {booth.company.image?.main?.fileName ?
                                                <img
                                                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+booth.company.image.main.fileName}
                                                    className=""
                                                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                    alt={booth.company.image.alt}
                                                />
                                            :
                                                <MDBIcon 
                                                    className="" 
                                                    style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} 
                                                    fas 
                                                    icon="company-alt" 
                                                />
                                            }
                                            <div className='ms-3'>
                                            <Link to={"/admin/company/" + booth.company.id} className="fw-bold text-dark">{booth.company.name}</Link>
                                                <br />
                                                <Link to={"/admin/user/" + booth.orderLineItem.order.user.id} className="text-dark">{booth.orderLineItem.order.user.firstName} {booth.orderLineItem.order.user.lastName}</Link>
                                                {booth.orderLineItem.order.user.contact?.phone &&
                                                <MDBTooltip tag='a' wrapperProps={{style: {cursor: "pointer"}, onClick: () => navigator.clipboard.writeText(booth.orderLineItem.order.user.contact?.phone)}} title={booth.orderLineItem.order.user.contact?.phone}>
                                                    <MDBIcon fas icon="phone" className="ms-2" />
                                                </MDBTooltip>
                                                }
                                                <MDBTooltip tag='a' wrapperProps={{style: {cursor: "pointer"}, onClick: () => navigator.clipboard.writeText(booth.orderLineItem.order.user.email)}} title={booth.orderLineItem.order.user.email}>
                                                    <MDBIcon fas icon="envelope" className="ms-2" />
                                                </MDBTooltip>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {getLocalizedText(booth.orderLineItem?.product.name)}
                                        <br />
                                        {t("Booth number")}: {booth.boothNumber}
                                        {openEditBoothNumberBoothId === booth.id ? (
                                            <div className="d-flex">
                                                <MDBInput
                                                    value={openEditBoothNumberBoothNumber}
                                                    onChange={e => setOpenEditBoothNumberBoothNumber(e.target.value)}
                                                />
                                                <MDBBtn
                                                    size="sm"
                                                    color="primary"
                                                    onClick={() => {
                                                        updateBoothNumber(openEditBoothNumberBoothId, openEditBoothNumberBoothNumber)
                                                        setOpenEditBoothNumberBoothId(null)
                                                        setOpenEditBoothNumberBoothNumber(null)
                                                    }}
                                                >
                                                    <MDBIcon fas icon="save" />
                                                </MDBBtn>
                                            </div>
                                        ) : (
                                            <span
                                               className="ms-2"
                                                onClick={() => {
                                                    setOpenEditBoothNumberBoothId(booth.id)
                                                    setOpenEditBoothNumberBoothNumber(booth.boothNumber)
                                                }}
                                            >
                                               <MDBIcon far icon="edit" />
                                            </span>
                                        )}
                                        <br />
                                       <span className="text-muted" style={{fontSize: "8px"}}>{booth.id}</span>
                                    </td>
                                    <td>
                                        {booth.equipment.items.filter(item => item.orderLineItem?.product.subType === "display_print").map((item, index) => (
                                            <div key={index}>
                                                {getLocalizedText(item.orderLineItem.product.name || item.orderLineItem.product.name[0])}
                                                {item.data ?
                                                    <MDBIcon className="text-success ms-2" fas icon='check' />
                                                :
                                                    <MDBIcon className="text-danger ms-2" fas icon='times' />
                                                }
                                                {item.data && 
                                                    <>
                                                        <br />
                                                        <MDBBtn outline key={index} onClick={() => downloadFile(item.data)} color="primary" size="sm">
                                                            {t("Download")}{/* {item.data} */}
                                                        </MDBBtn>
                                                        <span className="text-muted">{new Date(item.updatedAt).toLocaleString()}</span>
                                                    </>
                                                }
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        {booth.equipment.items.filter(item => item.orderLineItem?.product.subType === "carpet").map((item, index) => (
                                            <div key={index} className="text-center">
                                                {item.data ?
                                                    <> 
                                                    <img
                                                        src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/carpet/"+  eventCarpets.find(c => c.id === item.data)?.image.fileName}
                                                        className="img-fluid"
                                                        alt="color_carpet"
                                                        style={{ width: "40px", height: "40px", objectFit: "contain" }}
                                                    />
                                                    <br />
                                                    {getLocalizedText(eventCarpets.find(c => c.id === item.data)?.name)}
                                                    </>
                                                :
                                                    <MDBIcon className="text-danger ms-2" fas icon='times' />
                                                }
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        {sortEquipmentItems(booth.equipment.items, booth.orderLineItem.order.id).map((item, index) => (
                                            <div 
                                                key={index} 
                                                className={
                                                    item.orderLineItem?.order?.status === "canceled" 
                                                        ? "text-danger" 
                                                        : item.orderLineItem?.order?.id !== booth.orderLineItem.order.id 
                                                            ? "text-info" 
                                                            : ""
                                                }
                                            >
                                                {item.qty} x
                                                {getLocalizedText(item.orderLineItem.product.name || item.orderLineItem.product.name[0]).substring(0, 50)}
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        {booth.orderLineItem?.product.type !== "event_booth_partner" && (
                                            <MDBBtnGroup>
                                                <Link to={`/mycompany/${booth.company.id}/edit-booth/${booth.id}`}>
                                                    <MDBBtn size="sm" color="primary">{t("View")}</MDBBtn>
                                                </Link>
                                            </MDBBtnGroup>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
</>
)}
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    </MDBRow>
    </>
  )
}