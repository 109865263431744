import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { generateClient } from "aws-amplify/api"
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, 
    MDBTableBody, MDBTableHead, MDBBtnGroup, MDBIcon, MDBModal, MDBModalDialog, MDBModalContent, 
    MDBModalHeader, MDBModalBody, MDBModalTitle, MDBCardText, MDBCardImage,
    MDBCheckbox, MDBCollapse, MDBSelect, MDBInput,
    MDBPagination, MDBPaginationItem, MDBPaginationLink,
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem
} from "mdb-react-ui-kit"
import { Link } from "react-router-dom"

const client = generateClient()

export default function AdminAllProducts() {
    const { t, i18n } = useTranslation()
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [totalPages, setTotalPages] = useState(0)
    const [searchTerm, setSearchTerm] = useState("")
    const [filters, setFilters] = useState({});
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [showProductDetails, setShowProductDetails] = useState(false)
    

    const getProducts = async (currentPage, search, filters) => {
        try {
            let filterConditions = {};
            
            // Add search filter if search term exists
            if (search) {
                filterConditions = {
                    or: {
                        sku: { eq: `${search}` },
                        name_text: { wildcard: `${search}*` }
                      }

                };
            }

            // Add type filter if selected
            if (filters.type) {
                filterConditions.type = { eq: filters.type };
            }

            const products = await client.graphql({
                query: /* GraphQL */ `
                query SearchProducts(
                    $filter: SearchableProductFilterInput
                    $sort: [SearchableProductSortInput]
                    $limit: Int
                    $nextToken: String
                    $from: Int
                ) {
                    searchProducts(
                        filter: $filter
                        sort: $sort
                        limit: $limit
                        nextToken: $nextToken
                        from: $from
                    ) {
                        items {
                            id
                            name {
                                language
                                text
                            }
                            sku
                            stock
                            orderLineItems {
                                items {
                                    qty
                                }
                            }
                            type
                            price
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                        }
                        total
                    }
                }
                `,
                variables: {
                    limit: rowsPerPage,
                    from: (currentPage - 1) * rowsPerPage,
                    filter: Object.keys(filterConditions).length > 0 ? filterConditions : null,
                    sort: [{ field: "createdAt", direction: "desc" }]
                }
            });

            setProducts(products.data.searchProducts.items);
            setTotalPages(Math.ceil(products.data.searchProducts.total / rowsPerPage));
            setLoading(false);
        } catch (error) {
            console.warn("Error fetching products", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getProducts(page, searchTerm, filters);
    }, [page, searchTerm, filters]);

    // Add search handler with debounce
    let searchTimeout = null;
    const handleSearch = (event) => {
        const value = event.target.value;
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        searchTimeout = setTimeout(() => {
            setSearchTerm(value);
            setPage(1); // Reset to first page when searching
        }, 300);
    };

    // Pagination handler
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleViewProduct = (productId) => {
        setSelectedProduct(products.find(product => product.id === productId))
        setShowProductDetails(true)
    }
    
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [productOrders, setProductOrders] = useState([]);
    const [isCheckingOrders, setIsCheckingOrders] = useState(false);

    const checkProductOrders = async (productId) => {
      setIsCheckingOrders(true);
      try {
          const { data } = await client.graphql({
              query: /* GraphQL */ `
              query GetProduct($id: ID!) {
                  getProduct(id: $id) {
                      orderLineItems {
                          items {
                              order {
                                  orderNumber
                              }
                          }
                      }
                  }
              }
              `,
              variables: {
                  id: productId
              }
          });

          const orders = data.getProduct.orderLineItems.items
              .filter(item => item.order?.orderNumber)
              .map(item => item.order.orderNumber);

          setProductOrders(orders);
      } catch (error) {
          console.warn("Error checking product orders:", error);
          setProductOrders([]);
      }
      setIsCheckingOrders(false);
  };

  const handleDeleteProduct = async (productId) => {
      setProductToDelete(productId);
      setShowDeleteModal(true);
      await checkProductOrders(productId);
  };

  const confirmDelete = async () => {
      try {
          await client.graphql({
              query: /* GraphQL */ `
              mutation DeleteProduct($input: DeleteProductInput!) {
                  deleteProduct(input: $input) {
                      id
                  }
              }
              `,
              variables: {
                  input: {
                      id: productToDelete
                  }
              }
          });
          setProducts(products.filter(product => product.id !== productToDelete));
          setShowDeleteModal(false);
          setProductToDelete(null);
          setProductOrders([]);
      } catch (error) {
          console.warn("error deleting product", error);
      }
  };

  return (
<>
<MDBRow>
    <MDBCol>
        <MDBCard>
            <MDBCardBody>
                <MDBCardTitle>{t("All Products")}<Link to={`/admin/product/create`}><MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>{t("Create product")}</MDBBtn></Link></MDBCardTitle>
                <MDBRow className="mt-2 mt-xl-3">
                    <MDBCol md="4">
                        <MDBInput
                            label={t("Search products")}
                            onChange={handleSearch}
                            type='text'
                        />
                    </MDBCol>
                    <MDBCol md="3">
                        <MDBSelect
                            label={t("Type")}
                            onChange={type => setFilters({ ...filters, type: type.value })}
                            value={filters.type}
                            data={[
                                { text: t("All"), value: null },
                                { text: t("Event booths"), value: "event_booth" },
                                { text: t("Event spaces"), value: "event_space" },
                                { text: t("Event booths partner"), value: "event_booth_partner" },
                                { text: t("Event equipment"), value: "event_equipment" },
                                { text: t("Marketing"), value: "marketing" },
                                { text: t("Marketing Stand"), value: "marketing_booth" },
                                { text: t("Digital Access"), value: "digital_access" },
                                { text: t("Tickets"), value: "ticket" },
                                { text: t("Discount"), value: "discount" }
                            ]}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBTable align="middle">
                    <MDBTableHead>
                        <tr>
                            <th>{t("Image")}</th>
                            <th>{t("Name")}</th>
                            <th>{t("SKU")}</th>
                            <th>
                                {t("Stock")}
                                <br />
                                <span style={{fontSize: "11px"}}>{t("Total")} | {t("Ordered")} | {t("Available")}</span>
                                </th>
                            <th>{t("Actions")}</th>
                            
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {products?.map((product, index) => (
                            <tr key={index}>
                                <td>
                                <div className='d-flex align-items-center'>
                                    {product.image?.main?.fileName ?
                                        <img
                                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/${product.type === "ticket" ? "EventImages" : "ProductImages"}/`+product.image.main.fileName}
                                            className=""
                                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                            alt={product.image.alt}
                                        />
                                    :
                                        <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} icon="image" />
                                                
                                    }
                                </div>
                                </td>
                                <td>{product.name.find(n => n.language === i18n.language)?.text || product.name[0]?.text}</td>
                                <td>{product.sku}</td>
                                <td>
                                <span>{product.stock + product.orderLineItems?.items.reduce((acc, item) => acc + item.qty, 0)}</span> | <span>{product.orderLineItems?.items.reduce((acc, item) => acc + item.qty, 0)}</span> | <span className="fw-bold">{product.stock}</span> 
                                    <br />
                                   
                                    </td>
                                <td>
                                    <MDBBtnGroup>
                                        <MDBBtn color='info' size='sm' onClick={() => handleViewProduct(product.id)}><MDBIcon fas icon='eye' /></MDBBtn>
                                        <Link to={`/admin/product/${product.id}`}><MDBBtn color='info' size='sm' onClick={() => console.log("edit", product)}><MDBIcon fas icon='edit' /></MDBBtn></Link>
                                        <MDBBtn color='danger' size='sm' onClick={() => handleDeleteProduct(product.id)}><MDBIcon fas icon='trash-alt' /></MDBBtn>
                                    </MDBBtnGroup>
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
                {/* Pagination */}
                <nav aria-label='Page navigation' className="d-flex">
                        <MDBPagination className='mx-auto' style={{width: "fit-content"}}>
                            <MDBPaginationItem>
                            {page !== 1 && <MDBPaginationLink  className="text-dark" onClick={() => setPage(page - 1)}>{t("Previous")}</MDBPaginationLink>}
                            </MDBPaginationItem>{
                                totalPages > 0 && Array.from(Array(totalPages).keys()).slice(0,5).map((pageIndex) => {
                                    return (
                                        <MDBPaginationItem key={pageIndex} active={pageIndex + 1 === page}>
                                            <MDBPaginationLink href='#' className="text-dark" onClick={() => setPage(pageIndex+1)}>{pageIndex + 1}</MDBPaginationLink>
                                        </MDBPaginationItem>
                                    )
                                })
                            }
                            {
                                totalPages > 5 &&
                                <MDBDropdown dropup>
                                    <MDBDropdownToggle caret color="white" className="text-dark">
                                        {t("More")}
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu basic>
                                        {
                                            Array.from(Array(totalPages).keys()).slice(5).map((pageIndex) => {
                                                return (
                                                    <MDBDropdownItem link key={pageIndex} active={pageIndex + 1 === page} className="text-dark"  onClick={() => setPage(pageIndex+1)}>
                                                        {pageIndex + 1}
                                                    </MDBDropdownItem>
                                                )
                                            })
                                        }
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            }



                            <MDBPaginationItem>
                                {page !== totalPages && <MDBPaginationLink className="text-dark" onClick={() => setPage(page + 1)}>{t("Next")}</MDBPaginationLink>}
                            </MDBPaginationItem>
                        </MDBPagination>
                        <MDBDropdown className="float-end">
                        <MDBDropdownToggle caret color="white" className="text-dark">
                            {rowsPerPage}
                        </MDBDropdownToggle>
                        <MDBDropdownMenu basic>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(10)} className="text-dark">
                                10
                            </MDBDropdownItem>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(20)} className="text-dark">
                                20
                            </MDBDropdownItem>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(50)} className="text-dark">
                                50
                            </MDBDropdownItem>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(100)} className="text-dark">
                                100
                            </MDBDropdownItem>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(200)} className="text-dark">
                                200
                            </MDBDropdownItem>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(500)} className="text-dark">
                                500
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                    </nav>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
</MDBRow>
<MDBModal open={showProductDetails} onClose={() => setShowProductDetails(false)}>
    <MDBModalDialog scrollable size="xl">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Product Details")}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>{selectedProduct?.name.find(n => n.language === i18n.language)?.text || selectedProduct?.name[0]?.text}</MDBCardTitle>
                        <MDBCardText>{selectedProduct?.description?.find(n => n.language === i18n.language)?.text || selectedProduct?.description?.[0]?.text}</MDBCardText>
                        <MDBCardImage src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+selectedProduct?.image?.main?.fileName} alt={selectedProduct?.image?.main?.alt} position='top' />
                        <MDBIcon fas icon={selectedProduct?.icon} />
                        <MDBCardText>{t("SKU")}: {selectedProduct?.sku}</MDBCardText>
                        {/* <MDBCardText>{t("EAN")}:{selectedProduct?.ean}</MDBCardText> */}
                        <MDBCardText>{t("Type")}: {selectedProduct?.type}</MDBCardText>
                        <MDBCardText>{t("Price")}: {selectedProduct?.price} €</MDBCardText>
                        {selectedProduct?.allowedBuyerGroups &&<MDBCardText>{t("Allowed buyer groups")}: {selectedProduct?.allowedBuyerGroups}</MDBCardText>}
                        <ul>
                        {selectedProduct?.includes?.filter(i => i.language === i18n.language).map((include, index) => (
                            <li key={index}>{include?.text}</li>
                        ))}
                        </ul>
                        <MDBCardText>{selectedProduct?.shortDescription.find(n => n.language === i18n.language)?.text || selectedProduct?.shortDescription[0]?.text}</MDBCardText>
                        <MDBCardText>{selectedProduct?.features}</MDBCardText>
                        
                        <MDBCardText>{selectedProduct?.requiredShipping}</MDBCardText>
                       
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
{/* Delete Confirmation Modal */}
<MDBModal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
  <MDBModalDialog>
      <MDBModalContent>
          <MDBModalHeader>
              <MDBModalTitle>{t("Delete Product")}</MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
              {isCheckingOrders ? (
                  <div className="text-center">
                      <MDBIcon fas icon="spinner" spin /> {t("Checking orders...")}
                  </div>
              ) : productOrders.length > 0 ? (
                  <div>
                      <p className="text-danger">
                          {t("This product has already been ordered and cannot be deleted.")}
                      </p>
                      <p>
                          {t("Order numbers")}: {productOrders.join(", ")}
                      </p>
                  </div>
              ) : (
                  <p>{t("Are you sure you want to delete this product?")}</p>
              )}
          </MDBModalBody>
          <div className="modal-footer">
              <MDBBtn 
                  color="primary" 
                  outline
                  onClick={() => setShowDeleteModal(false)}
              >
                  {t("Close")}
              </MDBBtn>
              <MDBBtn
                  color="danger"
                  onClick={confirmDelete}
                  disabled={productOrders.length > 0 || isCheckingOrders}
              >
                  {t("Delete")}
              </MDBBtn>
          </div>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
</>
  )
}
