import React, { createContext, useContext, useEffect, useState } from 'react';

const IndustryContext = createContext();

export function useIndustry() {
  return useContext(IndustryContext);
}

export function IndustryProvider({ children }) {
    const [currentIndustry, setCurrentIndustry] = useState(null);
    const [availableIndustries, setAvailableIndustries] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      fetchIndustries();
    }, []);
  
    const fetchIndustries = async () => {
      try {
        const response = await fetch('https://y3vayhw2i5bqrm6fpwqz7fzfwe.appsync-api.eu-central-1.amazonaws.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'da2-vana665eajd6devxupfcoq3o74'
          },
          body: JSON.stringify({
            query: `
              query ListIndustries {
                listIndustries(filter: { status: { eq: "ACTIVE" } }) {
                  items {
                    id
                    name {
                      language
                      text
                    }
                    handle
                    domain
                    primaryColor
                    secondaryColor
                    logo {
                      fileName
                    }
                    status
                    socialLinks {
                      facebook
                      instagram
                      linkedin
                      x
                      spotify
                      youtube
                    }
                  }
                }
              }
            `
          })
        });

        const result = await response.json();
        const industries = result.data.listIndustries.items;
        setAvailableIndustries(industries);
  
        const hostname = window.location.hostname.toLowerCase();
        const matchingIndustry = industries.find(ind => hostname.includes(ind.domain));
        
        if (matchingIndustry) {
          setCurrentIndustry(matchingIndustry);
          applyTheme(matchingIndustry);
        } else {
            // If localhost use cp industry
            const cpIndustry = industries.find(ind => ind.handle === 'cp');
            setCurrentIndustry(cpIndustry);
            applyTheme(cpIndustry);
        }
      } catch (error) {
        console.error('Error loading industries:', error);
      } finally {
        setLoading(false);
      }
    };
  
    const applyTheme = (industry) => {
        if (!industry) return;
        document.body.className = document.body.className
          .split(' ')
          .filter(c => !c.includes('theme'))
          .join(' ');
        
        if (industry.handle) {
          document.body.classList.add(`${industry.handle}-theme`);
        }
    };
  
    const switchIndustry = (industry) => {
      setCurrentIndustry(industry);
      applyTheme(industry);
      localStorage.setItem('preferredIndustry', industry.id);
    };
  
    return (
      <IndustryContext.Provider value={{ 
        currentIndustry, 
        availableIndustries,
        switchIndustry,
        loading 
      }}>
        {children}
      </IndustryContext.Provider>
    );
}