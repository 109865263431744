import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTypography, MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import LectureTypeCard from "./LectureTypeCard";
import { useTranslation } from "react-i18next";
import { getLocalizedText } from "../../../../../utils/localizedText";


export default function ChooseLectureType(props) {
  const { t, i18n } = useTranslation();
  const { handleChange, lecture, event} = props;

  return (
    <>
<MDBTypography tag="h1" className="text-center">
  Was für einen Vortrag möchtest du eintragen?
</MDBTypography>
<MDBTypography tag="h6" className="my-2 my-lg-4">
<ol>
  <li>
    {t("Submission period for lectures")}: {event.lectureRegistrationStartDate && new Date(event.lectureRegistrationStartDate).toLocaleDateString(i18n.language, { year: 'numeric', month: 'long', day: 'numeric' })} - {event.lectureRegistrationEndDate && new Date(event.lectureRegistrationEndDate).toLocaleDateString(i18n.language, { year: 'numeric', month: 'long', day: 'numeric' })}
    </li>
  <li>
  {t("The lecture can only be submitted in its entirety, including: title, subsections, text description, topic-related image, assignment of the speaker.")}
  </li>
  <li>
 {t("Incompletely submitted lectures will not be considered for allocation to the program.")}
  </li>
</ol>
</MDBTypography>
{event.lecturesNotice?.[0] && <MDBTypography tag="h6" className="ms-4 my-lg-4 text-danger">
  {getLocalizedText(event.lecturesNotice)}
</MDBTypography>}
<MDBTypography tag="h3" className="text-center">
  {t("Choose lecture type")}
</MDBTypography>
<MDBRow className="mt-4">
  {
    event.availableLectureTypes?.map((type, index) => {
      return (
        <MDBCol size="12" lg="4" className="mx-2 mx-lg-0 px-1 px-xl-2 px-xxl-4" key={index}>
          <LectureTypeCard type={type} handleChange={handleChange} />
        </MDBCol>
      );
    })
  }

</MDBRow>
    </>
        

  );
}
