import { generateClient } from 'aws-amplify/api';

export async function deleteLecture(lectureId) {
  const client = generateClient();
  
  try {
    // First, fetch the lecture to get all connections
    const lectureData = await client.graphql({
      query: /* GraphQL */ `
        query GetLecture($id: ID!) {
          getLecture(id: $id) {
            id
            speakers {
              items {
                id
              }
            }
            topics {
              items {
                id
              }
            }
            courses {
              items {
                id
              }
            }
          }
        }
      `,
      variables: { id: lectureId }
    });

    const lecture = lectureData.data.getLecture;

    // Delete LectureSpeakers connections
    if (lecture.speakers?.items) {
      await Promise.all(lecture.speakers.items.map(async (speaker) => {
        await client.graphql({
          query: /* GraphQL */ `
            mutation DeleteLecturesSpeakers($input: DeleteLecturesSpeakersInput!) {
              deleteLecturesSpeakers(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              id: speaker.id
            }
          }
        });
      }));
    }

    // Delete LecturesTopics connections
    if (lecture.topics?.items) {
      await Promise.all(lecture.topics.items.map(async (topic) => {
        await client.graphql({
          query: /* GraphQL */ `
            mutation DeleteLecturesTopics($input: DeleteLecturesTopicsInput!) {
              deleteLecturesTopics(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              id: topic.id
            }
          }
        });
      }));
    }

    // Delete LecturesInCourses connections
    if (lecture.courses?.items) {
      await Promise.all(lecture.courses.items.map(async (course) => {
        await client.graphql({
          query: /* GraphQL */ `
            mutation DeleteLecturesInCourses($input: DeleteLecturesInCoursesInput!) {
              deleteLecturesInCourses(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              id: course.id
            }
          }
        });
      }));
    }

    // Finally delete the lecture itself
    await client.graphql({
      query: /* GraphQL */ `
        mutation DeleteLecture($input: DeleteLectureInput!) {
          deleteLecture(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: {
          id: lectureId
        }
      }
    });

    return true;
  } catch (error) {
    console.error('Error deleting lecture:', error);
    throw error;
  }
}