import { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { uploadData, getUrl, remove } from 'aws-amplify/storage';
import { useNavigate } from 'react-router-dom';
import { lang } from "moment-timezone";

export const useEventData = (eventId) => {
  const navigate = useNavigate();
  const client = generateClient();
  
  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [uploadingImageError, setUploadingImageError] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [uploadingFileError, setUploadingFileError] = useState(null);

  useEffect(() => {
    if (eventId) {
      fetchEvent();
    }
  }, [eventId]);

  const fetchEvent = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: GET_EVENT_QUERY,
        variables: { id: eventId },
      });
      setEvent(response.data.getEvent);
    } catch (error) {
      console.error('Error fetching event:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (field, value, language = null) => {
    setEvent(prev => {
      if (language) {
        const updatedField = prev[field] ? [...prev[field]] : [];
        const index = updatedField.findIndex(item => item.language === language);
        if (index !== -1) {
          updatedField[index] = { ...updatedField[index], text: value };
        } else {
          updatedField.push({ language, text: value });
        }
        return { ...prev, [field]: updatedField };
      } else {
        return { ...prev, [field]: value };
      }
    });
  };

  const handleImageUpload = async (file) => {
    if (!file) return;
    
    try {
      setIsUploadingImage(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      reader.onload = async () => {
        try {
          const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
          const response = await client.graphql({
            query: UPLOAD_IMAGE_MUTATION,
            variables: {
              imageBuffer: base64FileBuffer,
              imageType: "eventImage",
            },
          });
          
          const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
          setEvent(oldData => ({...oldData, image: {fileName}}));
        } catch (error) {
          console.error('Error uploading image:', error);
          setUploadingImageError(error.errors?.[0].message);
        }
      };
      
      reader.onerror = error => {
        console.error('Error reading file:', error);
        setUploadingImageError(error);
      };
    } catch (error) {
      console.error('Error handling image upload:', error);
      setUploadingImageError(error.errors?.[0].message);
    } finally {
      setIsUploadingImage(false);
    }
  };

  const handleBadgePreviewUpload = async (file) => {
    if (!file) return;
    
    try {
      setIsUploadingImage(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      reader.onload = async () => {
        try {
          const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
          const response = await client.graphql({
            query: UPLOAD_IMAGE_MUTATION,
            variables: {
              imageBuffer: base64FileBuffer,
              imageType: "eventImage",
            },
          });
          
          const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
          setEvent(oldData => ({...oldData, badgePreview: {fileName}}));
        } catch (error) {
          console.error('Error uploading badge preview:', error);
          setUploadingImageError(error.errors?.[0].message);
        }
      };
      
      reader.onerror = error => {
        console.error('Error reading file:', error);
        setUploadingImageError(error);
      };
    } catch (error) {
      console.error('Error handling badge preview upload:', error);
      setUploadingImageError(error.errors?.[0].message);
    } finally {
      setIsUploadingImage(false);
    }
  };

  const handleFileUpload = async (file) => {
    if (!file) return;
    
    try {
      setIsUploadingFile(true);
      const fileName = file.name;
      const result = await uploadData({
        key: "eventFiles/" + fileName,
        data: file,
      }).result;

      const fileURL = await getUrl({ key: "public/eventFiles/" + fileName });
      setEvent(oldData => ({
        ...oldData,
        floorPlan: {
          fileName: fileName,
          s3Path: "public/eventFiles/" + fileName
        }
      }));
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadingFileError(error.errors?.[0]?.message || error.message);
    } finally {
      setIsUploadingFile(false);
    }
  };

  const handleRemoveFile = async (path) => {
    try {
      await remove({ key: path });
      setEvent(oldData => ({...oldData, floorPlan: null}));
    } catch (error) {
      console.error('Error removing file:', error);
    }
  };

  const handleFileDownload = async (path) => {
    try {
      const getUrlResult = await getUrl({
        key: path.replace('public/', ''),
      });
      window.open(getUrlResult.url, '_blank');
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleProductData = async (eventData) => {
    // Build Product Data
    let productData = {
      type: "ticket",
      subType: "eventTicket",
      name: eventData.name.map(n => ({
        language: n.language,
        text: "Ticket " + n.text
      })
      ),
      sku: `TICKET-${event.name.find(n => n.language === "en")?.text.replace(/ /g, '-').toUpperCase() || event.name[0]?.text.replace(/ /g, '-').toUpperCase()}`,
      
      image: {
        main: {
          fileName: eventData.image?.fileName || null
        }
      },
      allowedBuyerGroups: ["business", "consumer"],
      requiredShipping: false,
      icon: "ticket-alt",
    };

    if (eventData.ticketProduct) {
      // Check if the product has been changed on the fields: name, price, image
      if(eventData.ticketProduct.name[0].text === productData.name[0].text &&
        eventData.ticketProduct.price === productData.price &&
        eventData.ticketProduct?.image?.main?.fileName === productData.image.main.fileName) {
          return eventData.ticketProduct.id;
      }


        await client.graphql({
            query: /* GraphQL */ `
            mutation UpdateProduct($input: UpdateProductInput!) {
                updateProduct(input: $input) {
                    id
                }
            }
            `,
            variables: {
                input: {
                    id: eventData.ticketProduct.id,
                    ...productData
                }
            }
        });
        return eventData.ticketProduct.id;
    } else {
        const { data } = await client.graphql({
            query: /* GraphQL */ `
            mutation CreateProduct($input: CreateProductInput!) {
                createProduct(input: $input) {
                    id
                }
            }
            `,
            variables: {
                input: productData
            }
        });
        return data.createProduct.id;
    }
};

  const updateEvent = async () => {
    try {
      let eventTicketProductId = await handleProductData(event);
      let updatedEvent = { ...event, eventTicketProductId };
      delete updatedEvent.industries;
      delete updatedEvent.ticketProduct;
      
      if (eventId) {
        await client.graphql({
          query: UPDATE_EVENT_MUTATION,
          variables: { input: { id: eventId, ...updatedEvent } },
        });
      } else {
        const result = await client.graphql({
          query: CREATE_EVENT_MUTATION,
          variables: { input: updatedEvent },
        });
        // Return the new ID if this was a creation
        setEvent(oldData => ({...oldData, id: result.data.createEvent.id, ticketProduct: {id: eventTicketProductId}}));
        return result.data.createEvent.id;
      }
    } catch (error) {
      console.error('Error saving event:', error);
      throw error; // Propagate the error to handle it in the component
    }
  };

  return {
    event,
    isLoading,
    isUploadingImage,
    uploadingImageError,
    isUploadingFile,
    uploadingFileError,
    handleInputChange,
    handleImageUpload,
    handleBadgePreviewUpload,
    handleFileUpload,
    handleRemoveFile,
    handleFileDownload,
    updateEvent
  };
};

// GraphQL Queries and Mutations
const GET_EVENT_QUERY = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      type
      name {
        language
        text
      }
      description {
        language
        text
      }
      bookingNotice {
        language
        text
      }
      ticketNotice {
        language
        text
      }
      image {
        fileName
      }
      location {
        name {
          language
          text
        }
        travelAndParkingNotice {
          language
          text
        }
        address {
          street
          streetNumber
          zip
          city
          countryCode
        }
        coordinates {
          lat
          lng
        }
      }
      ticketOrganizerLine {
        language
        text
      }
      status
      enableTicketBooking
      hasInvitationCodes
      bookingSelectDays
      boothBooking
      startDate
      endDate
      startTime
      endTime
      exhibitorLectures
      lectureRooms
      lectureRegistrationStartDate
      lectureRegistrationEndDate
      lectureDocumentsEndDate
      lectureRecordingCorrectionEndDate
      deadlineBoothEquipmentPrint
      startBoothEquipmentBooking
      deadlineBoothEquipmentBooking
      landingPageUrl
      lecturesNotice {
        language
        text
      }
      floorPlan {
        fileName
        s3Path
      }
      availableLectureTypes
      industries {
        items {
          industry {
            id
          }
        }
      }
      ticketProduct {
        id
        name {
          language
          text
        }
        price
        image {
          main {
            fileName
          }
        }
      }
      badgePreview {
        fileName
      }
      bookingTicketBulletPoints {
        text {
          language
          text
        }
      }
      ticketTAC {
        language
        text
      }
      ticketPrivacyPolicy {
        language
        text
      }
      boothTAC {
        language
        text
      }
      boothPrivacyPolicy {
        language
        text
      }
    }
  }
`;

const UPDATE_EVENT_MUTATION = /* GraphQL */ `
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
    }
  }
`;

const CREATE_EVENT_MUTATION = /* GraphQL */ `
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
    }
  }
`;

const UPLOAD_IMAGE_MUTATION = /* GraphQL */ `
  mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
    uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
  }
`;