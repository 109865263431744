import React, { memo, useCallback, useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBTextArea, MDBInputGroup, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertHTMlToRaw } from "../../../utils/HTMLDraft";
import { ContentState, convertFromHTML } from 'draft-js';

// Ausgelagerte BulletPointInput Komponente mit memo
const BulletPointInput = memo(({ bulletPoint, index, onUpdate, onRemove }) => {
  const { t } = useTranslation();
  
  const handleGermanTextChange = useCallback((value) => {
    const updatedTexts = bulletPoint.text.map(t => 
      t.language === 'de' ? { ...t, text: value } : t
    );
    onUpdate(index, { text: updatedTexts });
  }, [bulletPoint.text, index, onUpdate]);

  const handleEnglishTextChange = useCallback((value) => {
    const updatedTexts = bulletPoint.text.map(t => 
      t.language === 'en' ? { ...t, text: value } : t
    );
    onUpdate(index, { text: updatedTexts });
  }, [bulletPoint.text, index, onUpdate]);

  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);

  

  return (
    <MDBRow className="mb-3">
      <MDBCol md="5">
        <MDBInputGroup>
          <div className="input-group-text">
            <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
          </div>
          <MDBInput
            type="text"
            value={bulletPoint.text.find(t => t.language === 'de')?.text || ''}
            onChange={(e) => handleGermanTextChange(e.target.value)}
            label={t('Bullet Point (German)')}
          />
        </MDBInputGroup>
      </MDBCol>
      <MDBCol md="5">
        <MDBInputGroup>
          <div className="input-group-text">
            <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
          </div>
          <MDBInput
            type="text"
            value={bulletPoint.text.find(t => t.language === 'en')?.text || ''}
            onChange={(e) => handleEnglishTextChange(e.target.value)}
            label={t('Bullet Point (English)')}
          />
        </MDBInputGroup>
      </MDBCol>
      <MDBCol md="2">
        <MDBBtn 
          color="danger" 
          size="sm"
          onClick={handleRemove}
        >
          {t('Remove')}
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  );
});

BulletPointInput.displayName = 'BulletPointInput';

const TextsTab = ({ event, onInputChange }) => {
  const { t, i18n } = useTranslation();

  const handleBulletPointUpdate = useCallback((index, updatedBulletPoint) => {
    const updatedBulletPoints = [...(event.bookingTicketBulletPoints || [])];
    updatedBulletPoints[index] = updatedBulletPoint;
    onInputChange('bookingTicketBulletPoints', updatedBulletPoints);
  }, [event.bookingTicketBulletPoints, onInputChange]);

  const handleBulletPointRemove = useCallback((index) => {
    const updatedBulletPoints = event.bookingTicketBulletPoints.filter((_, i) => i !== index);
    onInputChange('bookingTicketBulletPoints', updatedBulletPoints);
  }, [event.bookingTicketBulletPoints, onInputChange]);

  const handleAddBulletPoint = useCallback(() => {
    const newBulletPoint = {
      text: [
        { language: 'de', text: '' },
        { language: 'en', text: '' }
      ]
    };
    const updatedBulletPoints = [...(event.bookingTicketBulletPoints || []), newBulletPoint];
    onInputChange('bookingTicketBulletPoints', updatedBulletPoints);
  }, [event.bookingTicketBulletPoints, onInputChange]);

  // Initialize editor states for all WYSIWYG fields
  const [editorStates, setEditorStates] = useState({
    ticketTAC: {
      de: null,
      en: null,
    },
    ticketPrivacyPolicy: {
      de: null,
      en: null,
    },
    boothTAC: {
      de: null,
      en: null,
    },
    boothPrivacyPolicy: {
      de: null,
      en: null,
    },
  });

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (event && !isInitialized) {
      const initEditorState = (content) => {
        if (!content) return EditorState.createEmpty();
        const contentState = ContentState.createFromBlockArray(
          convertFromHTML(content).contentBlocks,
          convertFromHTML(content).entityMap
        );
        return EditorState.createWithContent(contentState);
      };

      setEditorStates({
        ticketTAC: {
          de: initEditorState(event.ticketTAC?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.ticketTAC?.find(t => t.language === 'en')?.text),
        },
        ticketPrivacyPolicy: {
          de: initEditorState(event.ticketPrivacyPolicy?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.ticketPrivacyPolicy?.find(t => t.language === 'en')?.text),
        },
        boothTAC: {
          de: initEditorState(event.boothTAC?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.boothTAC?.find(t => t.language === 'en')?.text),
        },
        boothPrivacyPolicy: {
          de: initEditorState(event.boothPrivacyPolicy?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.boothPrivacyPolicy?.find(t => t.language === 'en')?.text),
        },
      });
      setIsInitialized(true);
    }
  }, [event, isInitialized]);

  // Generic handler for editor state changes
  const handleEditorStateChange = (editorState, field, language) => {
    setEditorStates(prevStates => ({
      ...prevStates,
      [field]: {
        ...prevStates[field],
        [language]: editorState
      }
    }));

    const updatedContent = [...(event[field] || [])];
    const contentIndex = updatedContent.findIndex(item => item.language === language);
    const newContent = {
      language,
      text: convertHTMlToRaw(editorState)
    };

    if (contentIndex === -1) {
      updatedContent.push(newContent);
    } else {
      updatedContent[contentIndex] = newContent;
    }

    onInputChange(field, updatedContent);
  };

  const renderEditorSection = (title, field) => (
    <>
      <h5 className="mb-3">{t(title)}</h5>
      <MDBRow className="mb-4">
        <MDBCol md="6">
          <MDBInputGroup className="mb-3">
            <div className="input-group-text">
              <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <div className="w-100">
              <Editor
                editorState={editorStates[field].de}
                onEditorStateChange={(state) => handleEditorStateChange(state, field, 'de')}
                editorClassName="form-control"
                toolbar={{
                  options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'history'],
                }}
              />
            </div>
          </MDBInputGroup>
        </MDBCol>
        <MDBCol md="6">
          <MDBInputGroup>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <div className="w-100">
              <Editor
                editorState={editorStates[field].en}
                onEditorStateChange={(state) => handleEditorStateChange(state, field, 'en')}
                editorClassName="form-control"
                toolbar={{
                  options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'history'],
                }}
              />
            </div>
          </MDBInputGroup>
        </MDBCol>
      </MDBRow>
    </>
  );

  return (
    <>
      {/* Description Section */}
      <h5 className="mb-3">{t("Description")}</h5>
      <MDBRow className="mb-4">
        <MDBCol md="6">
          <MDBInputGroup className="mb-3">
            <div className="input-group-text">
              <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <MDBTextArea
              label={t('Description (German)')}
              value={event?.description?.find(d => d.language === 'de')?.text || ''}
              onChange={(e) => onInputChange('description', e.target.value, 'de')}
            />
          </MDBInputGroup>
        </MDBCol>
        <MDBCol md="6">
          <MDBInputGroup>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <MDBTextArea
              label={t('Description (English)')}
              value={event?.description?.find(d => d.language === 'en')?.text || ''}
              onChange={(e) => onInputChange('description', e.target.value, 'en')}
            />
          </MDBInputGroup>
        </MDBCol>
      </MDBRow>

      {/* Booking Notice Section */}
      <h5 className="mb-3">{t("Booking Notice")}</h5>
      <MDBRow className="mb-4">
        <MDBCol md="6">
          <MDBInputGroup className="mb-3">
            <div className="input-group-text">
              <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <MDBTextArea
              label={t('Booking Notice (German)')}
              value={event?.bookingNotice?.find(n => n.language === 'de')?.text || ''}
              onChange={(e) => onInputChange('bookingNotice', e.target.value, 'de')}
            />
          </MDBInputGroup>
        </MDBCol>
        <MDBCol md="6">
          <MDBInputGroup>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <MDBTextArea
              label={t('Booking Notice (English)')}
              value={event?.bookingNotice?.find(n => n.language === 'en')?.text || ''}
              onChange={(e) => onInputChange('bookingNotice', e.target.value, 'en')}
            />
          </MDBInputGroup>
        </MDBCol>
      </MDBRow>
      <h5 className="mt-4">{t("Booking Ticket Bullet Points")}</h5>
      <MDBRow>
        <MDBCol>
          {event?.bookingTicketBulletPoints?.map((bulletPoint, index) => (
            <BulletPointInput
              key={`${index}-${bulletPoint.text[0]?.text}-${bulletPoint.text[1]?.text}`}
              bulletPoint={bulletPoint}
              index={index}
              onUpdate={handleBulletPointUpdate}
              onRemove={handleBulletPointRemove}
            />
          ))}
          <MDBBtn onClick={handleAddBulletPoint}>
            {t('Add Bullet Point')}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      {/* Ticket Notice Section */}
      <h5 className="mb-3">{t("Ticket Notice")}</h5>
      <MDBRow className="mb-4">
        <MDBCol md="6">
          <MDBInputGroup className="mb-3">
            <div className="input-group-text">
              <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <MDBTextArea
              label={t('Ticket Notice (German)')}
              value={event?.ticketNotice?.find(n => n.language === 'de')?.text || ''}
              onChange={(e) => onInputChange('ticketNotice', e.target.value, 'de')}
            />
          </MDBInputGroup>
        </MDBCol>
        <MDBCol md="6">
          <MDBInputGroup>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <MDBTextArea
              label={t('Ticket Notice (English)')}
              value={event?.ticketNotice?.find(n => n.language === 'en')?.text || ''}
              onChange={(e) => onInputChange('ticketNotice', e.target.value, 'en')}
            />
          </MDBInputGroup>
        </MDBCol>
      </MDBRow>

      {/* Ticket Organizer Line Section */}
      <h5 className="mb-3">{t("Ticket Organizer Line")}</h5>
      <MDBRow className="mb-4">
        <MDBCol md="6">
          <MDBInputGroup className="mb-3">
            <div className="input-group-text">
              <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <MDBInput
              label={t("Ticket Organizer Line (German)")}
              value={event?.ticketOrganizerLine?.find(n => n.language === 'de')?.text || ''}
              onChange={(e) => onInputChange('ticketOrganizerLine', e.target.value, 'de')}
            />
          </MDBInputGroup>
        </MDBCol>
        <MDBCol md="6">
          <MDBInputGroup>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <MDBInput
              label={t("Ticket Organizer Line (English)")}
              value={event?.ticketOrganizerLine?.find(n => n.language === 'en')?.text || ''}
              onChange={(e) => onInputChange('ticketOrganizerLine', e.target.value, 'en')}
            />
          </MDBInputGroup>
        </MDBCol>
      </MDBRow>

      {/* Lectures Notice Section */}
      <h5 className="mb-3">{t("Lectures Notice")}</h5>
      <MDBRow className="mb-4">
        <MDBCol md="6">
          <MDBInputGroup className="mb-3">
            <div className="input-group-text">
              <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <MDBTextArea
              label={t('Lectures Notice (German)')}
              value={event?.lecturesNotice?.find(n => n.language === 'de')?.text || ''}
              onChange={(e) => onInputChange('lecturesNotice', e.target.value, 'de')}
            />
          </MDBInputGroup>
        </MDBCol>
        <MDBCol md="6">
          <MDBInputGroup>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <MDBTextArea
              label={t('Lectures Notice (English)')}
              value={event?.lecturesNotice?.find(n => n.language === 'en')?.text || ''}
              onChange={(e) => onInputChange('lecturesNotice', e.target.value, 'en')}
            />
          </MDBInputGroup>
        </MDBCol>
      </MDBRow>

      {renderEditorSection("Ticket Terms and Conditions", "ticketTAC")}
      {renderEditorSection("Ticket Privacy Policy", "ticketPrivacyPolicy")}
      {renderEditorSection("Booth Terms and Conditions", "boothTAC")}
      {renderEditorSection("Booth Privacy Policy", "boothPrivacyPolicy")}
    </>
  );
};

export default TextsTab;