import { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';

export const useBooths = (eventId) => {
  const client = generateClient();
  const [isLoading, setIsLoading] = useState(true);
  const [eventBooths, setEventBooths] = useState([]);
  const [booths, setBooths] = useState([]);

  useEffect(() => {
    if (eventId) {
      fetchBooths();
    }
  }, [eventId]);

  const fetchBooths = async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        fetchAvailableBooths(),
        fetchEventBooths()
      ]);
    } catch (error) {
      console.error('Error fetching booths:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAvailableBooths = async () => {
    let nextToken = null;
    let allBooths = [];

    do {
      try {
        const response = await client.graphql({
          query: /* GraphQL */ `
            query SearchProducts(
              $filter: SearchableProductFilterInput
              $sort: [SearchableProductSortInput]
              $limit: Int
              $nextToken: String
            ) {
              searchProducts(
                filter: $filter
                sort: $sort
                limit: $limit
                nextToken: $nextToken
              ) {
                items {
                  id
                  name {
                    language
                    text
                  }
                  sku
                  description {
                    language
                    text
                  }
                  price
                  image {
                    main {
                      fileName
                    }
                  }
                }
                nextToken
              }
            }
          `,
          variables: {
            filter: {type: { regexp: "event_booth|event_space" }},
            limit: 1000,
            nextToken
          }
        });

        allBooths = [...allBooths, ...response.data.searchProducts.items];
        nextToken = response.data.searchProducts.nextToken;
      } catch (error) {
        console.error('Error fetching available booths:', error);
        break;
      }
    } while (nextToken);

    setBooths(allBooths);
  };

  const fetchEventBooths = async () => {
    let nextToken = null;
    let allEventBooths = [];

    do {
      try {
        const response = await client.graphql({
          query: /* GraphQL */ `
            query ListAvailableProductsInEvents(
              $filter: ModelAvailableProductsInEventsFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listAvailableProductsInEvents(
                filter: $filter
                limit: $limit
                nextToken: $nextToken
              ) {
                items {
                  id
                  product {
                    id
                    name {
                      language
                      text
                    }
                    sku
                    description {
                      language
                      text
                    }
                    price
                    image {
                      main {
                        fileName
                      }
                    }
                  }
                }
                nextToken
              }
            }
          `,
          variables: {
            filter: { eventId: { eq: eventId } },
            limit: 1000,
            nextToken
          }
        });

        allEventBooths = [...allEventBooths, ...response.data.listAvailableProductsInEvents.items];
        nextToken = response.data.listAvailableProductsInEvents.nextToken;
      } catch (error) {
        console.error('Error fetching event booths:', error);
        break;
      }
    } while (nextToken);

    setEventBooths(allEventBooths);
  };

  const addBoothToEvent = async (boothId) => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          mutation CreateAvailableProductsInEvents($input: CreateAvailableProductsInEventsInput!) {
            createAvailableProductsInEvents(input: $input) {
              id
              product {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            eventId: eventId,
            productId: boothId
          }
        }
      });

      const newBooth = booths.find(b => b.id === boothId);
      setEventBooths([...eventBooths, { 
        id: response.data.createAvailableProductsInEvents.id,
        product: newBooth 
      }]);
    } catch (error) {
      console.error('Error adding booth to event:', error);
    }
  };

  const removeBoothFromEvent = async (relationId) => {
    try {
      await client.graphql({
        query: /* GraphQL */ `
          mutation DeleteAvailableProductsInEvents($input: DeleteAvailableProductsInEventsInput!) {
            deleteAvailableProductsInEvents(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: { id: relationId }
        }
      });

      setEventBooths(eventBooths.filter(eb => eb.id !== relationId));
    } catch (error) {
      console.error('Error removing booth from event:', error);
    }
  };

  return {
    isLoading,
    eventBooths,
    booths,
    addBoothToEvent,
    removeBoothFromEvent
  };
};