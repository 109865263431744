import React, { useState, useEffect, useCallback } from "react";
import { generateClient } from 'aws-amplify/api';
import { diff } from 'deep-object-diff';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBTextArea, MDBBtn, MDBSticky, MDBRipple, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModalTitle, MDBSpinner, MDBFile, MDBCheckbox   } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import adjustChanges from "../../utils/adjustDiffChanges";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {convertHTMLtoDraft, convertHTMLtoDraftInit, ChangeHTMLDraft, ChangeDraftHTMLBtn, convertHTMlToRaw} from "../../utils/HTMLDraft";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { fullCompanyQuery, fullCompanyMutation } from "../../graphql-custom/companyQueryies";

// File upload
import { MDBFileUpload } from 'mdb-react-file-upload';
import {useDropzone} from 'react-dropzone';
import {v4 as uuid} from "uuid";

import SelectTopics from "../../components-user/topics/SelectTopics";

const client = generateClient();
const StateProofImage = React.memo(function Image({ src, alt, className, style }) {
    return <img src={src} alt={alt} className={className} style={style} />;
});

const deepUpdateObject = (object, path, value) => {
    const pathParts = path.split('.');
    let newObject = {...object};
  
    pathParts.reduce((o, p, i) => {
      if (i === pathParts.length - 1) {
        o[p] = value; // Setzt den Wert am Ende des Pfades
      } else {
        o[p] = o[p] ? {...o[p]} : {}; // Erstellt eine Kopie des nächsten Objekts im Pfad, falls es existiert, sonst ein neues Objekt
      }
      return o[p];
    }, newObject);
  
    return newObject;
  };
  

export default function EditCompany_Billing({presetCompanyId, toggleSaveChanges}) {
    const { t, i18n } = useTranslation();
    const companyid = presetCompanyId || (window.location.pathname.includes("mycompany") ? window.location.pathname.split("/")[2] : window.location.pathname.split("/")[3]);

    // Company data
    const [initialCompany, setInitialCompany] = useState({});
    const [company, setCompany] = useState({});
    const [changes, setChanges] = useState({});
    
    const [loading, setLoading] = useState(true);

    // Get data
    const getCompany = async () => {
        try {
            const company = await client.graphql({
                variables: { id: companyid },
                query: /* GraphQL */ `
                query GetCompany($id: ID!) {
                  getCompany(id: $id) {
                    id
                    name
                    billingContact {
                        phone
                        fax
                        email
                        address {
                            street
                            street2
                            streetNumber
                            company
                            contactPerson
                            city
                            zip
                            countryCode
                        }
                    }
                    billingSettings {
                        orderNumberRequired
                    }
                    employees(filter: {role: {eq: "admin"}}) {
                        items {
                          image {
                            fileName
                            alt
                          }
                          user {
                            firstName
                            middleName
                            lastName
                            title
                            salutation
                          }
                        }
                    }
                  }
                }
              `
            });
            return company.data.getCompany;
        } catch (error) {
            console.warn("error fetching company", error);
        }
    }

    const intialLoadCompany = async () => {
        console.log("loading company")
        setLoading(true);
        const companyData = await getCompany();
        setInitialCompany(companyData);
        setCompany(companyData);
        setLoading(false);
    }

    useEffect(() => {
        if(!companyid) return;
        intialLoadCompany();
    }, []);

    // Changes and form handling

    const handleChange = (e) => {
        //console.log("change",e.target.attributes.datapath.value, e.target.value)

        // Get values from input
        const datapath = e.target.attributes.datapath.value;
        const value = e.target.value;

        // Update current company object
        const newCompany = deepUpdateObject(company, datapath, value)
        setCompany(newCompany);
        setChanges(diff(initialCompany, newCompany));
    }

    
    const extractUpdatedTopLevelObjects = (company, changes) => {
        const updatedObjects = {};
      
        Object.keys(changes).forEach(topLevelKey => {
          if (company.hasOwnProperty(topLevelKey)) {
            updatedObjects[topLevelKey] = company[topLevelKey];
          }
        });
      
        return updatedObjects;
      };
    // Update company
    const [isUpdating, setIsUpdating] = useState(false);
    const updateCompany = async () => {
        if(Object.keys(changes).length === 0) return;
        try {
            console.log("changes",changes)
            let updateObject = extractUpdatedTopLevelObjects(company, changes);
            console.log("updateObject",updateObject)
            if(Object.keys(changes).length > 0) {
                const updatedCompany = await client.graphql({
                    variables: { input: {...updateObject, id: companyid}},
                    query: /* GraphQL */ `
                    mutation UpdateCompany(
                      $input: UpdateCompanyInput!
                      $condition: ModelCompanyConditionInput
                    ) {
                      updateCompany(input: $input, condition: $condition) {
                        id
                        name
                        billingContact {
                            phone
                            fax
                            email
                            address {
                                street
                                street2
                                streetNumber
                                contactPerson
                                city
                                zip
                                countryCode
                                company
                            }
                        }
                        billingSettings {
                            orderNumberRequired
                            }
                      }
                    }
                    `
                });
                setInitialCompany(updatedCompany.data.updateCompany);
                setCompany(updatedCompany.data.updateCompany);
            }

            //console.log("updatedCompany",updatedCompany);
            
            setChanges({});

        } catch (error) {
            console.warn("error updating company", error);
        }
    }

    const handleUpdateCompany = async () => {
        setIsUpdating(true);
        await updateCompany();
        setIsUpdating(false);
    }

    const handleResetChanges = () => { 
        setCompany(initialCompany);
        setChanges({});
    }
    

    useEffect(() => {
        if(toggleSaveChanges) {
          console.log("Saving changes to company profile")
          handleUpdateCompany();
        }
      }, [toggleSaveChanges])


  return (
<>
{
    typeof toggleSaveChanges !== "boolean" &&
    <MDBSticky
    offset={60}
    boundary
    tag='div'
    href=''
    >
    <MDBRow className="mb-3">
        <MDBCol size="12" className="text-end">
        <MDBBtn onClick={() => handleResetChanges()} color='light' rippleColor='dark' size="sm">
        {t("Reset Changes")}
        </MDBBtn>
        {
            isUpdating ?
            <MDBBtn color='primary' rippleColor='dark' size="sm">
            <div className="d-flex align-items-center">
            <div className="me-2">
            <MDBSpinner grow size="sm" />
            </div>
            {t("Updating")}
            </div>
            </MDBBtn>
            :
            <MDBBtn onClick={() => handleUpdateCompany()} color="success" outline>
        {t("Save")}
        </MDBBtn>
        }
        
        </MDBCol>
    </MDBRow>
</MDBSticky>
}





<MDBCard className="mb-4">
    <MDBCardBody>
    {/* <MDBCardTitle className="mb-4">{t("Anschriften")}</MDBCardTitle> */}
    {loading || !company ?
        <div>Loading...</div>
        :
        <>
        <MDBRow>
            <MDBCol md="6"> 
                <h5>{t("Billing Address")}</h5>
                <h6>{t("Address")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="12">
                        <MDBInput autoComplete="off" datapath="billingContact.address.company" label={company.billingContact?.address?.company ? "Company" : company.name} value={company.billingContact?.address?.company || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol size="8">
                        <MDBInput autoComplete="off" datapath="billingContact.address.street" label={t("Street")} value={company.billingContact?.address?.street || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                    <MDBCol size="4">
                        <MDBInput autoComplete="off" datapath="billingContact.address.streetNumber" label={t("Number")} value={company.billingContact?.address?.streetNumber || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="billingContact.address.street2" label={t("Street Additonal")} value={company.billingContact?.address?.street2 || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="billingContact.address.zip" label={t("Zip")} value={company.billingContact?.address?.zip || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="billingContact.address.city" label={t("City")} value={company.billingContact?.address?.city || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="billingContact.address.countryCode" label={t("Country")} value={company.billingContact?.address?.countryCode || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <h5>{t("Contact & Invoice Receivers")}</h5>
                <MDBRow className="mb-3">
                <h6>{t("Receivers Administrators")}</h6>
                <MDBCol size="12" className="mb-3">
                        {company.employees?.items?.map((employee, index) => (
                           <MDBCard className="shadow-5 mx-5 mt-4" key={index}>
                            <MDBCardBody>
                            <div className='d-flex align-items-center'>
                            {employee.image?.fileName ?
                                        <img
                                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+employee.image.fileName}
                                            className="rounded-circle border"
                                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                            alt={employee.image.alt}
                                        />
                                        :
                                        <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                                        
                            }
                            <div className='ms-3'>
                            <p className='fw-bold mb-1'>{employee.user?.title ? employee.user?.title + " " : ""}{employee.user?.firstName} {employee.user?.middleName} {employee.user?.lastName}</p>
                            <p className='text-muted mb-0'>{employee.contact?.email}</p>
                            </div>
                            </div>
                            </MDBCardBody>
                           </MDBCard>
                        ))
                        }
                    </MDBCol>
                    <h6>{t("Accounting department contact")}</h6>
                    
                    <MDBCol size="12" md="6" className="mb-3 mb-md-0">
                    <MDBInput autoComplete="off" datapath="billingContact.email" label={t("Email")} value={company.billingContact?.email || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                    <MDBCol size="12" md="6" className="mb-3 mb-md-0">
                        <PhoneInput
                            value={company.billingContact?.phone || ""}
                            placeholder={t("Phone")}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "billingContact.phone"}}, value: phone}})}
                            disabled={isUpdating}
                        />
                    </MDBCol>
                </MDBRow>
                
            </MDBCol>
            <MDBCol md="6" className="border-start">
            <h5>{t("Prefered Payment Method")}</h5>
            <MDBCard className="shadow-5 mx-5 mt-4">
                <MDBCardBody>
                    <div className='d-flex align-items-center'>
                        <MDBIcon fas icon="file-invoice-dollar" size="2x" />
                            <div className='ms-3'>
                            <p className='fw-bold mb-1'>Rechnung</p>
                            <p className='text-muted mb-0'>{company.name}</p>
                            </div>
                    </div>
                </MDBCardBody>
            </MDBCard>
            <h5 className="mt-4">{t("Additional Payment Settings")}</h5>
            <MDBCheckbox
               label={t("An order number is always required")}
                checked={company.billingSettings?.orderNumberRequired || false}
                onChange={(e) => handleChange({target: {attributes: {datapath: {value: "billingSettings.orderNumberRequired"}}, value: e.target.checked}})}

               />
            </MDBCol>
        </MDBRow>
    </>
    }
    </MDBCardBody>
</MDBCard>


   
</>
  )
}
