import React, { useState, useEffect } from 'react';
import { 
  MDBCard, 
  MDBCardBody, 
  MDBTabs, 
  MDBTabsItem, 
  MDBTabsLink, 
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBIcon,
  MDBInput,
  MDBSpinner,
  MDBRow,
  MDBCol,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { generateClient } from 'aws-amplify/api';
import * as XLSX from 'xlsx';
import shortUUID from 'short-uuid';
import { getLocalizedText } from "../../utils/localizedText";

const client = generateClient();

export default function AdminEventInvitationCodes() {
  const { t, i18n } = useTranslation();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [codesUsage, setCodesUsage] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [newCodes, setNewCodes] = useState({});

  // Load initial events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsData = await client.graphql({
          query: /* GraphQL */ `
            query ListEvents {
              listEvents(filter: { status: { eq: "active" }, type: { eq: "fair" }, hasInvitationCodes: { eq: true } }) {
                items {
                  id
                  name {
                    language
                    text
                  }
                  startDate
                  endDate
                }
              }
            }
          `
        });
        const sortedEvents = eventsData.data.listEvents.items.sort((a, b) => 
          new Date(b.startDate) - new Date(a.startDate)
        );
        setEvents(sortedEvents);
        if (sortedEvents.length > 0) {
          setSelectedEvent(sortedEvents[0].id);
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, []);

  // Load companies with booths and their codes when event is selected
  useEffect(() => {
    if (!selectedEvent) return;

    const fetchCompaniesWithBooths = async () => {
      setIsLoading(true);
      try {
        let allBookedBooths = [];
        let nextToken = null;

        do {
        const response = await client.graphql({
            query: /* GraphQL */ `
            query ListBookedBooths($filter: ModelBookedBoothFilterInput, $eventId: ID!, $nextToken: String) {
                listBookedBooths(filter: $filter, nextToken: $nextToken) {
                items {
                    company {
                    id
                    name
                    image {
                        main {
                        fileName
                        }
                    }
                    invitationCodes(filter: {eventInvitationCodesId: {eq: $eventId}}) {
                        items {
                        id
                        code
                        }
                    }
                    }
                    orderLineItem {
                        order {
                        deliveryAddress {
                            company
                            city
                            contactPerson
                            countryCode
                            firstName
                            lastName
                            street
                            streetNumber
                            street2
                            zip
                        }
                        user {
                            firstName
                            lastName
                            salutation
                        }
                        }
                    }
                }
                nextToken
                }
            }
            `,
            variables: {
            filter: {
                eventBookedBoothsId: { eq: selectedEvent }
            },
            eventId: selectedEvent,
            nextToken: nextToken
            }
        });

        allBookedBooths = allBookedBooths.concat(response.data.listBookedBooths.items);
        nextToken = response.data.listBookedBooths.nextToken;
        } while (nextToken);
        
        // Transform the data structure and remove duplicates
        const companiesWithBooths = allBookedBooths
          .map(item => ({...item.company, orderLineItem: item.orderLineItem}))
          .filter((company, index, self) => 
            index === self.findIndex((c) => c.id === company.id)
          )
            .sort((a, b) => a.name.localeCompare(b.name));
        
        setCompanies(companiesWithBooths);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching companies with booths:', error);
        setIsLoading(false);
      }
    };
    fetchCompaniesWithBooths();
  }, [selectedEvent]);

  // Count code usage
  useEffect(() => {
    const fetchCodeUsage = async (codes) => {
      try {
        const usageData = {};
        const codesAlreadyFetched = Object.keys(codesUsage);
        await Promise.all(codes.filter(code => !codesAlreadyFetched.includes(code.code)).map(async (code) => {
          const ticketsData = await client.graphql({
            query: /* GraphQL */ `
              query SearchTickets($filter: SearchableTicketFilterInput) {
                searchTickets(filter: $filter) {
                  total
                }
              }
            `,
            variables: {
              filter: {
                invitationCode: { eq: code.code },
                ticketEventId: { eq: selectedEvent }
              }
            }
          });
          usageData[code.code] = ticketsData.data.searchTickets.total;
        }));
        setCodesUsage(prev => ({...prev, ...usageData}));
      } catch (error) {
        console.error('Error fetching code usage:', error);
      }
    };

    companies.forEach(company => {
      if (company.invitationCodes?.items?.length > 0) {
        fetchCodeUsage(company.invitationCodes.items);
      }
    });
  }, [companies, selectedEvent]);

  const handleAddCode = async (companyId, code) => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          mutation CreateInvitationCode($input: CreateInvitationCodeInput!) {
            createInvitationCode(input: $input) {
              id
              code
            }
          }
        `,
        variables: {
          input: {
            code: code.toUpperCase(),
            createdByAdmin: true,
            companyInvitationCodesId: companyId,
            eventInvitationCodesId: selectedEvent
          }
        }
      });

      // Update local state
      setCompanies(companies.map(company => {
        if (company.id === companyId) {
          return {
            ...company,
            invitationCodes: {
              items: [
                ...company.invitationCodes.items,
                response.data.createInvitationCode
              ]
            }
          };
        }
        return company;
      }));

      // Clear new code input
      setNewCodes(prev => {
        const newCodes = {...prev};
        delete newCodes[companyId];
        return newCodes;
      });
    } catch (error) {
      console.error('Error adding code:', error);
    }
  };

  const handleDeleteCode = async (companyId, codeId) => {
    try {
      await client.graphql({
        query: /* GraphQL */ `
          mutation DeleteInvitationCode($input: DeleteInvitationCodeInput!) {
            deleteInvitationCode(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: {
            id: codeId
          }
        }
      });

      // Update local state
      setCompanies(companies.map(company => {
        if (company.id === companyId) {
          return {
            ...company,
            invitationCodes: {
              items: company.invitationCodes.items.filter(code => code.id !== codeId)
            }
          };
        }
        return company;
      }));
    } catch (error) {
      console.error('Error deleting code:', error);
    }
  };

  const handleDownloadExcel = () => {
    // Daten für Excel vorbereiten
    const excelData = [];
    
    // Header hinzufügen
    excelData.push(['Company', 'Code', 'Usage', "Admin_Anrede", "Admin_Vorname", "Admin_Nachname", "Adresse_Firma", "Straße", "Hausnummer", "Straße_2", "PLZ", "Ort", "Land"])
    
    // Daten für jede Firma hinzufügen
    companies.forEach(company => {
      company.invitationCodes?.items?.forEach(code => {
        excelData.push([
          company.name,
          code.code,
          codesUsage[code.code] || 0,
            company.orderLineItem?.order?.user?.salutation || "",
            company.orderLineItem?.order?.user?.firstName || "",
            company.orderLineItem?.order?.user?.lastName || "",
            company.orderLineItem?.order?.deliveryAddress?.company || company.name,
            company.orderLineItem?.order?.deliveryAddress?.street || "",
            company.orderLineItem?.order?.deliveryAddress?.streetNumber || "",
            company.orderLineItem?.order?.deliveryAddress?.street2 || "",
            company.orderLineItem?.order?.deliveryAddress?.zip || "",
            company.orderLineItem?.order?.deliveryAddress?.city || "",
            company.orderLineItem?.order?.deliveryAddress?.countryCode || ""
        ]);
      });
    });

    // Arbeitsmappe erstellen
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(excelData);

    // Spaltenbreiten automatisch anpassen
    const colWidths = [
      { wch: 30 },  // Company
      { wch: 15 },  // Code
      { wch: 10 }   // Usage
    ];
    ws['!cols'] = colWidths;

    // Worksheet zur Arbeitsmappe hinzufügen
    XLSX.utils.book_append_sheet(wb, ws, 'Invitation Codes');

    // Excel-Datei herunterladen
    XLSX.writeFile(wb, `Einladungscodes-${getLocalizedText(events.find(e => e.id === selectedEvent).name)}.xlsx`);
  };

  const generateAndAddCodes = async (companyId) => {
    console.log("generateAndAddCodes", companyId);
    let companiesToAddCodesTo = [];
    if (companyId) {
        companiesToAddCodesTo = companies.filter(company => company.id === companyId);
    } else {
        companiesToAddCodesTo = companies.filter(
      company => !company.invitationCodes?.items?.length
    );
    }
    console.log(companiesToAddCodesTo);
    for (const company of companiesToAddCodesTo) {
      const newCode = shortUUID().new().substring(0, 6).toUpperCase();
      try {
        await handleAddCode(company.id, newCode);
      } catch (error) {
        console.error(`Error adding code for company ${company.name}:`, error);
      }
    }
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <MDBTabs className='mb-3'>
          {events.map((event) => (
            <MDBTabsItem key={event.id}>
              <MDBTabsLink
                onClick={() => setSelectedEvent(event.id)}
                active={selectedEvent === event.id}
              >
                {event.name.find(n => n.language === i18n.language)?.text || event.name[0].text}
              </MDBTabsLink>
            </MDBTabsItem>
          ))}
        </MDBTabs>
        <div className="d-flex gap-2 mb-3">
          <MDBBtn
            color="primary" 
            onClick={handleDownloadExcel}
            disabled={isLoading || companies.length === 0}
          >
            <MDBIcon fas icon="file-excel" className="me-2" />
            {t("Download")}
          </MDBBtn>
          <MDBBtn
            color="success"
            onClick={() => generateAndAddCodes()}
            disabled={isLoading || companies.length === 0}
          >
            <MDBIcon fas icon="magic" className="me-2" />
            {t("Generate Missing Codes")}
          </MDBBtn>
        </div>
        <MDBTabsContent>
          <MDBTabsPane open={true}>
            {isLoading ? (
              <div className="text-center">
                <MDBSpinner />
              </div>
            ) : (
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>{t("Company")}</th>
                    <th>{t("Invitation Codes")}</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {companies.map((company) => (
                    <tr key={company.id}>
                      <td className="align-middle">
                        <div className="d-flex align-items-center">
                          {company.image?.main?.fileName && (
                            <img
                              src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/${company.image.main.fileName}`}
                              alt={company.name}
                              style={{ width: '30px', height: '30px', marginRight: '10px' }}
                              className="rounded-circle"
                            />
                          )}
                          {company.name}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-wrap gap-2 align-items-center">
                          {company.invitationCodes?.items?.map((code) => (
                            <div key={code.id} className="border rounded px-2 py-1 d-flex align-items-center">
                              <span className="me-2">{code.code}</span>
                              <span className="me-2 text-muted">({codesUsage[code.code] || 0})</span>
                              <MDBIcon
                                fas
                                icon="times"
                                className="text-danger cursor-pointer"
                                onClick={() => handleDeleteCode(company.id, code.id)}
                              />
                            </div>
                          ))}
                          {Object.keys(newCodes).includes(company.id) ? (
                            <MDBRow className="align-items-center g-2">
                              <MDBCol size="auto">
                                <MDBInput
                                  type="text"
                                  value={newCodes[company.id]}
                                  onChange={(e) => setNewCodes(prev => ({
                                    ...prev,
                                    [company.id]: e.target.value
                                  }))}
                                  className="m-0"
                                  style={{ width: '150px' }}
                                />
                              </MDBCol>
                              <MDBCol size="auto">
                                <MDBIcon
                                  fas
                                  icon="check"
                                  className="text-success cursor-pointer"
                                  onClick={() => handleAddCode(company.id, newCodes[company.id])}
                                />
                               
                              </MDBCol>
                            </MDBRow>
                          ) : <>
                            <MDBIcon
                              fas
                              icon="plus"
                              className="cursor-pointer"
                              onClick={() => setNewCodes(prev => ({...prev, [company.id]: ''}))}
                            />
                            <MDBIcon
                            fas
                            icon="magic"
                            className="text-success cursor-pointer"
                            onClick={() => generateAndAddCodes(company.id)}
                             />
                             </>
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            )}
          </MDBTabsPane>
        </MDBTabsContent>
      </MDBCardBody>
    </MDBCard>
  );
}