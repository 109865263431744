
import React, { useEffect, useState } from 'react';
import EmployeeSelector from "../../components-user/news/EmployeeSelector";
import { MDBInput, MDBCheckbox, MDBBtn, MDBRow, MDBCol, MDBTypography, MDBInputGroup, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBChip, MDBCard, MDBCardBody, MDBCardTitle, MDBFile, MDBSpinner, MDBIcon, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBSelect, MDBTextArea, MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertFromHTML} from 'draft-js';
import {convertHTMlToRaw} from "../../utils/HTMLDraft";
import SelectTopics from "../../components-user/topics/SelectTopics";
import { generateClient } from 'aws-amplify/api';
import { useNavigate, useParams } from "react-router-dom";
import { components } from "@aws-amplify/ui-react";
import { getLocalizedText } from "../../utils/localizedText";
import { useIndustry } from "../../utils/industryContextHook";


const client = generateClient();

const CreateProductPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { availableIndustries } = useIndustry();
    const { productId } = useParams();

    const { t, i18n } = useTranslation();
    const [productData, setProductData] = useState({});

    const [activeTab, setActiveTab] = useState("general");

    const handleClickTab = (tab) => {
        if(tab !== activeTab) {
            setActiveTab(tab);
        }
    }

    const [categories, setCategories] = useState([]);
    const getCategories = async () => {
      try {
        const categoryRes = await client.graphql({
          query: /* GraphQL */ `
          query ListProductCategorys(
            $filter: ModelProductCategoryFilterInput
            $limit: Int
            $nextToken: String
          ) {
            listProductCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {

              items {
                id
                name {
                  language
                  text
                }
                customerTypes
                description {
                  language
                  text
                }
                sortKey
              }
              nextToken
            }
          }
        `,
        });
        const categories = categoryRes.data.listProductCategories.items;
        setCategories(categories);
      } catch (error) {
        console.warn("Error getting categories", error);
      }
    };

    const [selectedIndustryIds, setSelectedIndustryIds] = useState([]);

    const getProductData = async () => {
      try {
        const productRes = await client.graphql({
          variables: { id: productId },
          query: /* GraphQL */ `
          query GetProduct($id: ID!) {
            getProduct(id: $id) {
              id
              sku
              name {
                language
                text
              }
              invoiceName {
                language
                text
              }
              description {
                language
                text
              }
              includes {
                language
                text
              }
              icon
              requiredShipping
              allowedBuyerGroups
              price
              downPaymentPrice
              stock
              type
              subType
              visibleInShop
              productCategoryProductsId
             
              components {
                items {
                  id
                  componentProductID
                  qty
                  order
                }
              }
              image {
                main {
                  fileName
                }
              }
              files {
                fileName
              }
              eventProducts {
                items {
                  id
                  event {
                    id
                    name {
                      language
                      text
                    }
                  }
                  price
                  stock
                  isAvailable
                }
              }
            }
          }
          
        `,
        });
        //console.log(productRes);
        const productData = {
          ...productRes.data.getProduct,
          componentIds: productRes.data.getProduct.components.items.map((c) => c.componentProductID),
          components: productRes.data.getProduct.components.items,
        }
        return productData
      } catch (error) {
        console.warn("Error getting product data", error);
      }
    };

  const intialLoadProduct = async () => {
    console.log("loading product");
    setIsLoading(true);
    const dbProduct = await getProductData();
    setProductData(dbProduct);
    if(dbProduct.description) {
      const deContent = dbProduct.description.find(c => c.language === "de")?.text;
      const enContent = dbProduct.description.find(c => c.language === "en")?.text;
      setEditorState({
        de: deContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(deContent)
                        )
                      ) : EditorState.createEmpty(),
        en: enContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(enContent)
                        )
                      ) : EditorState.createEmpty(),
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log("productId", productId)
    if (!productId) {
      console.log("No product id provided");
      getCategories();
      return;
    } else {
      intialLoadProduct();
      getCategories();
    }
  }, []);

 

  const [validationErrors, setValidationErrors] = useState([]);

  
  

  const createProduct = async () => {
    try {
      setIsLoading(true);
      
      // Create or update the product first
      const response = await client.graphql({
        query: productId ? /* GraphQL */ `
        mutation UpdateProduct($input: UpdateProductInput!) {
          updateProduct(input: $input) {
            id  
          }
        }
        ` : /* GraphQL */ `
        mutation CreateProduct(
          $input: CreateProductInput!
          $condition: ModelProductConditionInput
        ) {
          createProduct(input: $input, condition: $condition) {
            id
          }
        }
        `,
        variables: {
          input: {
            sku: productData.sku,
            name: productData.name,
            visibleInShop: productData.visibleInShop,
            productCategoryProductsId: productData.productCategoryProductsId,
            invoiceName: productData.invoiceName,
            description: productData.description,
            includes: productData.includes,
            icon: productData.icon,
            requiredShipping: productData.requiredShipping,
            allowedBuyerGroups: productData.allowedBuyerGroups,
            price: productData.price,
            downPaymentPrice: productData.downPaymentPrice,
            stock: productData.stock,
            image: productData.image,
            type: productData.type,
            subType: productData.subType,
            ...(productId ? {id: productId} : {}),
          },
        },
      });
  
      const currentProductId = productId || response.data[productId ? 'updateProduct' : 'createProduct'].id;
  
      // Handle industry connections
      // First, get existing industry connections
      const existingIndustries = await client.graphql({
        query: `
        query ListAvailableProductsInIndustries($filter: ModelAvailableProductsInIndustriesFilterInput) {
          listAvailableProductsInIndustries(filter: $filter) {
            items {
              id
              industryId
            }
          }
        }
      `,
        variables: {
          filter: { productId: { eq: currentProductId } }
        }
      });
  
      const existingIndustryIds = existingIndustries.data.listAvailableProductsInIndustries.items.map(item => item.industryId);
  
      // Determine which industries to add and remove
      const industriesToAdd = selectedIndustryIds.filter(id => !existingIndustryIds.includes(id));
      const industriesToRemove = existingIndustries.data.listAvailableProductsInIndustries.items
        .filter(item => !selectedIndustryIds.includes(item.industryId));
  
      // Remove old connections
      await Promise.all(industriesToRemove.map(async item => {
        await client.graphql({
          query: `
            mutation DeleteAvailableProductsInIndustries($input: DeleteAvailableProductsInIndustriesInput!) {
              deleteAvailableProductsInIndustries(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: { id: item.id }
          }
        });
      }));
  
      // Add new connections
      await Promise.all(industriesToAdd.map(async industryId => {
        await client.graphql({
          query: `
            mutation CreateAvailableProductsInIndustries($input: CreateAvailableProductsInIndustriesInput!) {
              createAvailableProductsInIndustries(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              productId: currentProductId,
              industryId: industryId
            }
          }
        });
      }));
  
      // Navigate away after successful completion
      setTimeout(() => {
        navigate(`/admin/products`);
        setIsLoading(false);
      }, 2000);
  
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating/updating product:", error);
    }
  };

    // Topics
  // Select Topics 
//   const [topics, setTopics] = useState([]);
//   const [topicGroups, setTopicGroups] = useState([]);
//   const [selectedTopics, setSelectedTopics] = useState(productData.topics || []);


//   const [selectTopicsModal, setSelectTopicsModal] = useState(false);
//   const toggleSelectTopicsModal = () => setSelectTopicsModal(!selectTopicsModal);

//   const handleSetSelectedTopics = (topics) => {
//     setSelectedTopics(topics);
//     setProductData(oldData => ({...oldData, cMSProductTopicId: topics[0]}));
//     // setLectureData(oldLecture => ({...oldLecture, topics}));
//   }

//   // Topics data

//   const getTopics = async () => {
//       try {
//         const topicGroupsWithTopics = await client.graphql({
//           query: /* GraphQL */ `
//           query ListTopicGroups(
//             $filter: ModelTopicGroupFilterInput
//             $limit: Int
//             $nextToken: String
//           ) {
//             listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
//               items {
//                 id
//                 color
//                 name {
//                   language
//                   text
//                 }
//                 topics {
//                   items {
//                     id
//                     name {
//                       language
//                       text
//                     }
//                     topicGroupTopicsId
//                   }
//                 }
//               }
//               nextToken
//               __typename
//             }
//           }
//         `
//           });
//           setTopicGroups(topicGroupsWithTopics.data.listTopicGroups.items);
//           const topics = topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat();
//           setTopics(topics);

//       } catch (error) {
//           console.log("error on fetching topics", error);
//       }
//   }

//   useEffect(() => {
//       if (topics.length === 0) {
//           getTopics();
//       }
//   }, [topics]);

//   const Topics = ({topics, topicGroups, selectedTopics}) => {

//     // First sort the topics by topicGroup (new array with objects for eacht group, inside object the key: topics, inside the topics for this group), then return for each group a col, inside the col the title of the group, then the topics as chips
//     if(topics?.length === 0 || topicGroups?.length === 0 || selectedTopics?.length === 0) {
//         return null;
//     }
//     let sortedTopics = [];
//     selectedTopics.forEach((selectedTopic) => {
//         const topicData = topics.find((topic) => topic.id === selectedTopic);
//         const topicGroup = topicGroups.find((topicGroup) => topicGroup.id === topicData.topicGroupTopicsId);
//         const topicGroupIndex = sortedTopics.findIndex((sortedTopic) => sortedTopic.id === topicGroup.id);
//         if (topicGroupIndex === -1) {
//             sortedTopics.push({...topicGroup, topics: [topicData]});
//         } else {
//             sortedTopics[topicGroupIndex].topics.push(topicData);
//         }
//     });
//     return (
//         <MDBRow className="my-2">
//             {sortedTopics.map((topicGroup) => {
//                 return (
//                     <MDBCol size="12" xxl="6" key={topicGroup.id} className="mb-3 mb-lg-4">
//                         <h6>{topicGroup.name?.find(n => n.language === i18n.language)?.text || topicGroup.name?.[0]?.text}</h6>
//                         <MDBRow>
//                             {topicGroup.topics.map((topic) => {
//                                 return (
//                                     <MDBCol size="12" key={topic.id}>
//                                         <MDBChip className="m-1 float-start" bgColor={topicGroup.color} textColor="white">{topic?.name?.find((name) => name.language === i18n.language)?.text || topic.name[0]?.text}</MDBChip>
//                                     </MDBCol>
//                                 )
//                             })}
//                         </MDBRow>
//                     </MDBCol>
//                 )
//             })}
//         </MDBRow>
//     )
// }


// Editor
// Editor
const [editorState, setEditorState] = useState({
    de: EditorState.createEmpty(),
    en: EditorState.createEmpty(),
})
const onEditorStateChange = (editorState, language ) => {
  setEditorState(oldState => ({...oldState, [language]: editorState}));
  setProductData(oldData => ({...oldData, description: [...(oldData.description?.length > 0 ? oldData.description.filter(c => c.language !== language) : []), {language, text: convertHTMlToRaw(editorState)}] }));

} 

// Image and Script

const [isUploadingLectureFiles, setIsUploadingLectureFiles] = useState(false);
const [uploadingLecturesErrors, setUploadingLecturesErrors] = useState(null);

const handleSetFile = (file) => {
  // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
if(file) {
  try {
    setIsUploadingLectureFiles(true);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onerror = error => {
    console.log('Error: ', error);
    setUploadingLecturesErrors(error);
  }
  reader.onload = async () => {
   try {
    const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
    const response = await client.graphql({
      query: /* GraphQL */ `
        mutation UploadLectureFile($base64FileBuffer: String!, $companyId: String!) {
          uploadLectureFile(base64FileBuffer: $base64FileBuffer, companyId: $companyId) {
            message
            fileName
          }
        }
      `,
      variables: {
        base64FileBuffer,
      },
    });
    console.log("response upload file", response);
    setProductData(oldData => ({...oldData, file: {fileName: response.data.uploadLectureFile.fileName, type: "productFile"}}));
    
   } catch (error) {
    console.log("error", error);
    setIsUploadingLectureFiles(false);
    setUploadingLecturesErrors(error.errors[0].message);
   }
  };
  
  setIsUploadingLectureFiles(false);
  setUploadingLecturesErrors(null);
  } catch (error) {
    console.log("error", error);
    setIsUploadingLectureFiles(false);
    setUploadingLecturesErrors(error.errors[0].message);
  }
  
}
  
}

const [isUploadingLectureImage, setIsUploadingLectureImage] = useState(false);
const [uploadingLectureImageError, setUploadingLectureImageError] = useState(null);

const handleSetImage = (file) => {
  // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
  if(file) {
    try {
      setIsUploadingLectureImage(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = error => {
      console.log('Error reader: ', error);
      setUploadingLectureImageError(error);
    }
    reader.onload = async () => {
      try {
        console.log(reader.result)
        const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
        const response = await client.graphql({
          query: /* GraphQL */ `
          mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
            uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
          }
        `,
          variables: {
            imageBuffer: base64FileBuffer,
            imageType: "productImage",
          },
        });
        console.log("response", response);
        const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
        setProductData(oldData => ({...oldData, image: {main:{fileName, alt: "Lecture Image"}}}));
        
        } catch (error) {
          console.log("error", error);
          setIsUploadingLectureImage(false);
          setUploadingLectureImageError(error.errors[0].message);
        }
    }
    setIsUploadingLectureImage(false);
    setUploadingLectureImageError(null);
    } catch (error) {
      console.log("error", error);
      setIsUploadingLectureImage(false);
      setUploadingLectureImageError(error.errors[0].message);
    }
  }
}

const [orderWithProduct, setOrderWithProduct] = useState([]);

// Components Tab

// Components Tab
const [isLoadingComponents, setIsLoadingComponents] = useState(false);
const [products, setProducts] = useState([]);
const [filteredProducts, setFilteredProducts] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

const filterProducts = (term) => {
  setSearchTerm(term);
  if (!term.trim()) {
    setFilteredProducts(products);
    return;
  }

  const searchValue = term.toLowerCase().trim();
  const filtered = products.filter(product => {
    // Search in all language versions of the name
    const nameMatch = product.name.some(n => 
      n.text.toLowerCase().includes(searchValue)
    );
    // Search in SKU
    const skuMatch = product.sku.toLowerCase().includes(searchValue);
    
    return nameMatch || skuMatch;
  });
  
  setFilteredProducts(filtered);
};

const getProducts = async () => {
  setIsLoading(true);
  try {
    const response = await client.graphql({
      query: /* GraphQL */ `
      query ListProducts(
        $filter: ModelProductFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            sku
            name {
              language
              text
            }
            ean
            type
            subType
            price
            downPaymentPrice
            allowedBuyerGroups
            requiredShipping
            icon
            stock
            createdAt
            updatedAt
            bundleBoothAvaiableAccessoriesId
            bundleBoothIncludedAccessoriesId
            __typename
          }
          nextToken
          __typename
        }
      }
    `,
      variables: {
        limit: 1000
      },
    });
    setProducts(response.data.listProducts.items);
    setFilteredProducts(response.data.listProducts.items);
  } catch (error) {
    console.error('Error fetching products:', error);
  } finally {
    setIsLoading(false);
  }
};

const addProductComponents = async (componentProductID) => {
  try {
    const newProductComponent = await client.graphql({
      query: /* GraphQL */ `
      mutation CreateProductComponent(
        $input: CreateProductComponentInput!
      ) {
        createProductComponent(input: $input) {
          id
          componentProductID
          qty

        }
      }
    `,
      variables: {
        input: {
          parentProductID: productData.id,
          componentProductID,
          qty: 1,
          order: productData.components.length + 1,
        },
      },
    });
    setProductData(oldData => ({...oldData, 
      componentIds: [...oldData.componentIds, newProductComponent.data.createProductComponent.componentProductID],
      components: [...(oldData.components || []), newProductComponent.data.createProductComponent]
    }));
  } catch (error) {
    console.error('Error adding product to event:', error);
  }
};

const removeProductComponents = async (productComponentId) => {
  try {
    const deleteRes = await client.graphql({
      query: /* GraphQL */ `
      mutation DeleteProductComponent(
        $input: DeleteProductComponentInput!
      ) {
        deleteProductComponent(input: $input) {
          id
          componentProductID
        }
      }
    `,
      variables: {
        input: {
          id: productComponentId,
        },
      },
    });
    setProductData(oldData => ({...oldData, 
      componentIds: oldData.componentIds.filter((c) => c !== deleteRes.data.deleteProductComponent.componentProductID),
      components: oldData.components.filter((c) => c.id !== deleteRes.data.deleteProductComponent.id)
    }));
      
  } catch (error) {
    console.error('Error removing product to event:', error);
  }
};

const updateProductComponentQTY = async (productComponentId, qty) => {
  try {
    const updateRes = await client.graphql({
      query: /* GraphQL */ `
      mutation UpdateProductComponent(
        $input: UpdateProductComponentInput!
      ) {
        updateProductComponent(input: $input) {
          id
          componentProductID
          qty
        }
      }
    `,
      variables: {
        input: {
          id: productComponentId,
          qty,
        },
      },
    });
    setProductData(oldData => ({...oldData, 
      components: oldData.components.map((c) => c.id === updateRes.data.updateProductComponent.id ? updateRes.data.updateProductComponent : c)
    }));
  } catch (error) {
    console.error('Error updating product to event:', error);
    
  }
};

const updateProductComponentOrder = async (productComponentId, order) => {
  try {
    const updateRes = await client.graphql({
      query: /* GraphQL */ `
      mutation UpdateProductComponent(
        $input: UpdateProductComponentInput!
      ) {
        updateProductComponent(input: $input) {
          id
          componentProductID
          qty
          order
        }
      }
    `,
      variables: {
        input: {
          id: productComponentId,
          order,
        },
      },
    });
    setProductData(oldData => ({...oldData,
      components: oldData.components.map((c) => c.id === updateRes.data.updateProductComponent.id ? updateRes.data.updateProductComponent : c)
    }));
  } catch (error) {
    console.error('Error updating product to event:', error);

  }
};

// In Events
const [events, setEvents] = useState([]);
const [isLoadingEvents, setIsLoadingEvents] = useState(false);

const getEvents = async () => {
  setIsLoadingEvents(true);
  try {
    const response = await client.graphql({
      query: /* GraphQL */ `
        query ListEvents {
          listEvents {
            items {
              id
              name {
                language
                text
              }
            }
          }
        }
      `,
    });
    setEvents(response.data.listEvents.items);
  } catch (error) {
    console.error('Error fetching events:', error);
  } finally {
    setIsLoadingEvents(false);
  }
};

const addProductToEvent = async (eventId) => {
  try {
    const response = await client.graphql({
      query: /* GraphQL */ `
      mutation CreateEventProduct(
        $input: CreateEventProductInput!
      ) {
        createEventProduct(input: $input) {
          id
          event {
            id 
            name {
              language
              text
            }
          }
          price
          stock
          isAvailable
        }
      }
    `,
      variables: {
        input: {
          eventEventProductsId: eventId,
          productEventProductsId: productId,
          price: productData.price,
          stock: productData.stock,
          isAvailable: true,
        },
      },
    });
    setProductData(prevData => ({
      ...prevData,
      eventProducts: {
        items: [...(prevData.eventProducts?.items || []), response.data.createEventProduct]
      }
    }));
  } catch (error) {
    console.error('Error adding product to event:', error);
  }
};

const removeProductFromEvent = async (eventProductId) => {
  try {
    await client.graphql({
      query: /* GraphQL */ `
      mutation DeleteEventProduct(
        $input: DeleteEventProductInput!
      ) {
        deleteEventProduct(input: $input) {
          id
        }
      }
    `,
      variables: {
        input: {
          id: eventProductId,
        },
      },
    });
    setProductData(prevData => ({
      ...prevData,
      eventProducts: {
        items: prevData.eventProducts.items.filter(item => item.id !== eventProductId)
      }
    }));
  } catch (error) {
    console.error('Error removing product from event:', error);
  }
};

const updateEventProduct = async (eventProductId, price, stock) => {
  try {
    const response = await client.graphql({
      query: /* GraphQL */ `
      mutation UpdateEventProduct(
        $input: UpdateEventProductInput!
      ) {
        updateEventProduct(input: $input) {
          id
          price
          stock
          isAvailable
        }
      }
    `,
      variables: {
        input: {
          id: eventProductId,
          price: parseFloat(price),
          stock: parseInt(stock),
        },
      },
    });
    setProductData(prevData => ({
      ...prevData,
      eventProducts: {
        items: prevData.eventProducts.items.map(item => 
          item.id === eventProductId ? {...item, ...response.data.updateEventProduct} : item
        )
      }
    }));
  } catch (error) {
    console.error('Error updating event product:', error);
  }
};

useEffect(() => {
  if(activeTab === "components" && products.length === 0) {
    getProducts();
  }
  if(activeTab === "events" && events.length === 0) {
    getEvents();
  }
}, [activeTab]);


return (
<>
<MDBRow className="h-auto d-flex justify-content-center">
<MDBCol size="12" xl="12" xxl="11">
<MDBCard>
<MDBCardBody className="px-1 px-md-5">
<MDBBtn onClick={() => createProduct()} color="primary" className="float-end">
  {t("Save")}
</MDBBtn>
<MDBCardTitle>{productId ? t("Edit Product") : t("Create Product")}</MDBCardTitle>
<MDBTabs className='mb-3'>
  <MDBTabsItem>
    <MDBTabsLink onClick={() => handleClickTab('general')} active={activeTab === 'general'}>
      <MDBIcon fas icon="cogs" className='me-2' /> {t("General")}
    </MDBTabsLink>
  </MDBTabsItem>
  <MDBTabsItem>
    <MDBTabsLink onClick={() => handleClickTab('media')} active={activeTab === 'media'}>
      <MDBIcon fas icon='image' className='me-2' /> {t("Media")}
    </MDBTabsLink>
  </MDBTabsItem>
  <MDBTabsItem>
    <MDBTabsLink onClick={() => handleClickTab('pricing')} active={activeTab === 'pricing'}>
      <MDBIcon fas icon='dollar' className='me-2' /> {t("Pricing")}
    </MDBTabsLink>
  </MDBTabsItem>
  <MDBTabsItem>
    <MDBTabsLink onClick={() => handleClickTab('components')} active={activeTab === 'components'}>
      <MDBIcon fas icon='shapes' className='me-2' /> {t("Components")}
    </MDBTabsLink>
  </MDBTabsItem>
  <MDBTabsItem>
  <MDBTabsLink onClick={() => handleClickTab('events')} active={activeTab === 'events'}>
    <MDBIcon fas icon='calendar-alt' className='me-2' /> {t("In Events")}
  </MDBTabsLink>
  </MDBTabsItem>
  <MDBTabsItem>
    <MDBTabsLink onClick={() => handleClickTab('stock')} active={activeTab === 'stock'}>
      <MDBIcon fas icon='warehouse' className='me-2' /> {t("Stock")}
    </MDBTabsLink>
  </MDBTabsItem>
  <MDBTabsItem>
    <MDBTabsLink onClick={() => handleClickTab('orders')} active={activeTab === 'orders'}>
      <MDBIcon fas icon='shopping-bag' className='me-2' /> {t("Orders")}
    </MDBTabsLink>
  </MDBTabsItem>
</MDBTabs>

<MDBTabsContent>

<MDBTabsPane open={activeTab === 'general'}>
{/* SKU */}
<MDBTypography tag="h5">{t("SKU")}</MDBTypography>
<MDBInput
  label={t("SKU")}
  type="text"
  value={productData.sku}
  onChange={(e) => setProductData({...productData, sku: e.target.value})}
/>
{/* SKU End */}
<MDBTypography tag="h5">{t("Shop")}</MDBTypography>
<MDBRow>
  <MDBCol size="12" lg="4">
  <MDBCheckbox
      name="visibleInShop"
      id="visibleInShop"
      label={t("Visible in shop")}
      checked={productData?.visibleInShop}
      onChange={(e) => setProductData({...productData, visibleInShop: e.target.checked})}
    />
  </MDBCol>
  <MDBCol size="12" lg="4">
      <MDBSelect
        label={t("Select Industries")}
        multiple
        data={availableIndustries?.map(industry => ({
          text: getLocalizedText(industry.name),
          value: industry.id
        }))}
        value={selectedIndustryIds || []}
        onChange={(entries) => setSelectedIndustryIds(entries.map(entry => entry.value))}
      />
  </MDBCol>
  <MDBCol size="12" lg="4">
    <MDBSelect
      label={t("Category")}
      value={productData?.productCategoryProductsId}
      onChange={(e) => setProductData({...productData, productCategoryProductsId: e?.value})}
      data={[
        {text: t("Select Category"), value: null},
        ...categories.map((c) => ({text: getLocalizedText(c.name), value: c.id}))
      ]}
    />
  </MDBCol>

</MDBRow>
<MDBRow>
  <MDBCol size="12" lg="6">
    
{/* Type */}
<MDBTypography tag="h5">{t("Type")}</MDBTypography>
{/* <MDBInput
  label={t("Type")}
  type="text"
  value={productData.type}
  onChange={(e) => setProductData({...productData, type: e.target.value})}
/> */}
<MDBSelect label={t("Type")}
  onChange={(e) => setProductData({...productData, type: e.value})}
  value={productData.type}
  data={
      [
          { text: t("All"), value: null },
          { text: t("Event booths"), value: "event_booth" },
          { text: t("Event spaces"), value: "event_space" },
          { text: t("Event booths partner"), value: "event_booth_partner" },
          { text: t("Event equipment"), value: "event_equipment" },
          { text: t("Marketing"), value: "marketing" },
          { text: t("Marketing Stand"), value: "marketing_booth" },
          { text: t("Digital Access"), value: "digital_access" },
          { text: t("Tickets"), value: "ticket" },
          { text: t("Discount"), value: "discount" },
      ]
  }
/>
{/* Type End */}
  </MDBCol>
  <MDBCol size="12" lg="6">
{/* SubType */}
<MDBTypography tag="h5">{t("Subtype")}</MDBTypography>
<MDBInput
  label={t("Subtype")}
  type="text"
  value={productData.subType}
  onChange={(e) => setProductData({...productData, subType: e.target.value})}
/>
{/* SubType End */}
  </MDBCol>
</MDBRow>
{/* Name */}
<MDBTypography tag="h5">{t("Name")}</MDBTypography>
<MDBInputGroup className="mb-1" textAfter={<span style={productData.name?.find((t) => t.language === "de")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((productData?.name?.length > 0? productData.name.find((t) => t.language === "de")?.text: "")?.length || "0") + "/60"}</span>}>
  <div className="input-group-text">
  <ReactCountryFlag
      countryCode="de"
      svg
      style={{
      width: "20px",
      height: "20px",
      }}
      title={i18n.language}
  /> 
  </div>
  <MDBInput
  label={t("German")}
  type="text"
  value={productData?.name?.find((t) => t.language === "de")?.text || ""}
  onChange={(e) => setProductData({...productData, name: [{language: "de", text: e.target.value}, ...(productData.name?.length > 0 ? productData.name.filter((t) => t.language !== "de") : [])]})}
  />
</MDBInputGroup>
<MDBInputGroup className="mb-1" textAfter={<span style={productData.name?.find((t) => t.language === "en")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((productData?.name?.length > 0? productData.name.find((t) => t.language === "en")?.text: "")?.length || "0") + "/60"}</span>}>
  <div className="input-group-text">
  <ReactCountryFlag
      countryCode="gb"
      svg
      style={{
      width: "20px",
      height: "20px",
      }}
      title={i18n.language}
  />
  </div>
  <input
  className="form-control"
  placeholder={t("English")}
  type="text"
  value={productData?.name?.find((t) => t.language === "en")?.text || ""}
  onChange={(e) => setProductData({...productData, name: [{language: "en", text: e.target.value}, ...(productData.name?.length > 0 ? productData.name.filter((t) => t.language !== "en") : [])]})}
  />
</MDBInputGroup>
{/* Name End */}
{/* Invoice Name */}
<MDBTypography tag="h5" className="mt-3">{t("Invoice Name")}</MDBTypography>
<MDBInputGroup className="mb-1" textAfter={<span style={productData.invoiceName?.find((t) => t.language === "de")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((productData?.invoiceName?.length > 0? productData.invoiceName.find((t) => t.language === "de")?.text: "")?.length || "0") + "/60"}</span>}>
  <div className="input-group-text">
  <ReactCountryFlag
      countryCode="de"
      svg
      style={{
      width: "20px",
      height: "20px",
      }}
      title={i18n.language}
  /> 
  </div>
  <MDBInput
  label={t("German")}
  type="text"
  value={productData?.invoiceName?.find((t) => t.language === "de")?.text || ""}
  onChange={(e) => setProductData({...productData, invoiceName: [{language: "de", text: e.target.value}, ...(productData.invoiceName?.length > 0 ? productData.invoiceName.filter((t) => t.language !== "de") : [])]})}
  />
</MDBInputGroup>
<MDBInputGroup className="mb-1" textAfter={<span style={productData.invoiceName?.find((t) => t.language === "en")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((productData?.invoiceName?.length > 0? productData.invoiceName.find((t) => t.language === "en")?.text: "")?.length || "0") + "/60"}</span>}>
  <div className="input-group-text">
  <ReactCountryFlag
      countryCode="gb"
      svg
      style={{
      width: "20px",
      height: "20px",
      }}
      title={i18n.language}
  />
  </div>
  <input
  className="form-control"
  placeholder={t("English")}
  type="text"
  value={productData?.invoiceName?.find((t) => t.language === "en")?.text || ""}
  onChange={(e) => setProductData({...productData, invoiceName: [{language: "en", text: e.target.value}, ...(productData.invoiceName?.length > 0 ? productData.invoiceName.filter((t) => t.language !== "en") : [])]})}
  />
</MDBInputGroup>
{/* Invoice Name End */}
{/* Topic */}
{/* <MDBTypography tag="h5" className="mt-3">{t("Topic")}</MDBTypography>
<div className="w-100 text-center">
<MDBBtn onClick={() => toggleSelectTopicsModal()} color="primary" outline>
  {t("Select Topic")}
</MDBBtn>
<MDBRow className="w-100">
    <MDBCol>
    {topics.length > 0 && topicGroups.length > 0 && productData.cMSProductTopicId && (
        <Topics
        topics={topics}
        topicGroups={topicGroups}
        selectedTopics={[productData.cMSProductTopicId] || []}
        setSelectedTopics={handleSetSelectedTopics}
        />
    )}
    </MDBCol>
</MDBRow>
</div> */}
{/* Topic End */}
{/* Description */}
<div>
<MDBTypography tag="h5" className="mt-3">{t("Description")}</MDBTypography>
<ReactCountryFlag
    countryCode="de"
    svg
    style={{
    width: "20px",
    height: "20px",
    }}
    title={i18n.language}
/> 
<Editor
    editorState={editorState["de"]}
    editorClassName="md-textarea form-control"
    onEditorStateChange={e => onEditorStateChange(e, "de")}
  />
  <br></br>
  <ReactCountryFlag
    countryCode="gb"
    svg
    style={{
    width: "20px",
    height: "20px",
    }}
    title={i18n.language}
/>
<Editor
    editorState={editorState["en"]}
    editorClassName="md-textarea form-control"
    onEditorStateChange={e => onEditorStateChange(e, "en")}
  />
</div>
{/* Description End */}
{/* Includes */}
<MDBTypography tag="h5" className="mt-3">{t("Includes")}</MDBTypography>
{[...Array(productData.includes?.length || 0)].map((item, index) => 
<MDBRow className="w-100">
  <MDBCol size="12" lg="1" className="pt-2">
    <span className="fw-bold">{(index + 1)}</span>
  </MDBCol>
  <MDBCol size="12" lg="5" className="px-1">
    <MDBInputGroup className="mb-1 mb-md-3">
      <div className="input-group-text">
        <ReactCountryFlag
          countryCode="de"
          svg
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      </div>
      <MDBTextArea
        rows={1}
        type="text"
        // label={t("Included") + " " + (index + 1) + " " +  t("Geman")}
        value={productData.includes?.[index]?.text}
        // TODO: Change Includes to have multiple languages

        datapath={"tempSubjects[" + index + "].de"}
        onChange={(e) => setProductData(oldData => ({...oldData, includes: [...oldData.includes?.slice(0, index), {text: e.target.value, language: "de"}, ...oldData.includes?.slice(index + 1)]}))}
      />
    </MDBInputGroup>
  </MDBCol>
  {/* <MDBCol size="12" lg="5" className="px-1">
    <MDBInputGroup className="mb-3">
      <div className="input-group-text">
        <ReactCountryFlag
          countryCode="gb"
          svg
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      </div>
      <MDBInput
        type="text"
        // label={t("Included") + " " + (index + 1) + " " +  t("English")}
        value={productData.includes?.[index]?.en}
        datapath={"tempSubjects[" + index + "].en"}
        onChange={(e) => setProductData(oldData => ({...oldData, includes: [...oldData.includes?.slice(0, index), {de: oldData.includes?.[index]?.de, en: e.target.value}, ...oldData.includes?.slice(index + 1)]}))}
        
      />
    </MDBInputGroup>
  </MDBCol> */}
  <MDBCol size="12" lg="1">
    <MDBBtn onClick={() => setProductData(oldData => ({...oldData, includes: oldData.includes?.filter((i, iIndex) => iIndex !== index)}))} color="danger" outline>
      {t("Remove")}
    </MDBBtn>
  </MDBCol>
</MDBRow>
)
}
<MDBBtn onClick={() => setProductData(oldData => ({...oldData, includes: [...(oldData.includes || []), {text: "", language: "de"}]}))} color="primary" outline>
  {t("Add")}
</MDBBtn>
{/* Includes End */}
{/* <MDBTypography tag="h5" className="mt-3">{t("Features")}</MDBTypography> */}
{/* <MDBTypography tag="h5" className="mt-3">{t("Files")}</MDBTypography> */}
<MDBTypography tag="h5" className="mt-3">{t("Additonal")}</MDBTypography>
<MDBRow>
  <MDBCol size="12" lg="4">
  <span className="fw-bold">{t("Requires shipping")}</span>
    <MDBCheckbox
      name="requiredShipping"
      id="requiredShipping"
      label={t("Shipping required")}
      checked={productData.requiredShipping}
      onChange={(e) => setProductData({...productData, requiredShipping: e.target.checked})}
    />
  </MDBCol>
  <MDBCol size="12" lg="4">
    <span className="fw-bold">{t("Allowed buyer groups")}</span>
    <MDBCheckbox
      name="allowConsumer"
      id="allowConsumer"
      label={t("Consumer")}
      checked={productData.allowedBuyerGroups?.includes("consumer")}
      onChange={(e) => setProductData({...productData, allowedBuyerGroups: e.target.checked ? [...(productData.allowedBuyerGroups || []), "consumer"] : productData.allowedBuyerGroups.filter(g => g !== "consumer")})}
    />
    <MDBCheckbox
      name="allowBusiness"
      id="allowBusiness"
      label={t("Business")}
      checked={productData.allowedBuyerGroups?.includes("business")}
      onChange={(e) => setProductData({...productData, allowedBuyerGroups: e.target.checked ? [...(productData.allowedBuyerGroups || []), "business"] : productData.allowedBuyerGroups.filter(g => g !== "business")})}
    />
  </MDBCol>
  <MDBCol size="12" lg="4">
    <span className="fw-bold">{t("Icon")}</span>
    <MDBInput type="text" value={productData.icon} onChange={(e) => setProductData({...productData, icon: e.target.value})} />
  </MDBCol>
</MDBRow>
</MDBTabsPane>
<MDBTabsPane open={activeTab === 'media'}>
{/* LECTURE IMAGE */}
<MDBRow className="d-flex justify-content-evenly">
  <MDBCol size="12" lg="10" xl="8" xxl="7">
  <hr className="my-4" />
    <div className="mb-2 mb-lg-4">
     
    </div>
    <div className="w-100 text-center mb-2 mb-lg-4">
    <img
      src={productData?.image?.main?.fileName ? 
      "https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/" + productData.image.main.fileName: "https://via.placeholder.com/600x400"}
      className="img-thumbnail"
      style={{ height: "200px", width: "400px", objectFit: "cover" }}
      alt='...' />
    </div>
    <MDBFile id='fileDE' onChange={e => handleSetImage(e.target.files[0])} />
   
  </MDBCol>
 
</MDBRow>
{/* LECTURE IMAGE END */}
</MDBTabsPane>
<MDBTabsPane open={activeTab === 'pricing'}>
{/* Price and downPaymentPrice */}
<MDBRow className="d-flex justify-content-evenly">
  <MDBCol size="12" lg="10" xl="8" xxl="7">
    <MDBRow>
      <MDBCol size="12" lg="6">
        <MDBInputGroup className="mb-3">
          <MDBInput
            label={t("Price")}
            type="number"
            value={productData.price}
            onChange={(e) => setProductData({...productData, price: e.target.value})}
          />
        </MDBInputGroup>
      </MDBCol>
      <MDBCol size="12" lg="6">
        <MDBInputGroup className="mb-3">
          <MDBInput
            label={t("Down Payment")}
            type="number"
            value={productData.downPaymentPrice}
            onChange={(e) => setProductData({...productData, downPaymentPrice: e.target.value})}
          />
        </MDBInputGroup>
      </MDBCol>
    </MDBRow>
  </MDBCol>
</MDBRow>
</MDBTabsPane>
<MDBTabsPane open={activeTab === 'components'}>
{/* Components */}

    <MDBRow>
      <MDBCol size="12" lg="6">
        <MDBTypography tag="h5">{t("Products")}</MDBTypography>
        <MDBInput
          type="text"
          label={t("Search by name or SKU")}
          className="mb-3"
          onChange={(e) => {
            const searchTerm = e.target.value.toLowerCase();
            const filtered = products.filter(product => 
              product.name.some(n => n.text.toLowerCase().includes(searchTerm)) ||
              product.sku.toLowerCase().includes(searchTerm)
            );
            setFilteredProducts(filtered);
          }}
        />
        <MDBListGroup>
        {(filteredProducts).map((product) => (
        <MDBListGroupItem key={product.id}>
          <MDBRow>
            <MDBCol size="12" lg="9">
              {product.name.find(n => n.language === i18n.language)?.text || product.name[0]?.text}
              <br />
              {product.sku} - {product.type} - {product.subType}
            </MDBCol>
            <MDBCol size="12" lg="3">
              {productData.componentIds?.includes(product.id) ? (
                <MDBBtn onClick={() => removeProductComponents(product.id)} color="danger" outline>
                  {t("Remove")}
                </MDBBtn>
              ) : (
                <MDBBtn onClick={() => addProductComponents(product.id)} color="primary" outline>
                  {t("Add")}
                </MDBBtn>
              )}
            </MDBCol>
          </MDBRow>
        </MDBListGroupItem>
      ))}
        </MDBListGroup>
      </MDBCol>
      <MDBCol size="12" lg="6">
        <MDBTypography tag="h5">{t("Selected Products")}</MDBTypography>
        <MDBListGroup>
          {products.filter(p => productData.componentIds?.includes(p.id))
          .sort((a, b) => productData.components.find(c => c.componentProductID === a.id).order - productData.components.find(c => c.componentProductID === b.id).order)
          .map((product) => (
            <MDBListGroupItem key={product.id}>
              <MDBRow>
                <MDBCol size="12" lg="2">
                <MDBInput
                    label={t("Quantity")}
                    type="number"
                    min={1}
                    value={productData.components.find(c => c.componentProductID === product.id)?.qty || 0}
                    onChange={(e) => updateProductComponentQTY(productData.components.find(c => c.componentProductID === product.id).id, e.target.value)}
                  />
                  </MDBCol>
                <MDBCol size="12" lg="7">
                  
                  {product.name.find(n => n.language === i18n.language)?.text || product.name[0]?.text}
                  <br />
                  {product.sku} - {product.type} - {product.subType}
                </MDBCol>
                <MDBCol size="12" lg="3">
                  <MDBBtn onClick={() => removeProductComponents(productData.components.find(c => c.componentProductID === product.id).id)} color="danger" outline>
                    {t("Remove")}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBListGroupItem>
          ))}
        </MDBListGroup>
      </MDBCol>
        
    </MDBRow>

</MDBTabsPane>
<MDBTabsPane open={activeTab === 'events'}>
  <MDBRow>
    <MDBCol size="12" lg="6">
      <MDBTypography tag="h5">{t("Available Events")}</MDBTypography>
      <MDBListGroup>
        {events.filter(event => !productData.availableInEvents?.items.some(item => item.eventId === event.id)).map((event) => (
          <MDBListGroupItem key={event.id}>
            <MDBRow>
              <MDBCol size="12" lg="9">
                {event.name.find(n => n.language === i18n.language)?.text || event.name[0]?.text}
              </MDBCol>
              <MDBCol size="12" lg="3">
                <MDBBtn onClick={() => addProductToEvent(event.id)} color="primary" outline>
                  {t("Add")}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBListGroupItem>
        ))}
      </MDBListGroup>
    </MDBCol>
    <MDBCol size="12" lg="6">
  <MDBTypography tag="h5">{t("Product Available In Events")}</MDBTypography>
  <MDBListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
    {productData.eventProducts?.items.map((eventProduct) => (
      <MDBListGroupItem key={eventProduct.id} className="d-flex justify-content-between">
        <div style={{width: "500px"}}>
          <span>{eventProduct.event.name.find(n => n.language === i18n.language)?.text || eventProduct.event.name[0]?.text}</span>
        </div>
        <div className="d-flex align-items-center">
          <MDBInput
            type="number"
            label={t("Price")}
            value={eventProduct.price}
            onChange={(e) => updateEventProduct(eventProduct.id, e.target.value, eventProduct.stock)}
          >
            <i className="fas fa-euro-sign trailing"></i>
          </MDBInput>
          <MDBInput
            type="number"
            label={t("Stock")}
            value={eventProduct.stock}
            onChange={(e) => updateEventProduct(eventProduct.id, eventProduct.price, e.target.value)}
          />
          <MDBBtn
          onClick={() => removeProductFromEvent(eventProduct.id)}
          color="danger"
          className="ms-2"
          style={{width: '250px'}}
          size="sm"
          outline
          >
                    {t("Remove")}
          </MDBBtn>
         
        </div>
      </MDBListGroupItem>
    ))}
  </MDBListGroup>
</MDBCol>
  </MDBRow>
</MDBTabsPane>
<MDBTabsPane open={activeTab === 'stock'}>
{/* Stock */}
<MDBInput
  label={t("Stock")}
  type="number"
  value={productData.stock}
  onChange={(e) => setProductData({...productData, stock: e.target.value})}
/>
</MDBTabsPane>
<MDBTabsPane open={activeTab === 'orders'}>
{/* Orders */}
</MDBTabsPane>
</MDBTabsContent>





{/* LECTURE SCRIPT */}
{/* <MDBRow className="d-flex justify-content-evenly">
  <MDBCol size="12" lg="10" xl="8" xxl="7">
  <hr className="my-4" />
    <div className="mb-2 mb-lg-4">
      <MDBTypography tag="h5">
      {t("Optional: Upload a file for the product")}
      </MDBTypography>
    </div>
    {productData?.file?.fileName && (
      <p className="text-muted">{t("File uploaded successfully")}</p>
    )
    }
    <MDBFile id='fileDE' onChange={e => handleSetFile(e.target.files[0])} />
      
    <div className="mt-3">
      <MDBCheckbox
        name="script-condition"
        id="script-condition"
        label={t("I confirm that I have the rights to use the file and that it can be made public.")}
        checked={productData?.conditions?.findIndex(c => c.name === "file") > -1 ? productData?.conditions[productData?.conditions?.findIndex(c => c.name === "file")].accepted : false}
        onChange={(e) => {setProductData(oldData => ({...oldData, conditions: [{name: "file", accepted: e.target.checked}, ...oldData.conditions?.filter(c => c.name !== "file")]}))}}
      />
    </div>
    
  </MDBCol>
  
</MDBRow> */}
{/* LECTURE SCRIPT END */}


</MDBCardBody>
</MDBCard>
</MDBCol>
</MDBRow>
{/* <MDBModal open={selectTopicsModal} onClose={() => setSelectTopicsModal(false)} staticBackdrop>
  <MDBModalDialog centered size="xl">
      <MDBModalContent >
          <MDBModalHeader>
              <MDBModalTitle>Select Topics</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleSelectTopicsModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
              <SelectTopics
                topics={topics}
                topicGroups={topicGroups}
                isActive={selectTopicsModal}
                toggleActive={toggleSelectTopicsModal}
                selectedTopics={selectedTopics}
                setSelectedTopics={handleSetSelectedTopics}
                parentTopics={topics}
                parentTopicGroups={topicGroups}
                maxSelect={1}
                />
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSelectTopicsModal}>{t("Close")}</MDBBtn>
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal> */}

</>
);
};

export default CreateProductPage;
