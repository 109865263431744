import React, { useState } from 'react';
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, 
  MDBModalBody, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBIcon, MDBSpinner } from 'mdb-react-ui-kit';
import { getLocalizedText } from "../../../utils/localizedText";
import { deleteLecture } from '../lectureConnection';

const LectureModal = ({ isOpen, onClose, lecture, i18n, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  if (!lecture) return null;

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this lecture? This action cannot be undone.')) {
      try {
        setIsDeleting(true);
        await deleteLecture(lecture.id);
        onClose();
        if (onDelete) {
          onDelete(lecture.id);
        }
      } catch (error) {
        console.error('Error deleting lecture:', error);
        alert('Failed to delete lecture. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <MDBModal open={isOpen} onClose={onClose} tabIndex='-1'>
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{getLocalizedText(lecture.title)}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={onClose}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol md="3" className="text-center mb-3">
                {lecture.company?.image?.main?.fileName ? (
                  <img
                    src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/${lecture.company.image.main.fileName}`}
                    className="rounded"
                    style={{ maxWidth: '150px', width: '100%', objectFit: "contain" }}
                    alt={lecture.company.image.main.alt}
                  />
                ) : (
                  <MDBIcon fas icon="building" size="4x" className="text-muted" />
                )}
                <h6 className="mt-2">{lecture.company?.name}</h6>
              </MDBCol>
              <MDBCol md="9">
                <div className="mb-3">
                  <h6 className="fw-bold">Description:</h6>
                  <p>{getLocalizedText(lecture.content)}</p>
                </div>
                
                <div className="mb-3">
                  <h6 className="fw-bold">Type:</h6>
                  <p className="text-capitalize">{lecture.type?.replace('_', ' ')}</p>
                </div>

                {lecture.startTime && (
                  <div className="mb-3">
                    <h6 className="fw-bold">Schedule:</h6>
                    <p>
                      Room {lecture.room} - {new Date(lecture.startTime).toLocaleTimeString()} to{' '}
                      {new Date(lecture.endTime).toLocaleTimeString()}
                    </p>
                  </div>
                )}

                {lecture.presentationLanguage && (
                  <div className="mb-3">
                    <h6 className="fw-bold">Presentation Language:</h6>
                    <p className="text-uppercase">{lecture.presentationLanguage}</p>
                  </div>
                )}

                {lecture.topics?.length > 0 && (
                  <div className="mb-3">
                    <h6 className="fw-bold">Topics:</h6>
                    <div>
                      {lecture.topics.map((topic, index) => (
                        <span key={index} className="badge bg-primary me-2">
                          {getLocalizedText(topic.name)}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn 
              color='danger' 
              onClick={handleDelete}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <><MDBSpinner size='sm' /> Deleting...</>
              ) : (
                <>
                  <MDBIcon fas icon="trash-alt" className="me-2" />
                  Delete Lecture
                </>
              )}
            </MDBBtn>
            <MDBBtn color='secondary' onClick={onClose}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default LectureModal;