import React, { useContext, useEffect } from "react";
import {
  Route,
  Routes,
  BrowserRouter,
  useNavigate,
  useLocation,
} from "react-router-dom";


import ScrollToTop from "./utils/scrollTop";
import RedirectDashboardOnboarding from "./utils/redirectDashboardOnboarding";  

import Navigation from "./Navigation";
import NavigationAdmin from "./NavigationAdmin";

// Onboarding
import Onboarding from "./components-user/registration/AccountSetup";

import AccountSetup from "./components-user/registration/AccountSetup";
import UserList from "./components-dev-admin/UserList";

// Admin Pages
import AdminEditUser from "./pages-dev-admin/users/User";
import AdminPlatformUsers from "./pages-dev-admin/PlatformUsers";
import AdminCompanies from "./pages-dev-admin/Companies";
import AdminEditCompany from "./components-dev-admin/manage-company/EditCompany";
import AdminCreateCompany from "./pages-dev-admin/TEMP/CreateCompany";
import AdminCreateUser from "./pages-dev-admin/TEMP/CreateUser";
import AdminAllTickets from "./pages-dev-admin/event-management/AllTickets";
import AdminAllNews from "./pages-dev-admin/news/AllNews";
import AdminEditNews from "./pages-dev-admin/news/EditNews";
import PrintTicket from "./pages-dev-admin/event-management/PrintTicket";
import AdminAllProducts from "./pages-dev-admin/products/AllProducts";
import AllVideoProgress from "./academy/admin/AllVideoProgress";
import AdminCreateProduct from "./pages-dev-admin/products/EditProduct";
import AdminAllEvents from "./pages-dev-admin/event-management/AllEvents";
import AdminEditEvent from "./pages-dev-admin/event-management/edit-event/index";
import AdminBookedBooths from "./pages-dev-admin/event-management/BookedBooths";
import AdminIndustries from "./pages-dev-admin/industries/AdminIndustries";
import AdminEditIndustries from "./pages-dev-admin/industries/EditIndustry";
import AdminEventCarpets from "./pages-dev-admin/event-management/EventCarpets";
import AdminInvitationCodes from "./pages-dev-admin/event-management/InvitationCodes";

import AdminCompanyProducts from "./pages-dev-admin/company-data/products/AllProducts";
import AdminCompanyJobs from "./pages-dev-admin/company-data/jobs/AllJobs";
import AdminCompanyEditJob from "./pages-dev-admin/company-data/jobs/EditJob";

import AdminAcademyAllSeminars from "./academy/admin/AllSeminars";
import AdminAcademyEditSeminar from "./academy/admin/EditSeminar";
// User Pages
import DashboardUserAdmin from "./pages-user/Dashboard-User-Admin";
import Companies from "./pages-user/Companies";
import Company from "./pages-user/CompanyDetail";
import RequestAffiliation from "./pages-user/manage-company/RequestAffiliation";
import MyCompanyRequests from "./pages-user/manage-company/Requests";
import MyCompanyEmployees from "./pages-user/manage-company/Employees";
import MyCompanyDepartments from "./pages-user/manage-company/Departments";
import MyProfile from "./components-user/EditProfile";
import MyCompanyProfile from "./pages-user/manage-company/Profile";
import MyEmployeeProfile from "./pages-user/manage-company/MyEmployeeProfile";
import MyCompanyBillingSettings from "./pages-user/manage-company/BillingSettings";
import Lectures from "./pages-user/event-management/lectures/AllLectures";
import RegisterLecture from "./pages-user/event-management/lectures/RegisterLecture";
import RegisterTechnicalLecture from "./pages-user/event-management/lectures/RegisterTechnicalLecture";
import RegisterEducationalLecture from "./pages-user/event-management/lectures/RegisterEducationalLecture";
import RegisterMarketingLecture from "./pages-user/event-management/lectures/RegisterMarketingLecture";
import RegisterLectureLandingPage from "./components-user/event-management/lectures/registration/landing-page/RegisterLectureLandingPage";
import AllLectures from "./pages-dev-admin/event-management/AllLectures";
import EventGuests from "./pages-user/event-management/EventGuests";
import Chat from "./pages-user/chat/Chat";
import CompanyBookings from "./pages-user/manage-company/CompanyBookings";
import EditBooth from "./pages-user/event-management/booth/EditBooth";
import AdminBookedEquipment from "./pages-dev-admin/event-management/BookedEquipment";

import AllRecruitingLectures from "./pages-user/recruiting/AllRecruitingLectures";
import RegisterRecruitingLecture from "./pages-user/recruiting/CreateRecruitingLecture";

// User Tickets
import BookTicket from "./pages-user/event-management/tickets/BookTicket";
import AllTickets from "./pages-user/event-management/tickets/AllBookings";
import AdminAllOrders from "./pages-dev-admin/orders/AllOrders";
import AdminCreateOrder from "./pages-dev-admin/orders/CreateOrder";
import Actions from "./pages-user/event-management/actions/AllLectures";
import RegisterAction from "./pages-user/event-management/actions/RegisterLecture";
import AllActions from "./pages-dev-admin/event-management/AllActions";
import EditLectureAdmin from "./pages-dev-admin/lectures/EditLecture";
import AdminLectureScheduling from "./pages-dev-admin/lectures/Scheduling";


// News
import AllNews from "./pages-user/news/AllNews";
import CreateNews from "./pages-user/news/CreateNews";

import CreateJobListing from "./pages-user/recruiting/CreateJobListing";
// Products
import AllProducts from "./pages-user/products/AllProducts";
import CreateProduct from "./pages-user/products/CreateProduct";

import TEMPCompaniesAttendingEvent from "./pages-dev-admin/TEMP/CompaniesAttendingEvent";
import TEMPPromotionTimes from "./pages-dev-admin/TEMP/PromotionsTimes";
//Shop

import Shop from "./pages-user/shop/Shop";
// Checkout 
import Checkout from "./pages-user/order/checkout/Checkout";
import OrderThankYou from "./pages-user/order/checkout/ThankYou";


// Insight
import LectureVideo from "./academy/basics/LectureVideo";
import AllLecturesList from "./academy/basics/AllLecturesList";
import AllCourses from "./academy/admin/AllCourses";
import EditCourse from "./academy/admin/EditCourse";
import UserCourseProgress from "./academy/admin/UserCourseProgress";

// Adacemy
import AllBasicCourses from "./academy/basics/AllBasicCourses";
import CourseDetail from "./academy/basics/CourseDetail";

// Temp
import SelectBooth from "./pages-user/event-management/booking/SelectBooth";
import AllEventLectures from "./pages-user/AllEventLectures";
const fourtOFour = () => <h1 className="text-center">404</h1>;

function AppRoutes(props) {
  

  return (
    <BrowserRouter>
      {!props.isInitialized ? (
        <h1>Loading...</h1>
      ) : (
        <>
          {props.isAdmin && !props.adminAsUser ? (
            <NavigationAdmin
              signOut={props.signOut}
              isAdmin={props.isAdmin}
              adminAsUser={props.adminAsUser}
              setAdminAsUser={props.setAdminAsUser}
              setDesktopFullscreen={props.setDesktopFullscreen}
            />
          ) : (
            <Navigation
              signOut={props.signOut}
              isAdmin={props.isAdmin}
              setAdminAsUser={props.setAdminAsUser}
            />
          )}
          {/* <OnboardingChecker /> */}
          <ScrollToTop />
          {/* <RedirectDashboardOnboarding user={props.user} /> */}
          <Routes>
            {/* Allgemeine Routen */}
            <Route exact path="/" element={<DashboardUserAdmin />} />
            <Route exact path="/accountsetup" element={<AccountSetup />} />
            <Route exact path="/users" element={<UserList />} />
            <Route exact path="/companies-attending-event" element={<TEMPCompaniesAttendingEvent />} />
            <Route exact path="/companies" element={<Companies />} />
            <Route exact path="/company/:companyid" element={<Company />} />
            <Route exact path="/event-lectures" element={<AllEventLectures />} />
            <Route exact path="/chat" element={<Chat />} />
            <Route exact path="/shop" element={<Shop />} />

            {/* Admin-spezifische Routen */}
            <Route path="/admin" element={null}>
              <Route index element={null} />
              <Route path="users" element={<AdminPlatformUsers />} />
              <Route path="user/:userid" element={<AdminEditUser />} />
              <Route path="create-user" element={<AdminCreateUser />} />
              <Route path="companies" element={<AdminCompanies />} />
              <Route path="create-company" element={<AdminCreateCompany />} />
              <Route path="company/:companyid" element={<AdminEditCompany />} />
              <Route path="lectures" element={<AllLectures />} />
              <Route path="lecture_scheduling" element={<AdminLectureScheduling />} />
              <Route path="lecture/:companyid/:lectureid" element={<EditLectureAdmin />} />
              <Route path="tickets" element={<AdminAllTickets />} />
              <Route path="orders" element={<AdminAllOrders />} />
              <Route path="order/create" element={<AdminCreateOrder />} />
              <Route path="products" element={<AdminAllProducts />} />
              <Route path="product/:productId" element={<AdminCreateProduct />} />
              <Route path="product/create" element={<AdminCreateProduct />} />
              <Route path="printticket" element={<PrintTicket />} />
              <Route path="news" element={<AdminAllNews />} />
              <Route path="news/:companyId/:newsId" element={<AdminEditNews />} />
              <Route path="news/create" element={<AdminEditNews />} />
              <Route path="company-products" element={<AdminCompanyProducts />} />
              <Route path="company-jobs" element={<AdminCompanyJobs />} />
              <Route path="company-job/:jobId" element={<AdminCompanyEditJob />} />
              <Route path="promotiontimes" element={<TEMPPromotionTimes />} />
              <Route path="video-progress" element={<AllVideoProgress />} />
              <Route path="events" element={<AdminAllEvents />} />
              <Route path="event/:eventId" element={<AdminEditEvent />} />
              <Route path="event/create" element={<AdminEditEvent />} />
              <Route path="event/invitation-codes" element={<AdminInvitationCodes />} />
              <Route path="event-carpets" element={<AdminEventCarpets />} />
              <Route path="booked-booths" element={<AdminBookedBooths />} />
              <Route path="booked-equipment" element={<AdminBookedEquipment />} />
                {/* Insight Admin */}
              <Route path="insight/courses" element={<AllCourses />} />
              <Route path="insight/course/create" element={<EditCourse />} />
              <Route path="insight/course/:courseId" element={<EditCourse />} />
              <Route path="insight/user-course-progress/:userId" element={<UserCourseProgress />} />
              {/* Academy Admin */}
              <Route path="academy/seminars" element={<AdminAcademyAllSeminars />} />
              <Route path="academy/seminar/:seminarId" element={<AdminAcademyEditSeminar />} />
              <Route path="academy/seminar/create" element={<AdminAcademyEditSeminar />} />
              {/* Industries */}
              <Route path="industries" element={<AdminIndustries />} />
              <Route path="industry/:industryId" element={<AdminEditIndustries />} />
              <Route path="industry/create" element={<AdminEditIndustries />} />
            </Route>

            {/* Benutzer-spezifische Routen */}
            <Route path="/mycompany/:companyid" element={null}>
              <Route index element={<MyCompanyProfile />} />
              <Route path="requests" element={<MyCompanyRequests />} />
              <Route path="profile" element={<MyCompanyProfile />} />
              <Route path="employees" element={<MyCompanyEmployees />} />
              <Route path="departments" element={<MyCompanyDepartments />} />
              <Route path="billing" element={<MyCompanyBillingSettings />} />
              <Route path="myemployeeprofile/:employeeid" element={<MyEmployeeProfile />} />
              <Route path="register-lecture-landing-page" element={<RegisterLectureLandingPage />} />
              <Route path="lectures" element={<Lectures />} />
              <Route path="register-lecture" element={<RegisterLecture />} />
              <Route path="register-lecture/:lectureid" element={<RegisterLecture />} />
              <Route path="register-lecture/technical" element={<RegisterTechnicalLecture />} />
              <Route path="register-lecture/educational" element={<RegisterEducationalLecture />} />
              <Route path="register-lecture/marketing" element={<RegisterMarketingLecture />} />
              <Route path="actions" element={<Actions />} />
              <Route path="register-action" element={<RegisterAction />} />
              <Route path="register-action/:actionid" element={<RegisterAction />} />	
              <Route path="recruiting-lectures" element={<AllRecruitingLectures />} />
              <Route path="register-recruiting-lecture" element={<RegisterRecruitingLecture />} />
              <Route path="register-recruiting-lecture/:lectureid" element={<RegisterRecruitingLecture />} />
              <Route path="news" element={<AllNews />} />
              <Route path="news/create" element={<CreateNews />} />
              <Route path="news/:newsid" element={<CreateNews />} />
              <Route path="job-listing/create" element={<CreateJobListing />} />
              <Route path="job-listing/:newsid" element={<CreateJobListing />} />
              <Route path="products" element={<AllProducts />} />
              <Route path="product/create" element={<CreateProduct />} />
              <Route path="product/:productid" element={<CreateProduct />} />
              <Route path="select-booth/:eventId" element={<SelectBooth />} />
              <Route path="event-guests" element={<EventGuests />} />
              <Route path="company-bookings" element={<CompanyBookings />} />
              <Route path="edit-booth/:boothId" element={<EditBooth />} />
              {/* Weitere Benutzer-Routen hier einfügen */}
            </Route>

            {/* Insight */}
            <Route path="/insight/lecture-video/:lectureId" element={<LectureVideo />} />
            <Route path="/insight/lectureVideos" element={<AllLecturesList />} />
            
            {/* Academy */}
            <Route path="/academy/basics" element={<AllBasicCourses />} />
            <Route path="/academy/basics/course/:courseId" element={<CourseDetail />} />
            <Route path="/academy/basics/lecture/:lectureId" element={<LectureVideo />} />

          

            {/* Weitere allgemeine Routen */}
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/company/:companyid/request-affiliation" element={<RequestAffiliation />} />

            {/* Tickets */}
            <Route path="/book-ticket" element={<BookTicket />} />
            <Route path="/bookings" element={<AllTickets />} />

            {/* Checkout */}
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/order/confirmation/:orderId" element={<OrderThankYou />} />

            {/* Fallback für nicht gefundene Seiten */}
            <Route path="*" element={<fourtOFour />} />
          </Routes>

        </>
      )}
    </BrowserRouter>
  );
}
export default AppRoutes;
