import React, { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBSelect, MDBSpinner, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { getLocalizedText } from '../../utils/localizedText';

const client = generateClient();

const EventEquipmentSummary = () => {
  const handleDownloadExcel = () => {
    // Daten für Excel vorbereiten
    const excelData = [];
    
    // Header hinzufügen
    excelData.push([
      "Ausstattung",
      "Im Stand enthalten",
      "Zusätzlich gebucht",
      "Gesamt gebucht",
      "Verfügbarer Bestand",
      "Verbleibender Bestand"
    ]);
    
    // Daten hinzufügen
    equipmentSummary.forEach(item => {
      excelData.push([
        getLocalizedText(item.product.name),
        item.includedQuantity,
        item.additionalQuantity,
        item.totalBooked,
        item.stock,
        item.stock - item.totalBooked
      ]);
    });

    // Arbeitsmappe erstellen
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(excelData);

    // Spaltenbreiten automatisch anpassen
    ws['!cols'] = [
      { wch: 40 }, // Ausstattung
      { wch: 20 }, // Im Stand enthalten
      { wch: 20 }, // Zusätzlich gebucht
      { wch: 15 }, // Gesamt gebucht
      { wch: 20 }, // Verfügbarer Bestand
      { wch: 20 }  // Verbleibender Bestand
    ];

    // Worksheet zur Arbeitsmappe hinzufügen
    XLSX.utils.book_append_sheet(wb, ws, 'Ausstattung');

    // Excel-Datei herunterladen
    XLSX.writeFile(wb, `Ausstattung-${getLocalizedText(events.find(e => e.id === selectedEvent)?.name)}.xlsx`);
  };
  const { t, i18n } = useTranslation();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [equipmentSummary, setEquipmentSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await client.graphql({
          query: `
            query ListEvents {
              listEvents(filter: { type: { eq: "fair" } }) {
                items {
                  id
                  name {
                    text
                    language
                  }
                  startDate
                  endDate
                  status
                  type
                }
              }
            }
          `
        });

        const activeEvents = response.data.listEvents.items.filter(
          event => event.status === "active" && new Date(event.endDate) >= new Date()
        );
        setEvents(activeEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  // When an event is selected, fetch booths and equipment data
  useEffect(() => {
    if (!selectedEvent) return;

    const fetchEquipmentData = async () => {
      setIsLoading(true);
      try {
        // First, get all booked booths for the event with additional order information
        const bookedBoothsResponse = await client.graphql({
          query: `
            query SearchBookedBooths($filter: SearchableBookedBoothFilterInput) {
              searchBookedBooths(filter: $filter) {
                items {
                  id
                  orderLineItem {
                    order {
                      id
                    }
                  }
                  equipment {
                    items {
                      orderLineItem {
                        order {
                          id
                          status
                        }
                        product {
                          id
                          name {
                            language
                            text
                          }
                          type
                          subType
                        }
                        qty
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            filter: {
              eventBookedBoothsId: { eq: selectedEvent }
            }
          }
        });

        // Initialize separate summaries for included and additional equipment
        const productSummary = {};

        bookedBoothsResponse.data.searchBookedBooths.items.forEach(booth => {
          const mainOrderId = booth.orderLineItem.order.id;

          booth.equipment.items.forEach(equipment => {
            if (equipment.orderLineItem?.product) {
              const productId = equipment.orderLineItem.product.id;
              if (!productSummary[productId]) {
                productSummary[productId] = {
                  product: equipment.orderLineItem.product,
                  includedQuantity: 0,
                  additionalQuantity: 0,
                  totalBooked: 0
                };
              }

              // Skip canceled orders
              if (equipment.orderLineItem.order.status === "canceled") {
                return;
              }

              const quantity = equipment.orderLineItem.qty || 0;
              
              // Check if equipment is from the main order or additional
              if (equipment.orderLineItem.order.id === mainOrderId) {
                productSummary[productId].includedQuantity += quantity;
              } else {
                productSummary[productId].additionalQuantity += quantity;
              }
              
              productSummary[productId].totalBooked += quantity;
            }
          });
        });

        // Get event product data for these products
        const eventProductsResponse = await client.graphql({
          query: `
            query SearchEventProducts($filter: ModelEventProductFilterInput) {
              listEventProducts(filter: $filter) {
                items {
                  product {
                    id
                    name {
                      language
                      text
                    }
                  }
                  stock
                }
              }
            }
          `,
          variables: {
            filter: {
              eventEventProductsId: { eq: selectedEvent }
            }
          }
        });

        // Merge event product data with booked quantities
        const eventProducts = eventProductsResponse.data.listEventProducts.items;
        const summaryData = Object.values(productSummary).map(item => {
          const eventProduct = eventProducts.find(ep => ep.product.id === item.product.id);
          return {
            ...item,
            stock: eventProduct?.stock || 0
          };
        });

        // Sort by product name
        summaryData.sort((a, b) => {
          const nameA = getLocalizedText(a.product.name);
          const nameB = getLocalizedText(b.product.name);
          return nameA.localeCompare(nameB);
        });

        setEquipmentSummary(summaryData);
      } catch (error) {
        console.error('Error fetching equipment data:', error);
      }
      setIsLoading(false);
    };

    fetchEquipmentData();
  }, [selectedEvent]);

  return (
    <MDBRow>
      <MDBCol>
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>
              {t("Event Equipment Summary")}
              <MDBBtn
                className="ms-3"
                size="sm"
                outline
                color="primary" 
                onClick={handleDownloadExcel}
                disabled={equipmentSummary.length === 0}
              >
                <MDBIcon fas icon="file-excel" className="me-2" />
                {t("Download")}
              </MDBBtn>
            </MDBCardTitle>
            
            <MDBRow className="mb-4">
              <MDBCol md="4">
                <MDBSelect
                  label={t("Select Event")}
                  data={[
                    { text: t("Select an event..."), value: "" },
                    ...events.map(event => ({
                      text: getLocalizedText(event.name),
                      value: event.id
                    }))
                  ]}
                  value={selectedEvent || ""}
                  onChange={e => setSelectedEvent(e.value)}
                />
              </MDBCol>
            </MDBRow>

            {isLoading ? (
              <div className="text-center">
                <MDBSpinner />
              </div>
            ) : selectedEvent ? (
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>Ausstattung</th>
                    <th>Im Stand enthalten</th>
                    <th>Zusätzlich gebucht</th>
                    <th>Gesamt gebucht</th>
                    <th>Verfügbarer Bestand</th>
                    <th>Verbleibender Bestand</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {equipmentSummary.map((item, index) => (
                    <tr key={index}>
                      <td>{getLocalizedText(item.product.name)}</td>
                      <td>{item.includedQuantity}</td>
                      <td>{item.additionalQuantity}</td>
                      <td>{item.totalBooked}</td>
                      <td>{item.stock}</td>
                      <td className={item.stock - item.totalBooked < 0 ? 'text-danger' : ''}>
                        {item.stock - item.totalBooked}
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            ) : (
              <div className="text-center text-muted">
                {t("Please select an event to view equipment summary")}
              </div>
            )}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
};

export default EventEquipmentSummary;