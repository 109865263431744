import React, { useState, useEffect, useCallback } from "react";
import RegistrationStepper from "../../../components-user/event-management/actions/registration/registration-steps/RegistrationStepper";

import { useTranslation } from "react-i18next";
import { diff } from "deep-object-diff";
import { generateClient } from 'aws-amplify/api';
import { getLecture } from "../../../graphql/queries";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import deepUpdateObject from "../../../utils/deepUpdateObject";


const extractExistingParams = (searchParams) => {
  return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
      if (value === 'true') {
          acc[key] = true;
      } else if (value === 'false') {
          acc[key] = false;
      } else {
          acc[key] = value;
      }
      return acc;
  }, {});
}
export default function RegisterLecture() {
  const { t, i18n } = useTranslation();
  const client = generateClient();
  const navigate = useNavigate();

  const [event, setEvent] = useState({});

  const [searchParams] = useSearchParams();

  const companyid = window.location.pathname.includes("mycompany")
    ? window.location.pathname.split("/")[2]
    : window.location.pathname.split("/")[3];
  const lectureid = window.location.pathname.includes("mycompany")
    ? window.location.pathname.split("/")[4]
    : window.location.pathname.split("/")[5];

  // Loading
  const [loading, setLoading] = useState(false);

  // Lecture data
  //const [initialLectureData, setInitialLectureData] = useState({});
  const [lectureData, setLectureData] = useState({});
  const [lectureDataChanges, setLectureDataChanges] = useState({});

  const getLectureData = async () => {
    try {
      const lecture = await client.graphql({
        variables: { id: lectureid },
        query: /* GraphQL */ `
        query GetLecture($id: ID!) {
          getLecture(id: $id) {
            id
            type
            startTime
            endTime
            event {
              id
              description {
                language
                text
              }
              status
              date
              lectureDocumentsEndDate
              x4Id
              
              
            }
            company {
              id
              name
              industry
              status
              website
              admins
              x4Id
              x4AdminId
              companyAdminId
              
            }
            speakers {
              items {
                employeeId
              }
            }
            session
            mainSpeaker {
              id
              role
              permissions {
                resource
                actions
              }
              status
              x4Id
              WPJetEngineId
              permissions {
                resource
                actions
              }
              companyEmployeesId
              userEmployeeProfilesId
              
            }
            room
            title {
              language
              text
              
            }
            subjects {
              id
              name {
                language
                text
              }
              
            }
            topics {
              items {
                topicId
              }
            }
            content {
              language
              text
              
            }
            conditions {
              name
              accepted
              
            }
            presentationLanguage
            image {
              fileName
              alt
              x4UUID
              
            }
            pdfDe {
              url
              name
              
            }
            pdfEn {
              url
              name
              
            }
            createdAt
            updatedAt
            companyLecturesId
            eventLecturesId
            lectureMainSpeakerId
            
          }
        }
      `,
      });
      console.log(lecture);
     
      return {
        ...lecture.data.getLecture,
        speakers: lecture.data.getLecture.speakers?.items?.map(speaker => speaker.employeeId) || [],
        topics: lecture.data.getLecture.topics?.items?.map(topic => topic.topicId) || [],
        tempSubjects: lecture.data.getLecture.subjects?.map(subject => ({de: subject.name.find(name => name.language === "de").text, en: subject.name.find(name => name.language === "en").text})) || [],
      };
    } catch (error) {
      console.warn("Error getting lecture data", error);
    }
  };

  const getEvent = async (eventId) => {
    try {
        const event = await client.graphql({
            query: /* GraphQL */ `
            query GetEvent($id: ID!) {
              getEvent(id: $id) {
                id
                name {
                  language
                  text
                }
                bookingNotice {
                    language
                    text
                }
                startDate
                endDate
                lectureRegistrationStartDate
                lectureRegistrationEndDate
                lectureDocumentsEndDate
                lectureRecordingCorrectionEndDate
                availableLectureTypes
                lecturesNotice {
                    language
                    text
                }
                image {
                    fileName
                    alt
                }
              }
            }
          `,
            variables: {
                id: eventId
            }
        })
        console.log("event", event)
        setEvent(event.data.getEvent)
        return event.data.getEvent
    } catch (error) {
        console.warn("error fetching event", error)
    }
}

  const intialLoadLecture = async () => {
    console.log("loading lecture");
    setLoading(true);
    const dbLecture = await getLectureData();
    //setInitialLectureData(dbLecture);
    setLectureData(dbLecture);
    setLoading(false);
  };

  useEffect(() => {
    const existingParams = extractExistingParams(searchParams);
    if(existingParams.eventId) {
      getEvent(existingParams.eventId);
    }
    if (!companyid) {
      console.warn("No company id provided");
      return;
    } else if (!lectureid) {
      console.log("No lecture id provided");
      return;
    } else {
      intialLoadLecture();
    }
  }, []);

  const handleChange = (e, additionalInstructions) => {
    console.log("change", e.target.attributes.datapath.value, e.target.checked, e.target.value);
    console.log("additionalInstructions", additionalInstructions);
    console.log(e)
    
    // Get values from input
    const datapath = e.target.attributes.datapath.value;
    let value;

    // Check if the input is a checkbox
    if (e.target.type === 'checkbox') {
        value = e.target.checked; // Use 'checked' for checkboxes
    } else {
        value = e.target.value; // Use 'value' for other input types
    }

    // Update current company object
    const newLecture = deepUpdateObject(lectureData, datapath, value, additionalInstructions);
    console.log("new lecture", JSON.stringify(newLecture));
    setLectureData(newLecture);
    
    //setLectureDataChanges(diff(initialLectureData, newLecture));
};

const [isSaving, setIsSaving] = useState(false);

 const createLectureHandler = async () => {
    try {
      setIsSaving(true);
      let lectureDataForLecture = {
        conditions: lectureData.conditions,
        content: lectureData.content,
        subjects: lectureData.tempSubjects?.map(subjectTexts => ({
          id: uuidv4(),
          name: [
            {
              language: "de",
              text: subjectTexts.de
            },
            {
              language: "en",
              text: subjectTexts.en
            },
          ]
        })).filter(Boolean) || lectureData.subjects || null,
        title: lectureData.title,
        type: lectureData.type,
        companyLecturesId: companyid,
        ...(lectureid ? {} : {status: "created"}),
        ...(event.id ? {eventLecturesId: event.id} : {})
      };
      if(lectureData.lectureMainSpeakerId) lectureDataForLecture.lectureMainSpeakerId = lectureData.lectureMainSpeakerId;
      if(lectureData.image?.fileName) lectureDataForLecture.image = lectureData.image;
      console.log("lectureDataForLecture", lectureDataForLecture)
      const lecture = await client.graphql({
        variables: { input: lectureid ? {...lectureDataForLecture, id: lectureid} :lectureDataForLecture },
        query: lectureid ?
        /* GraphQL */ `
        mutation UpdateLecture(
          $input: UpdateLectureInput!
          $condition: ModelLectureConditionInput
        ) {
            updateLecture(input: $input, condition: $condition) {
              id
            }
          }
        `
        :
        /* GraphQL */ `
        mutation CreateLecture(
          $input: CreateLectureInput!
          $condition: ModelLectureConditionInput
        ) {
          createLecture(input: $input, condition: $condition) {
            id
          }
        }
      `,
      });
      //console.log(lecture);

      // Speakers

      let dataForSpeaker = [];
        if(lectureData.speakers?.length > 0  && !lectureid) {
          lectureData.speakers.forEach(speaker => {
            dataForSpeaker.push({
              id: speaker,
              speakerLectureId: lecture.data.createLecture.id
            })
          })
          console.log("dataForSpeaker", dataForSpeaker)
          await Promise.all(dataForSpeaker.map(async speaker => {
            console.log("speaker", speaker)
            const speakerData = await client.graphql({
              variables: { input: {
                lectureId: speaker.speakerLectureId,
                employeeId: speaker.id
              } },
              query: /* GraphQL */ `
              mutation CreateLecturesSpeakers(
                $input: CreateLecturesSpeakersInput!
                $condition: ModelLecturesSpeakersConditionInput
              ) {
                createLecturesSpeakers(input: $input, condition: $condition) {
                  id
                  lectureId
                  employeeId
                }
              }
            `,
            });
            console.log("speakerData", speakerData);
          }
        ))} else if(lectureid) {
          console.log("updating speakers")
          async function updateLectureSpeakers(lectureData, lectureid, client) {
            const existingSpeakers = await fetchExistingSpeakers(lectureid, client);
            const newSpeakerIds = lectureData.speakers || [];
            const existingSpeakerIds = existingSpeakers.map(s => s.employeeId);
        
            const speakersToAdd = newSpeakerIds.filter(id => !existingSpeakerIds.includes(id));
            const speakersToRemove = existingSpeakers.filter(s => !newSpeakerIds.includes(s.employeeId));
        
            // Füge neue Speaker hinzu
            await Promise.all(speakersToAdd.map(async id => {
              await addSpeaker({ id, speakerLectureId: lectureid }, client);
            }));
        
            // Entferne überflüssige Speaker
            await Promise.all(speakersToRemove.map(async speaker => {
              await removeSpeaker(speaker.id, client);
            }));

          }
          
          async function fetchExistingSpeakers(lectureId, client) {
            const query = /* GraphQL */ `
            query ListLecturesSpeakers(
              $filter: ModelLecturesSpeakersFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listLecturesSpeakers(
                filter: $filter
                limit: $limit
                nextToken: $nextToken
              ) {
                items {
                    id
                    employeeId
                  }
                }
              }
            `;
          
            const response = await client.graphql({
              query,
              variables: { filter: { lectureId: { eq: lectureId } }, limit: 10000 }
            });
          
            return response.data.listLecturesSpeakers.items || [];
          }
          
          
          
          function compareSpeakers(newSpeakers, existingSpeakers) {
            const speakersToAdd = newSpeakers.filter(speaker => !existingSpeakers.includes(speaker));
            const speakersToRemove = existingSpeakers.filter(speaker => !newSpeakers.includes(speaker));
            return { speakersToAdd, speakersToRemove };
          }
          
          async function addSpeaker(speaker, client) {
            // GraphQL-Mutation, um einen Speaker hinzuzufügen
            const mutation = /* GraphQL */ `
            mutation CreateLecturesSpeakers(
              $input: CreateLecturesSpeakersInput!
              $condition: ModelLecturesSpeakersConditionInput
            ) {
              createLecturesSpeakers(input: $input, condition: $condition) {
                  id
                }
              }
            `;
            await client.graphql({
              query: mutation,
              variables: { input: { lectureId: speaker.speakerLectureId, employeeId: speaker.id } }
            });
          }
          
          async function removeSpeaker(lectureSpeakerId, client) {
            const mutation = /* GraphQL */ `
            mutation DeleteLecturesSpeakers(
              $input: DeleteLecturesSpeakersInput!
              $condition: ModelLecturesSpeakersConditionInput
            ) {
              deleteLecturesSpeakers(input: $input, condition: $condition) {
                  id
                }
              }
            `;
          
            await client.graphql({
              query: mutation,
              variables: { input: { id: lectureSpeakerId } }
            });
          }
          

          await updateLectureSpeakers(lectureData, lectureid, client);
        }

     
          if(lectureData.topics?.length > 0  && !lectureid) {
        console.log("creating topics", lectureData.topics)
        let dataForTopics = [];
        lectureData.topics.forEach(topicId => {
          dataForTopics.push({
            topicId: topicId,
            lectureId: lecture.data.createLecture.id
          })
        })
        console.log("dataForTopics", dataForTopics)
        await Promise.all(dataForTopics.map(async topic => {
          console.log("topic", topic)
          const topicData = await client.graphql({
            variables: { input: {
              lectureId: topic.lectureId,
              topicId: topic.topicId
            } },
            query: /* GraphQL */ `
            mutation CreateLecturesTopics(
              $input: CreateLecturesTopicsInput!
              $condition: ModelLecturesTopicsConditionInput
            ) {
              createLecturesTopics(input: $input, condition: $condition) {
                id
                lectureId
                topicId
              }
            }
          `,
          });
          console.log("topicData", topicData);
        }))
      } else if(lectureid) {
        async function updateLectureTopics(lectureData, lectureid, client) {

            // Aktualisieren von Topics für eine bestehende Lecture
            console.log("updating topics");
            const existingTopics = await fetchExistingTopics(lectureid, client);
            const newTopicIds = lectureData.topics || [];
            console.log("newTopicIds", newTopicIds)
            const existingTopicIds = existingTopics.map(t => t.topicId);

            const topicsToAdd = newTopicIds.filter(id => !existingTopicIds.includes(id));
            const topicsToRemove = existingTopics.filter(t => !newTopicIds.includes(t.topicId));

            // Füge neue Topics hinzu
            await Promise.all(topicsToAdd.map(async id => {
              await addTopic({ topicId: id, lectureId: lectureid }, client);
            }));

            // Entferne überflüssige Topics
            await Promise.all(topicsToRemove.map(async topic => {
              await removeTopic(topic.id, client);
            }));
          
        }

        async function fetchExistingTopics(lectureId, client) {
          const query = /* GraphQL */ `
            query ListLecturesTopics(
              $filter: ModelLecturesTopicsFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listLecturesTopics(
                filter: $filter
                limit: $limit
                nextToken: $nextToken
              ) {
                items {
                  id
                  topicId
                }
              }
            }
          `;

          const response = await client.graphql({
            query,
            variables: { filter: { lectureId: { eq: lectureId } }, limit: 10000 }
          });

          return response.data.listLecturesTopics.items || [];
        }

        async function addTopic(topic, client) {
          const mutation = /* GraphQL */ `
            mutation CreateLecturesTopics(
              $input: CreateLecturesTopicsInput!
              $condition: ModelLecturesTopicsConditionInput
            ) {
              createLecturesTopics(input: $input, condition: $condition) {
                id
              }
            }
          `;
          await client.graphql({
            query: mutation,
            variables: { input: { lectureId: topic.lectureId, topicId: topic.topicId } }
          });
        }

        async function removeTopic(lectureTopicId, client) {
          const mutation = /* GraphQL */ `
            mutation DeleteLecturesTopics(
              $input: DeleteLecturesTopicsInput!
              $condition: ModelLecturesTopicsConditionInput
            ) {
              deleteLecturesTopics(input: $input, condition: $condition) {
                id
              }
            }
          `;

          await client.graphql({
            query: mutation,
            variables: { input: { id: lectureTopicId } }
          });
        }

        // Verwenden Sie updateLectureTopics, um Topics zu verwalten
        await updateLectureTopics(lectureData, lectureid, client);

      }
       
      
      setTimeout(() => {
        navigate(`/mycompany/${companyid}/actions`);
        setIsSaving(false);
      }, 2000);
      
      return lecture.data.createLecture;
    } catch (error) {
      setIsSaving(false);
      console.warn("Error creating lecture", error);
    }
  };

  return <RegistrationStepper
  handleChange={handleChange}
  lecture={lectureData}
  createLectureHandler={createLectureHandler}
  isSaving={isSaving}
  setLectureData={setLectureData}
  isEdit={!!lectureid}
  event={event}
/>
  
}
