import React, { useState, useEffect, useCallback } from "react";
import { generateClient } from 'aws-amplify/api';
import { diff } from 'deep-object-diff';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBTextArea, MDBBtn, MDBSticky, MDBRipple, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModalTitle, MDBSpinner, MDBFile   } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import adjustChanges from "../../utils/adjustDiffChanges";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {convertHTMLtoDraft, convertHTMLtoDraftInit, ChangeHTMLDraft, ChangeDraftHTMLBtn, convertHTMlToRaw} from "../../utils/HTMLDraft";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { fullCompanyQuery, fullCompanyMutation } from "../../graphql-custom/companyQueryies";

// File upload
import { MDBFileUpload } from 'mdb-react-file-upload';
import {useDropzone} from 'react-dropzone';
import {v4 as uuid} from "uuid";

import SelectTopics from "../../components-user/topics/SelectTopics";
import ReactCountryFlag from "react-country-flag";

const client = generateClient();
const StateProofImage = React.memo(function Image({ src, alt, className, style }) {
    return <img src={src} alt={alt} className={className} style={style} />;
});

const deepUpdateObject = (object, path, value) => {
    const pathParts = path.split('.');
    let newObject = {...object};
  
    pathParts.reduce((o, p, i) => {
      if (i === pathParts.length - 1) {
        o[p] = value; // Setzt den Wert am Ende des Pfades
      } else {
        o[p] = o[p] ? {...o[p]} : {}; // Erstellt eine Kopie des nächsten Objekts im Pfad, falls es existiert, sonst ein neues Objekt
      }
      return o[p];
    }, newObject);
  
    return newObject;
  };
  

export default function EditCompany_General({presetCompanyId, toggleSaveChanges, handleChangeComplete}) {
    const { t, i18n } = useTranslation();
    console.log("presetCompanyId",presetCompanyId)
    const companyid = presetCompanyId || (window.location.pathname.includes("mycompany") ? window.location.pathname.split("/")[2] : window.location.pathname.split("/")[3]);

    // Company data
    const [initialCompany, setInitialCompany] = useState({});
    const [company, setCompany] = useState({});
    const [changes, setChanges] = useState({});
    
    const [loading, setLoading] = useState(true);

    // Get data
    const getCompany = async () => {
        try {
            const company = await client.graphql({
                variables: { id: companyid },
                query: fullCompanyQuery
            });
            //console.log("company",company);
            setSelectedTopics(company.data.getCompany.topics.items.map((topic) => topic.topic.id));
            return {
                ...company.data.getCompany,
                topics: company.data.getCompany.topics.items.map((topic) => topic.topic.id)
            };
        } catch (error) {
            console.warn("error fetching company", error);
        }
    }

    const intialLoadCompany = async () => {
        console.log("loading company")
        setLoading(true);
        const companyData = await getCompany();
        setInitialCompany(companyData);
        setCompany(companyData);
        if(companyData.description) {
            const deContent = companyData.description.find(c => c.language === "de")?.text;
            const enContent = companyData.description.find(c => c.language === "en")?.text;
            setEditorState({
              de: deContent ? EditorState.createWithContent(
                              ContentState.createFromBlockArray(
                                convertFromHTML(deContent)
                              )
                            ) : EditorState.createEmpty(),
              en: enContent ? EditorState.createWithContent(
                              ContentState.createFromBlockArray(
                                convertFromHTML(enContent)
                              )
                            ) : EditorState.createEmpty(),
            });
          }
        setLoading(false);
    }

    useEffect(() => {
        if(!companyid) {
            console.warn("no company id");
            return;
        };
        intialLoadCompany();
    }, []);

    // Changes and form handling

    const handleChange = (e) => {
        //console.log("change",e.target.attributes.datapath.value, e.target.value)

        // Get values from input
        const datapath = e.target.attributes.datapath.value;
        const value = e.target.value;

        // Update current company object
        const newCompany = deepUpdateObject(company, datapath, value)
        setCompany(newCompany);
        setChanges(diff(initialCompany, newCompany));
    }

    
    const extractUpdatedTopLevelObjects = (company, changes) => {
        const updatedObjects = {};
      
        Object.keys(changes).forEach(topLevelKey => {
          if (company.hasOwnProperty(topLevelKey)) {
            updatedObjects[topLevelKey] = company[topLevelKey];
          }
        });
      
        return updatedObjects;
      };
    // Update company
    const [isUpdating, setIsUpdating] = useState(false);
    const updateCompany = async () => {
        if(Object.keys(changes).length === 0) return;
        try {
            console.log("changes",changes)
            let updateObject = extractUpdatedTopLevelObjects(company, changes);
            console.log("updateObject",updateObject)
            const topics = updateObject.topics;
            delete updateObject.topics;
            if(Object.keys("updateObject").length > 1 || (changes.length === 1 && !changes.hasOwnProperty("topics"))) {
                const updatedCompany = await client.graphql({
                    variables: { input: {...updateObject, id: companyid}},
                    query: fullCompanyMutation
                });
                setInitialCompany(updatedCompany.data.updateCompany);
                setCompany(updatedCompany.data.updateCompany);
            }
            // Unabhängig davon, ob Topics vorhanden sind oder nicht, führen wir die Aktualisierung durch.
            console.log("updating topics");
            async function fetchExistingTopics(companyId, client) {
              const query = /* GraphQL */ `
                  query ListCompaniesTopics(
                      $filter: ModelCompaniesTopicsFilterInput
                      $limit: Int
                      $nextToken: String
                  ) {
                      listCompaniesTopics(
                          filter: $filter
                          limit: $limit
                          nextToken: $nextToken
                      ) {
                          items {
                              id
                              topicId
                          }
                      }
                  }
              `;
          
              const response = await client.graphql({
                  query,
                  variables: { filter: { companyId: { eq: companyId } }, limit: 10000 }
              });
          
              return response.data.listCompaniesTopics.items || [];
          }
          
          async function addTopic(topic, client) {
              const mutation = /* GraphQL */ `
                  mutation CreateCompaniesTopics(
                      $input: CreateCompaniesTopicsInput!
                      $condition: ModelCompaniesTopicsConditionInput
                  ) {
                      createCompaniesTopics(input: $input, condition: $condition) {
                          id
                      }
                  }
              `;
              await client.graphql({
                  query: mutation,
                  variables: { input: { companyId: topic.companyId, topicId: topic.topicId } }
              });
          }
          
          async function removeTopic(companyTopicId, client) {
              const mutation = /* GraphQL */ `
                  mutation DeleteCompaniesTopics(
                      $input: DeleteCompaniesTopicsInput!
                      $condition: ModelCompaniesTopicsConditionInput
                  ) {
                      deleteCompaniesTopics(input: $input, condition: $condition) {
                          id
                      }
                  }
              `;
          
              await client.graphql({
                  query: mutation,
                  variables: { input: { id: companyTopicId } }
              });
          }
            const existingTopicConnections = await fetchExistingTopics(companyid, client);
            const existingTopicIds = existingTopicConnections.map(t => t.topicId);

            const topicsToAdd = selectedTopics.filter(id => !existingTopicIds.includes(id));
            const topicsToRemove = existingTopicConnections.filter(t => !selectedTopics.includes(t.topicId));

            // Füge neue Topics hinzu
            await Promise.all(topicsToAdd.map(async topicId => {
                await addTopic({ topicId: topicId, companyId: companyid }, client);
            }));

            // Entferne überflüssige Topics
            await Promise.all(topicsToRemove.map(async topicConnection => {
                await removeTopic(topicConnection.id, client);
            }));
            
            setChanges({});

        } catch (error) {
            console.warn("error updating company", error);
        }
    }

    const handleUpdateCompany = async () => {
        setIsUpdating(true);
        await updateCompany();
        setIsUpdating(false);
        if(toggleSaveChanges) handleChangeComplete("companyProfile");
    }

    const handleResetChanges = () => { 
        setCompany(initialCompany);
        setChanges({});
    }
    


    const uploadImage = async (file) => {
        const filename = file.type === "image/png" ? uuid() + ".png" : file.type === "image/jpeg" && uuid() + ".jpg"
        // await Storage.put(filename, file, {
        //     level: "protected",
        //     contentType: file.type
        //   });
        }

    const MainImage = (props) => {
        const onDrop = useCallback(acceptedFiles => {
            uploadImage(acceptedFiles[0])
          }, [])
          const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
       
      
        return (
          <section className="container" style={
            {
                top: "50px",
                position: "absolute",
                left: "100px",
                width: "fit-content",
                zIndex: "1"
            }
          } >
            <div {...getRootProps({className: 'dropzone text-center'})}>
              <input {...getInputProps()} />
              <StateProofImage alt={company.image?.main?.alt || "Company Image"}
                src={company.image?.main?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+ company.image?.main?.fileName : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
                className="img-thumbnail rounded-circle border"
                style={{ height: "200px", width: "200px", objectFit: "contain" }}
                />
                
            <MDBBtn color="light" size="sm" className="m-0" style={
            {
                top: "170px",
                position: "absolute",
                left: "60px",
                zIndex: "1"
            }
            }>{t("Upload Logo")}</MDBBtn>
               {/* <p>{t("Drag 'n' drop or click to select files")}</p> */}
            </div>
          </section>
        );
      }

    const [nameFieldLocked, setNameFieldLocked] = useState(true);

    // Editor
    const [editorState, setEditorState] = useState({
        de: EditorState.createEmpty(),
        en: EditorState.createEmpty(),
      })
    const [editorModes, setEditorModes] = useState({showDraft: true,showHTML: false})
    const onEditorStateChange = (editorState, language) => {
        setEditorState(oldState => ({...oldState, [language]: editorState}));
      //console.log("editorState",convertHTMlToRaw(editorState))

      setCompany(oldCompany => ({...oldCompany, description: [...oldCompany.description?.filter(c => c.language !== language) ?? [], {language, text: convertHTMlToRaw(editorState)}] }));
        setChanges(diff(initialCompany, company));
    } 
    const ChangeHTMLDraft = () => {
        if (editorModes.showDraft && !editorModes.showHTML) {
            setEditorModes({showDraft: false,showHTML: true})
        } else if (!editorModes.showDraft && editorModes.showHTML) {
            setEditorModes({showDraft: true,showHTML: false})
        }
        }
    const ChangeDraftHTMLBtn = () => <div className="rdw-option-wrapper" onClick={ChangeHTMLDraft}><MDBIcon icon="code" /></div>

    // Effects

    

    // useEffect(() => {
    //     if (loading || !company) return
    //     else {
    //         convertHTMLtoDraftInit(company.description[0].text || "", ContentState, EditorState, setEditorState)
    //     }
    // }, [company])


   // Select Topics 
  const [topics, setTopics] = useState([]);
  const [topicGroups, setTopicGroups] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);


  const [selectTopicsModal, setSelectTopicsModal] = useState(false);
  const toggleSelectTopicsModal = () => setSelectTopicsModal(!selectTopicsModal);


  const handleSetSelectedTopics = (topics) => {
    setSelectedTopics(topics);
    handleChange({
      target: {
        value: topics,
        attributes: {
          datapath: {
            value: "topics"
          }
        },
      }
    })
  }

    // Topics data

    const getTopics = async () => {
        try {
          const topicGroupsWithTopics = await client.graphql({
            query: /* GraphQL */ `
            query ListTopicGroups(
              $filter: ModelTopicGroupFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                  id
                  color
                  sortKey
                  name {
                    language
                    text
                  }
                  topics {
                    items {
                      id
                      name {
                        language
                        text
                      }
                      topicGroupTopicsId
                    }
                  }
                }
                nextToken
                __typename
              }
            }
          `
            });
            setTopicGroups(topicGroupsWithTopics.data.listTopicGroups.items.sort((a, b) => a.sortKey - b.sortKey));
            const topics = topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat();
            setTopics(topics);
            

        } catch (error) {
            console.log("error on fetching topics", error);
        }
    }

    useEffect(() => {
        if (topics.length === 0) {
            getTopics();
        }
    }, [topics]);


    const Topics = ({topics, topicGroups, selectedTopics}) => {
      // console.log("topics",topics);
      // console.log("topicGroups",topicGroups);
      // console.log("selectedTopics",selectedTopics);
      // First sort the topics by topicGroup (new array with objects for eacht group, inside object the key: topics, inside the topics for this group), then return for each group a col, inside the col the title of the group, then the topics as chips
      if(!topics || topics?.length === 0 || !topicGroups || topicGroups?.length === 0 || selectedTopics?.length === 0) {
          return null;
      }
      let sortedTopics = [];
      selectedTopics.forEach((selectedTopic) => {
          const topicData = topics.find((topic) => topic.id === selectedTopic);
          const topicGroup = topicGroups.find((topicGroup) => topicGroup.id === topicData.topicGroupTopicsId);
          const topicGroupIndex = sortedTopics.findIndex((sortedTopic) => sortedTopic.id === topicGroup.id);
          if (topicGroupIndex === -1) {
              sortedTopics.push({...topicGroup, topics: [topicData]});
          } else {
              sortedTopics[topicGroupIndex].topics.push(topicData);
          }
      });
      console.log("sortedTopics",sortedTopics);
      return (
          <MDBRow className="my-3 my-lg-4">
              {sortedTopics.map((topicGroup) => {
                  return (
                      <MDBCol size="12" xxl="6" key={topicGroup.id} className="mb-3 mb-lg-4">
                          <h6>{topicGroup.name?.find(n => n.language === i18n.language)?.text || topicGroup.name?.[0]?.text}</h6>
                          <MDBRow>
                              {topicGroup.topics.map((topic) => {
                                  return (
                                      <MDBCol size="12" key={topic.id}>
                                          <MDBChip className="m-1 float-start" bgColor={topicGroup.color} textColor="white">{topic?.name?.find((name) => name.language === i18n.language)?.text || topic.name[0]?.text}</MDBChip>
                                      </MDBCol>
                                  )
                              })}
                          </MDBRow>
                      </MDBCol>
                  )
              })}
          </MDBRow>
      )
  }

    // User Image
    const [isUploadingImageStates, setIsUploadingImageStates] = useState({});
    const [uploadingImageErrors, setUploadingImageErrors] = useState({});
    
    const handleSetImage = (file, type) => {
      // upload file using mutation: uploadFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadFiles-${env}")
      if(file) {
        try {
            setIsUploadingImageStates(prevState => ({...prevState, [type]: true}));
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = error => {
          console.log('Error reader: ', error);
            setIsUploadingImageStates(prevState => ({...prevState, [type]: false}));
        }
        reader.onload = async () => {
          try {
            console.log(reader.result)
            const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
            const response = await client.graphql({
              query: /* GraphQL */ `
              mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
                uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
              }
            `,
              variables: {
                imageBuffer: base64FileBuffer,
                imageType: type === "image" ? "companyLogo" : "companyBanner",
              },
            });
            console.log("response", response);
            const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
            handleChange({
              target: {
                value: {
                  fileName,
                  alt: " Image of " + company.name,
                },
                attributes: {
                  datapath: {
                    value: type === "image" ? "image.main" : "banner",
                  }
                },
              },
            });
            } catch (error) {
              console.log("error", error);
                setUploadingImageErrors(prevState => ({...prevState, [type]: error.errors[0].message}));
                setIsUploadingImageStates(prevState => ({...prevState, [type]: false}));
            }
        }
        setIsUploadingImageStates(prevState => ({...prevState, [type]: false}));
        setUploadingImageErrors(prevState => ({...prevState, [type]: null}));
        } catch (error) {
          console.log("error", error);
            setUploadingImageErrors(prevState => ({...prevState, [type]: error.errors[0].message}));
            setIsUploadingImageStates(prevState => ({...prevState, [type]: false}));
        }
      }
    }

    useEffect(() => {
      if(toggleSaveChanges) {
        console.log("Saving changes to company profile")
        handleUpdateCompany();
      }
    }, [toggleSaveChanges])


    return (
<>
{
    typeof toggleSaveChanges !== "boolean" &&
    <MDBSticky
    offset={60}
    boundary
    tag='div'
    href=''

    >
    <MDBRow className="mb-3" >
        <MDBCol size="12" className="text-end">
        <div style={{backgroundColor: "white", width: "fit-content", float: "right"}}>
        <MDBBtn onClick={() => handleResetChanges()} color='light' rippleColor='dark' size="sm" >
        {t("Reset Changes")}
        </MDBBtn>
        <MDBBtn onClick={() => handleUpdateCompany()} color="success" outline>
        {t("Save")}
        </MDBBtn>
        </div>
       
        </MDBCol>
    </MDBRow>
</MDBSticky>
}



    <MDBRow className="mb-2 mb-xl-4">
        <MDBCol size="12" lg="4">
        <div className="text-center">
        <div className="w-100 mb-1">
        <StateProofImage alt={company.image?.main?.alt || "Company Image"}
              src={company.image?.main?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+ company.image?.main?.fileName : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
              className="img-thumbnail rounded-circle border"
              style={{ height: "200px", width: "200px", objectFit: "contain" }}
          />
        </div>
        <span className="text-muted" style={{fontSize: "smaller"}}>{t("Recommended format: 16:5, e.g. 1400px * 410px")}</span><br/>
           <MDBBtn color="primary" onClick={() => document.getElementById("imageMain").click()}>{t("Upload Logo")}</MDBBtn>
            <MDBFile className="d-none" size='sm' id='imageMain' onChange={e => handleSetImage(e.target.files[0], "image")} />
            {
            isUploadingImageStates.image && <span><MDBSpinner role="status" /></span>
            }
            {
                uploadingImageErrors.image && <span className="mt-3 text-danger fw-bold">
                {uploadingImageErrors.image}
              </span>
            }   
        </div>
        </MDBCol>
        <MDBCol size="12" lg="8">
        <div className="text-center">
        <div className="w-100 mb-1">
        <StateProofImage alt={company.banner?.alt || "Company Image"}
              src={company.banner?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+ company.banner?.fileName : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
              className="border bg-white"
              style={{ height: "200px", maxWidth: "100%", width: "auto", aspectRatio: "16 / 5" , objectFit: "cover" }}
          />
        </div>
        <span className="text-muted" style={{fontSize: "smaller"}}>{t("Recommended format: 16:5, e.g. 1400px * 410px")}</span><br/>
        <MDBBtn color="primary" onClick={() => document.getElementById("imageBanner").click()}>{t("Upload Banner")}</MDBBtn>
        <MDBFile className="d-none" size='sm' id='imageBanner' onChange={e => handleSetImage(e.target.files[0], "banner")} />
            {
            isUploadingImageStates.banner && <span><MDBSpinner role="status" /></span>
            }
            {
            uploadingImageErrors.banner && <span className="mt-3 text-danger fw-bold">
            {uploadingImageErrors.banner}
            </span>
            }
        </div>
        
         
        </MDBCol>
    </MDBRow>



<MDBCard className="mb-4">
    <MDBCardBody>
    <MDBCardTitle className="mb-4">{t("Informations")}</MDBCardTitle>
    {loading || !company ?
        <div>Loading...</div>
        :
        <>
        <MDBRow>
        <MDBCol md="6">
                <h5>{t("Address")}</h5>
            <h6>{t("Name")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="11">
                        <MDBInput disabled={nameFieldLocked} autoComplete="off" datapath="name" label={t("Name")} value={company.name || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol size="1" className="text-center pt-2">
                        {nameFieldLocked ? 
                        <MDBIcon fas icon="unlock-alt" onClick={() => setNameFieldLocked(!nameFieldLocked)} />
                        : 
                        <MDBIcon fas icon="lock" onClick={() => setNameFieldLocked(!nameFieldLocked)} />
                        }
                    </MDBCol>
                </MDBRow>    
                <h6>{t("Address")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="8">
                        <MDBInput autoComplete="off" datapath="contact.address.street" label={t("Street")} value={company.contact?.address?.street || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                    <MDBCol size="4">
                        <MDBInput autoComplete="off" datapath="contact.address.streetNumber" label={t("Number")} value={company.contact?.address?.streetNumber || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.street2" label={t("Street Additonal")} value={company.contact?.address?.street2 || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.zip" label={t("Zip")} value={company.contact?.address?.zip || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.city" label={t("City")} value={company.contact?.address?.city || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.countryCode" label={t("Country")} value={company.contact?.address?.countryCode || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <h6>{t("Contact")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="12" md="4" className="mb-3 mb-md-0">
                        <MDBInput
                            autoComplete="off"
                            value={company.contact?.phone || ""}
                            label={t("Phone")}
                            datapath="contact.phone"
                            onChange={handleChange}
                            disabled={isUpdating}
                        />

                    </MDBCol>
                    <MDBCol size="12" md="4" className="mb-3 mb-md-0">
                        <MDBInput
                            autoComplete="off"
                            value={company.contact?.mobile || ""}
                            label={t("Mobile")}
                            datapath="contact.mobile"
                            onChange={handleChange}
                            disabled={isUpdating}
                        />
                    </MDBCol>
                    <MDBCol size="12" md="4">
                        <MDBInput
                            autoComplete="off"
                            value={company.contact?.fax || ""}
                            label={t("Fax")}
                            datapath="contact.fax"
                            onChange={handleChange}
                            disabled={isUpdating}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol size="12" md="6" className="mb-3 mb-md-0">
                        <MDBInput autoComplete="off" datapath="contact.email" label={t("Email")} value={company.contact?.email || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                    <MDBCol size="12" md="6">
                        <MDBInput autoComplete="off" datapath="website" label={t("Website")} value={company.website || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <hr className="w-100 d-md-none" />
            <MDBCol md="6" className="text-center">
                <MDBBtn onClick={() => toggleSelectTopicsModal()} color="primary" outline>
                    {t("Select topics")}
                </MDBBtn>
                {/* <h5>{t("Selected Topics")}</h5> */}
                
                <MDBRow className="w-100 mx-0">
                    <MDBCol>
                       <Topics
                            topics={topics}
                            topicGroups={topicGroups}
                            selectedTopics={selectedTopics}
                            

                            />
                    </MDBCol>
                </MDBRow>
            </MDBCol>

           
            
        </MDBRow>
    </>
    }
    </MDBCardBody>
</MDBCard>

<MDBCard>
    <MDBCardBody>
    <MDBCardTitle className="mb-4">{t("Description")}</MDBCardTitle>
    {
      editorState &&
      <>
      <ReactCountryFlag
        countryCode="de"
        svg
        style={{
        width: "20px",
        height: "20px",
        }}
        title={i18n.language}
    /> 
    <Editor
        editorState={editorState["de"]}
        onEditorStateChange={e => onEditorStateChange(e, "de")}
        editorClassName={editorModes.showDraft ? "md-textarea form-control" : "md-textarea form-control d-none"}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        toolbarCustomButtons={[<ChangeDraftHTMLBtn />]}
      />
      <br></br>
      <ReactCountryFlag
        countryCode="gb"
        svg
        style={{
        width: "20px",
        height: "20px",
        }}
        title={i18n.language}
    />
    <Editor
        editorState={editorState["en"]}
        onEditorStateChange={e => onEditorStateChange(e, "en")}
        editorClassName={editorModes.showDraft ? "md-textarea form-control" : "md-textarea form-control d-none"}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        toolbarCustomButtons={[<ChangeDraftHTMLBtn />]}
      />
    {/* <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName={editorModes.showDraft ? "md-textarea form-control" : "md-textarea form-control d-none"}
        toolbarCustomButtons={[<ChangeDraftHTMLBtn />]}
        onEditorStateChange={onEditorStateChange}
        /> */}
        {/* <textarea
            onChange={e => {
              console.log("e")
              convertHTMLtoDraft(e, ContentState, EditorState, setEditorState)
            }}
            className={editorModes.showHTML ? "md-textarea form-control w-100" : "md-textarea form-control w-100 d-none"}
            value={editorState && convertHTMlToRaw(editorState)}
        /> */}
      </>
    }
   
    </MDBCardBody>
</MDBCard>
<MDBModal open={selectTopicsModal} onClose={() => setSelectTopicsModal(false)}>
    <MDBModalDialog size="xl">
        <MDBModalContent >
            <MDBModalHeader>
                <MDBModalTitle>Select Topics</MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={toggleSelectTopicsModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <SelectTopics
                topics={topics}
                topicGroups={topicGroups}
                isActive={selectTopicsModal}
                toggleActive={toggleSelectTopicsModal}
                selectedTopics={company.topics || []}
                setSelectedTopics={handleSetSelectedTopics}
                parentTopics={topics}
                parentTopicGroups={topicGroups}
                maxSelect={5}
                  />
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleSelectTopicsModal}>{t("Close")}</MDBBtn>
            </MDBModalFooter>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
    )
}
