import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { generateClient, get } from 'aws-amplify/api';
import { Link, useNavigate, useParams } from "react-router-dom";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardLink, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBProgress, MDBProgressBar, MDBRow, MDBSpinner, MDBTable, MDBTableBody, MDBTableHead, MDBTypography } from "mdb-react-ui-kit";
import { isoToGermanDate } from "../../../utils/dateTools";
import { getUrl, uploadData } from "aws-amplify/storage";
import { transformMoney } from "../../../utils/money";
import parse from 'html-react-parser';
import { getLocalizedText } from "../../../utils/localizedText";

const productTypes = [
    { type: "furniture", name: "Furniture" },
    // { type: "sanitary", name: "Sanitary" },
    { type: "electrical", name: "Electrical" },
    { type: "compressed_air", name: "Compressed air" },
    // { type: "internet_cable", name: "Internet - Cable" },
    // { type: "internet_wifi", name: "Internet - Wifi" }
];

const productTypesOrder = [
  "carpet", "display_print","boothDefault", "furniture", "sanitary", "electrical", "compressed_air", "internet_cable", "internet_wifi", "marketing"
];

export default function EditBooth() {
    const { t, i18n } = useTranslation();
    const client = generateClient();
    const navigate = useNavigate();

    // params
    const { companyid, boothId } = useParams();
    // states
    const [isLoading, setIsLoading] = useState(false);
    const [booth, setBooth] = useState(null);
    const [isCoExhibitorBooth, setIsCoExhibitorBooth] = useState(false);
    const [event, setEvent] = useState(null);
    const [bookedProducts, setBookedProducts] = useState([]);
    const [showBookedProductsModal, setShowBookedProductsModal] = useState(false);
    const [showBoothDetails, setShowBoothDetails] = useState(false);


    // fetch booth
    const getBookedBooth = async (boothId) => {
        try {
            const response = await client.graphql({
                query: /* GraphQL */ `
                query GetBookedBooth($id: ID!) {
                  getBookedBooth(id: $id) {
                    id
                    orderLineItem {
                      id
                      order {
                        id
                        orderNumber
                        buyerOrderNumber
                        status
                        paymentStatus
                        deliveryStatus
                        notes
                        totalAmount
                      }
                      product {
                        id
                        type
                        price
                        name {
                          language
                          text
                        }
                        description {
                          language
                          text
                        }
                        image {
                          main {
                              fileName
                          }
                        }
                        includes {
                          language
                          text
                        }
                      }
                      qty
                      price
                    }
                    event {
                      id
                      name {
                        language
                        text
                      }
                      image {
                        fileName
                        alt
                      }
                      date
                      startDate
                      endDate
                      deadlineBoothEquipmentPrint
                      startBoothEquipmentBooking
                      deadlineBoothEquipmentBooking
                      lectureRegistrationEndDate
                      floorPlan {
                        s3Path
                      }
                      landingPageUrl
                    }
                    boothNumber
                    company {
                      id
                      name 
                      image {
                        main {
                            fileName
                        }
                      }
                    }
                    status
                    equipment {
                        items {
                            id
                            data
                            files {
                              fileName
                            }
                            status
                            qty
                            orderLineItem {
                              order {
                                orderNumber
                                createdAt
                              }
                              price
                              qty
                              product {
                                icon
                                image {
                                  main {
                                    fileName
                                  }
                                }
                                name {
                                  language
                                  text
                                }
                                description {
                                  language
                                  text
                                }
                                type
                                subType
                              }
                            }
                        }
                      nextToken
                     
                    }
                    createdAt
                    updatedAt
                  }
                }
              `,
                variables: {
                    id: boothId
                }
            });
            console.log('getBookedBooth response:', response);
            setBooth(response.data.getBookedBooth);
            if(response.data.getBookedBooth.orderLineItem.product.type === "event_booth_partner") {
              setIsCoExhibitorBooth(true);
            }
            setEvent(response.data.getBookedBooth.event);
            setBookedProducts(response.data.getBookedBooth.equipment.items);

        } catch (error) {
            console.error('getBookedBooth error:', error);
        }
    }

    useEffect(() => {
        getBookedBooth(boothId);
    }, [boothId]);

    const openFloorPlan = async () => {
      try {
         // Get Url
          const url = await getUrl({ key: event.floorPlan.s3Path.replace("public/", "") });
          window.open(url.url.toString(), '_blank')
      } catch (error) {
        
      }
  }

  // Products
  const [eventProducts, setEventProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showAddProductModal, setShowAddProductModal] = useState(false);

  const getEventProducts = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
        query ListEventProducts(
          $filter: ModelEventProductFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listEventProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              event {
                id 
              }
              product {
                id
                sku
                name {
                  language
                  text
                }
                image {
                  main {
                    fileName
                  }
                }
                description {
                  language
                  text
                }
                ean
                type
                subType
                price
                allowedBuyerGroups
                icon
                stock
              }
              price
              stock
              isAvailable
              createdAt
              updatedAt
              productEventProductsId
              eventEventProductsId
              __typename
            }
            nextToken
            __typename
          }
        }
      `,
        variables: {
          filter: {
            eventEventProductsId: {
              eq: event.id,
            },
          },
        },
      });
      setProducts(response.data.listEventProducts.items);
    } catch (error) {
      console.error('Error fetching event products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const decreaseQty = (product) => {
    const productIndex = selectedProducts.findIndex(p => p.id === product.id);
    if(productIndex > -1 && selectedProducts[productIndex].qty > 0) {
      const newSelectedProducts = [...selectedProducts];
      newSelectedProducts[productIndex].qty -= 1;
      setSelectedProducts(newSelectedProducts);
    }
  };

  const increaseQty = (product) => {
    const productIndex = selectedProducts.findIndex(p => p.id === product.id);
    if(productIndex > -1) {
      const newSelectedProducts = [...selectedProducts];
      newSelectedProducts[productIndex].qty += 1;
      setSelectedProducts(newSelectedProducts);
    } else {
      setSelectedProducts([...selectedProducts, { id: product.id, qty: 1 }]);
    }
  };

  // Marketing Products Modal

  const [showAddMarketingProductModal, setShowAddMarketingProductModal] = useState(false);


  // Modify BoothEquipment

  // handle updates to the data field of the BoothEquipment
  const updateBoothEquipment = async (boothEquipmentId, data) => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          mutation UpdateBoothEquipment($input: UpdateBoothEquipmentInput!) {
            updateBoothEquipment(input: $input) {
              id
              data
              files {
                fileName
              }
              status
              qty
              orderLineItem {
                order {
                  orderNumber
                  createdAt
                }
                price
                qty
                product {
                  icon
                  image {
                    main {
                      fileName
                    }
                  }
                  name {
                    language
                    text
                  }
                  type
                  subType
                }
              }
            }
          }
        `,
        variables: {
          input: {
            id: boothEquipmentId,
            data
          },
        },
      });
      console.log('updateBoothEquipment response:', response);
      setBookedProducts(bookedProducts.map((bookedProduct) => bookedProduct.id === boothEquipmentId ? response.data.updateBoothEquipment : bookedProduct));
    } catch (error) {
      console.error('updateBoothEquipment error:', error);
    }
  }

  // Carpet

  const [eventCarpets, setEventCarpets] = useState([]);
  const [showSelectCarpetModal, setShowSelectCarpetModal] = useState(false);
  const [boothEquipmentIdForSelectCarpet, setBoothEquipmentIdForSelectCarpet] = useState(null);

  const getEventCarpets = async () => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
        query ListAvailableCarpetsInEvents(
          $filter: ModelAvailableCarpetsInEventsFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listAvailableCarpetsInEvents(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
          ) {
            items {
              id
              eventId
              eventCarpetId
              event {
                id
              }
              eventCarpet {
                id
                order
                name {
                  language
                  text
                }
                description {
                  language
                  text
                }
                color
                image {
                  fileName
                }
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
        }
      `,
        variables: {
          filter: {
            eventId: {
              eq: event.id,
            },
          },
        },
      });
      setEventCarpets(response.data.listAvailableCarpetsInEvents.items);
    } catch (error) {
      console.error('Error fetching event carpets:', error);
    }
  };

  // displays

  const [boothEquipmentForUploadDisplayFile, setBoothEquipmentForUploadDisplayFile] = useState(null);
  const [showUploadDisplayFileModal, setShowUploadDisplayFileModal] = useState(false);
  const [displayFileUploadStatus, setDisplayFileUploadStatus] = useState({});
  const inputFieldRef = useRef();

  const uploadFile = async (file, boothEquipmentId) => {
    try {
        let lastTimeStamp = Date.now(); // Initial timestamp
        let lastTransferredBytes = 0; // Initial transferred bytes
        if(!file.name.endsWith('.pdf') || file.name.endsWith('.PDF')) {
          alert('Only PDF files are allowed');
          return;
        }
        const result = await uploadData({
          key: `displayFiles/${file.name}`, 
          data: file,
          options: {
            onProgress: ({ transferredBytes, totalBytes }) => {
              if (totalBytes) {
                const currentTime = Date.now();
                const timeElapsed = (currentTime - lastTimeStamp) / 1000; // Convert ms to seconds
                const bytesTransferred = transferredBytes - lastTransferredBytes;
                const speedInMBps = (bytesTransferred / (1024 * 1024)) / timeElapsed; // Convert bytes to MB and divide by seconds
                setDisplayFileUploadStatus(old => ({
                    ...old,
                  [boothEquipmentId]: {
                    transferredBytes,
                    totalBytes,
                    percent: Math.round((transferredBytes / totalBytes) * 100),
                    speed: speedInMBps.toFixed(2) + ' MB/s',
                  },
                }));
              }
            }
          }
        }).result;
        console.log('Path from Response: ', result.key);
        // Update the lecture with the video path on the field videoS3Key
        updateBoothEquipment(boothEquipmentId, result.key);
        
      } catch (error) {
        console.log('Error : ', error);
      }
}

const downloadFile = async (key) => {
  try {
    const url = await getUrl({ key });
    console.log('Download URL:', url);
    window.open(url.url, '_blank');
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

// Equipment / Product details modal

const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);
const [selectedProduct, setSelectedProduct] = useState(null);

const openProductDetails = (product) => {
  setSelectedProduct(product);
  setShowProductDetailsModal(true);
};





  useEffect(() => {
    if(event?.id) {
      getEventProducts();
      getEventCarpets();
    }
  }, [event]);

// <Link to={`/checkout?${selectedProducts.map((selectedProduct) => `productId=${selectedProduct.id}`).join("&")}&companyId=${companyid}` + (notice ? `&notes=${notice}` : "")} className="float-end">
  const handleInitBooking = () => {
    console.log("companyId", companyid)
    let productIdsForOrder = [];
    selectedProducts.forEach((selectedProduct) => {
      for (let i = 0; i < selectedProduct.qty; i++) {
        productIdsForOrder.push(selectedProduct.id);
      }
    });
    const url = `/checkout?${productIdsForOrder.map((productId) => `productId=${productId}`).join("&")}&companyId=${companyid}&boothId=${boothId}`;
    // Redirect to checkout page using react-router-dom
    navigate(url);

  }



  return (
<>
<MDBCard>
      <MDBCardBody>
      <MDBTypography tag="h5">{isCoExhibitorBooth ? t("Manage event co-exhibitor stand") : t("Manage event stand")}</MDBTypography>
        <MDBRow>
            <MDBCol size="12" lg="6" xl="4">
                <MDBTypography tag="h6">{t("Event")}</MDBTypography>
                {event? <>
                  <span>{getLocalizedText(event?.name)}</span>
                  <a href={event?.landingPageUrl} target="_blank" rel="noreferrer" className="text-decoration-none">
                  <MDBIcon className="ms-2" size="sm" color="secondary" fas icon="external-link-alt" />
                  </a>
                  <br />
                  <span>{isoToGermanDate(event?.startDate)} - {isoToGermanDate(event?.endDate)}</span>
                </>
                : <MDBSpinner />}
            </MDBCol>
            <MDBCol size="12" lg="6" xl="4">
                <MDBTypography tag="h6">{t("Stand")}</MDBTypography>
                {booth? <>
                  <div className='d-flex align-items-center'>
                  {booth?.orderLineItem?.product?.image?.main?.fileName ?
                      <img
                          src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+booth.orderLineItem.product.image.main.fileName}
                          className=""
                          style={{ height: "50px", width: "50px", objectFit: "contain" }}
                          alt={booth.orderLineItem.product.image?.main?.alt}
                      />
                  :
                      <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="archway" />
                              
                  }
                  <div className='ms-3'>
                  <span>{getLocalizedText(booth.orderLineItem.product.name)}</span>
                  <br />
                  <span className="text-secondary" onClick={() => setShowBoothDetails(true)}>{t("More details")}</span>
                  </div>
                  </div>
                  <br />
                  <span>{t("Stand number")}: {booth?.boothNumber || t("not yet determined")}</span>
                  {/* Link to Floor plan from event */}
                  <MDBIcon className="ms-2 cursor-pointer" size="sm" color="secondary" fas icon="external-link-alt" onClick={openFloorPlan} />
                </>
                : <MDBSpinner />}
                
            </MDBCol>
            <MDBCol size="12" lg="6" xl="4">
                <MDBTypography tag="h6">{t("Booking")}</MDBTypography>
                <MDBTypography tag="h6">{booth?.status}</MDBTypography>
                <span>{t("Booking date")}: {isoToGermanDate(booth?.createdAt)}</span>
                <br />
                <span>{t("Payment status")}: {t(booth?.orderLineItem?.order?.paymentStatus)}</span>
            </MDBCol>
        </MDBRow>
        {!isCoExhibitorBooth &&
        <div className="text-danger">
        <MDBTypography tag="h5" className="mt-3">{t("Deadlines")}</MDBTypography>
        <MDBRow>
            <MDBCol size="12" lg="4">
                <MDBTypography tag="h6">{t("Booth equipment booking")}</MDBTypography>
                <span>{isoToGermanDate(event?.startBoothEquipmentBooking)} - {isoToGermanDate(event?.deadlineBoothEquipmentBooking)}</span> 
            </MDBCol>
            <MDBCol size="12" lg="4">
                <MDBTypography tag="h6">{t("Upload print media")}</MDBTypography>
                <span>{isoToGermanDate(event?.deadlineBoothEquipmentPrint)}</span>
            </MDBCol>
            <MDBCol size="12" lg="4">
                <MDBTypography tag="h6">{t("Lecture registration")}
               <Link to={`/mycompany/${companyid}/lectures`}>
                <MDBIcon className="ms-2" size="sm" color="secondary" fas icon="external-link-alt" />
                </Link>
                  </MDBTypography>
                <span>{isoToGermanDate(event?.lectureRegistrationEndDate)}</span>
            </MDBCol>
        </MDBRow>
        </div>
        }
    </MDBCardBody>
</MDBCard>


{!isCoExhibitorBooth &&
<MDBCard className="mt-2 mt-md-4">
        <MDBCardBody>
            <MDBTypography tag="h5">{t("Equipment")}</MDBTypography>
            <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th>{t("Product")}</th>
                        <th>{t("Quantity")}</th>
                        <th>{t("Price")}</th>
                        <th>{t("Actions")}</th>

                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {bookedProducts
                    .filter(p => p.orderLineItem.product.type !== "marketing_booth")
                    .sort((a, b) => productTypesOrder.indexOf(a.orderLineItem.product.subType) - productTypesOrder.indexOf(b.orderLineItem.product.subType))
                    .map((equipment, index) => (
                        <tr key={index}>
                            <td>
                              {equipment.orderLineItem.product.image?.main?.fileName ?
                                  <img
                                      src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+  equipment.orderLineItem.product.image.main.fileName}
                                      className=""
                                      style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                      alt={equipment.orderLineItem.product.image.alt}
                                  />
                              :
                                  <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon={equipment.orderLineItem.product.icon} />
                                          
                              }
                              {getLocalizedText(equipment.orderLineItem.product.name)}
                              <MDBBtn color='tertiary' rippleColor='light' size="sm" className="ms-2" style={{verticalAlign: "middle"}} onClick={() => openProductDetails(equipment.orderLineItem.product)}>{t("Details")}</MDBBtn>
                              {/* {equipment.orderLineItem.product.type} {equipment.orderLineItem.product.subType} */}
                            </td>
                           
                            <td>{equipment.orderLineItem.qty}</td>
                            <td>{transformMoney(equipment.orderLineItem.price)}</td>
                            <td className="text-center">
                              {equipment.orderLineItem.product.subType === "display_print" &&
                              <>
                             { equipment.data ?
                              <>
                              <span className="text-success">
                                {t("Uploaded successfully")}
                              </span>
                              <br />
                              <MDBBtn color="primary" size="sm" outline
                              disabled={event?.deadlineBoothEquipmentPrint < new Date().toISOString()}
                                onClick={() => {
                                  setShowUploadDisplayFileModal(true);
                                  setBoothEquipmentForUploadDisplayFile(equipment);
                                }}
                              >{t("Change")}</MDBBtn>
                              </>
                              :
                              <>
                               <MDBBtn color="primary" size="sm" outline
                               disabled={event?.deadlineBoothEquipmentPrint < new Date().toISOString()}
                                onClick={() => {
                                  setShowUploadDisplayFileModal(true);
                                  setBoothEquipmentForUploadDisplayFile(equipment);
                                }}
                              >{t("Upload")}</MDBBtn>
                              </>}
                              </>
                             
                              }
                              {equipment.orderLineItem.product.subType === "carpet" &&
                              <MDBBtn color="primary" size="sm" outline
                                onClick={() => {
                                  setShowSelectCarpetModal(true);
                                  setBoothEquipmentIdForSelectCarpet(equipment.id);
                                }}
                              >
                                {equipment.data && eventCarpets.find(c => c.eventCarpet.id === equipment.data) ?
                                <>
                                <img
                                      src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/carpet/"+  eventCarpets.find(c => c.eventCarpet.id === equipment.data).eventCarpet.image.fileName}
                                      className=""
                                      style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                      alt="color_carpet"
                                  />
                                 <span className="ms-2">{t("Change")}</span>
                                </>
                                :
                                  <><MDBIcon fas icon="palette" /> {t("Select color")}</>
                                }
                                
                                </MDBBtn>
                              }
                            </td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
            <div className="d-flex justify-content-center">
            <MDBBtn color="primary" outline disabled={event?.deadlineBoothEquipmentBooking < new Date().toISOString() || event?.startBoothEquipmentBooking > new Date().toISOString()} onClick={() => setShowBookedProductsModal(true)}>{t("Book additional equipment")}</MDBBtn>
            </div>
        </MDBCardBody>
</MDBCard>
}

<MDBCard className="mt-2 mt-md-4">
        <MDBCardBody>
            <MDBTypography tag="h5">{t("Marketing")}</MDBTypography>
            <MDBCardText>
                {t("Do your products and services deserve more attention?")}
                <br />
                <span className="fw-bold">{t("Book various marketing measures now to stand out at the event.")}</span>
            </MDBCardText>
            <MDBBtn color="primary"
            disabled={event?.startDate < new Date().toISOString()}
             outline onClick={() => setShowAddMarketingProductModal(true)}>{t("Book marketing products")}</MDBBtn>
             {bookedProducts.filter(p => p.orderLineItem.product.type === "marketing_booth").length > 0 &&
             <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th>{t("Product")}</th>
                        <th>{t("Quantity")}</th>
                        <th>{t("Price")}</th>
                        <th>{t("Actions")}</th>

                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {bookedProducts
                    .filter(p => p.orderLineItem.product.type === "marketing_booth")
                    .sort((a, b) => productTypesOrder.indexOf(a.orderLineItem.product.subType) - productTypesOrder.indexOf(b.orderLineItem.product.subType))
                    .map((equipment, index) => (
                        <tr key={index}>
                            <td>
                              {equipment.orderLineItem.product.image?.main?.fileName ?
                                  <img
                                      src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+  equipment.orderLineItem.product.image.main.fileName}
                                      className=""
                                      style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                      alt={equipment.orderLineItem.product.image.alt}
                                  />
                              :
                                  <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon={equipment.orderLineItem.product.icon} />
                                          
                              }
                              {getLocalizedText(equipment.orderLineItem.product.name)}
                              <MDBBtn color='tertiary' rippleColor='light' size="sm" className="ms-2" style={{verticalAlign: "middle"}} onClick={() => openProductDetails(equipment.orderLineItem.product)}>{t("Details")}</MDBBtn>
                              {/* {equipment.orderLineItem.product.type} {equipment.orderLineItem.product.subType} */}
                            </td>
                           
                            <td>{equipment.orderLineItem.qty}</td>
                            <td>{transformMoney(equipment.orderLineItem.price)}</td>
                            <td className="text-center">
                              {equipment.orderLineItem.product.subType === "display_print" &&
                              <>
                             { equipment.data ?
                              <>
                              <span className="text-success">
                                {t("Uploaded successfully")}
                              </span>
                              <br />
                              <MDBBtn color="primary" size="sm" outline
                              disabled={event?.deadlineBoothEquipmentPrint < new Date().toISOString()}
                                onClick={() => {
                                  setShowUploadDisplayFileModal(true);
                                  setBoothEquipmentForUploadDisplayFile(equipment);
                                }}
                              >{t("Change")}</MDBBtn>
                              </>
                              :
                              <>
                               <MDBBtn color="primary" size="sm" outline
                               disabled={event?.deadlineBoothEquipmentPrint < new Date().toISOString()}
                                onClick={() => {
                                  setShowUploadDisplayFileModal(true);
                                  setBoothEquipmentForUploadDisplayFile(equipment);
                                }}
                              >{t("Upload")}</MDBBtn>
                              </>}
                              </>
                             
                              }
                              {equipment.orderLineItem.product.subType === "carpet" &&
                              <MDBBtn color="primary" size="sm" outline
                                onClick={() => {
                                  setShowSelectCarpetModal(true);
                                  setBoothEquipmentIdForSelectCarpet(equipment.id);
                                }}
                              >
                                {equipment.data && eventCarpets.find(c => c.eventCarpet.id === equipment.data) ?
                                <>
                                <img
                                      src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/carpet/"+  eventCarpets.find(c => c.eventCarpet.id === equipment.data).eventCarpet.image.fileName}
                                      className=""
                                      style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                      alt="color_carpet"
                                  />
                                 <span className="ms-2">{t("Change")}</span>
                                </>
                                :
                                  <><MDBIcon fas icon="palette" /> {t("Select color")}</>
                                }
                                
                                </MDBBtn>
                              }
                            </td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
              }
        </MDBCardBody>
</MDBCard>
{!isCoExhibitorBooth &&
<MDBCard className="mt-2 mt-md-4">
        <MDBCardBody>
            <MDBTypography tag="h5">{t("Included in the Booth")}</MDBTypography>
            <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th>{t("Product")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {booth?.orderLineItem?.product?.includes?.map((includedProduct, index) => (
                        <tr key={index}>
                            <td>{includedProduct.text}</td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
        </MDBCardBody>
</MDBCard>
}

<MDBModal open={showBookedProductsModal} onClose={() => setShowBookedProductsModal(false)}>
  <MDBModalDialog centered size="xl">
    <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t('Book Product')}</MDBModalTitle>
        </MDBModalHeader>
        <MDBCardBody>
          {productTypes.map((type, index) => (
            <>
            <MDBTypography className="mt-3 mt-xl-4 ms-lg-3" tag="h5">{t(type.name)}</MDBTypography>
            <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th>{t("Product")}</th>
                        <th></th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {products.filter(p => p.product.subType === type.type)
                    
                    .sort((a, b) => a.product.name.find(t => t.language === i18n.language)?.text.localeCompare(b.product.name.find(t => t.language === i18n.language)?.text, undefined, { numeric: true }))
                    .map((eventProduct, index) => (
                        <tr key={index}>
                            <td>
                            {eventProduct.product.image?.main?.fileName ?
                                  <img
                                      src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+  eventProduct.product.image.main.fileName}
                                      className="me-2"
                                      style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                      alt={eventProduct.product.image.alt}
                                  />
                              :
                                  <MDBIcon className="me-2" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon={eventProduct.product.icon} />
                                          
                              }
                              {getLocalizedText(eventProduct.product.name)}
                              <MDBBtn color='tertiary' rippleColor='light' size="sm" className="ms-2" style={{verticalAlign: "middle"}} onClick={() => openProductDetails(eventProduct.product)}>{t("Details")}</MDBBtn>
                              </td>
                            <td>
                            <div  className="float-end">
                            <MDBInputGroup style={{maxWidth: "100px"}}>
                                <MDBBtn color="primary" className="ps-2 pt-1" outline size="sm"
                                  onClick={() => decreaseQty(eventProduct.product)}
                                ><MDBIcon fas icon="minus" /></MDBBtn>
                                  <MDBInput value={selectedProducts.find(p => p.id === eventProduct.product.id)?.qty || 0} />
                                  <MDBBtn color="primary" className="ps-2 pt-1" outline size="sm"
                                  onClick={() => eventProduct.stock - (selectedProducts.find(p => p.id === eventProduct.product.id)?.qty || 0 ) > 0 && increaseQty(eventProduct.product)}
                                  ><MDBIcon fas icon="plus" /></MDBBtn>
                                </MDBInputGroup>
                                {eventProduct.stock < 1 ?
                                <span className="text-danger">{t("Out of stock")}</span>
                                : eventProduct.stock < 3 ?
                                <span className="text-warning">{t("In stock")}: {eventProduct.stock}</span>
                                : <span className="text-success">{t("In stock")}: {eventProduct.stock}</span>
                                }
                            </div>
                               
                                <span className="float-end me-3 mt-2">{transformMoney(eventProduct.price)}</span>
                            </td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
            </>
          ))}


        </MDBCardBody>
        <MDBModalFooter className="sticky-bottom bg-white">
            <MDBBtn color="secondary" outline size="sm" onClick={() => setShowBookedProductsModal(false)}>{t("Close")}</MDBBtn>
            <MDBBtn color="primary" style={{width: "200px"}} onClick={handleInitBooking}>{t("Book")}</MDBBtn>
        </MDBModalFooter>
    </MDBModalContent>
  </MDBModalDialog>
</MDBModal>

<MDBModal open={showAddMarketingProductModal} onClose={() => setShowAddMarketingProductModal(false)}>
  <MDBModalDialog centered size="xl">
    <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t('Book Marketing Product')}</MDBModalTitle>
        </MDBModalHeader>
        <MDBCardBody>
        <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th>{t("Product")}</th>
                        <th></th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {products
                    .sort((a, b) => a.product.name.find(t => t.language === i18n.language)?.text.localeCompare(b.product.name.find(t => t.language === i18n.language)?.text, undefined, { numeric: true }))
                    .filter(p => p.product.type === "marketing_booth").map((eventProduct, index) => (
                        <tr key={index}>
                            <td>
                            {eventProduct.product.image?.main?.fileName ?
                                  <img
                                      src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+  eventProduct.product.image.main.fileName}
                                      className="me-2"
                                      style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                      alt={eventProduct.product.image.alt}
                                  />
                              :
                                  <MDBIcon className="me-2" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon={eventProduct.product.icon} />
                                          
                              }
                              {getLocalizedText(eventProduct.product.name)}
                              <MDBBtn color='tertiary' rippleColor='light' size="sm" className="ms-2" style={{verticalAlign: "middle"}} onClick={() => openProductDetails(eventProduct.product)}>{t("Details")}</MDBBtn>
                              </td>
                            <td>
                            <div  className="float-end">
                                <MDBInputGroup style={{maxWidth: "100px"}}>
                                <MDBBtn color="primary" className="ps-2 pt-1" outline size="sm"
                                  onClick={() => decreaseQty(eventProduct.product)}
                                ><MDBIcon fas icon="minus" /></MDBBtn>
                                  <MDBInput value={selectedProducts.find(p => p.id === eventProduct.product.id)?.qty || 0} />
                                  <MDBBtn color="primary" className="ps-2 pt-1" outline size="sm"
                                  onClick={() => eventProduct.stock - (selectedProducts.find(p => p.id === eventProduct.product.id)?.qty || 0 ) > 0 && increaseQty(eventProduct.product)}
                                  ><MDBIcon fas icon="plus" /></MDBBtn>
                                </MDBInputGroup>
                                {eventProduct.stock < 1 ?
                                <span className="text-danger">{t("Out of stock")}</span>
                                : eventProduct.stock < 3 ?
                                <span className="text-warning">{t("In stock")}: {eventProduct.stock}</span>
                                : <span className="text-success">{t("In stock")}: {eventProduct.stock}</span>
                                }
                            </div>
                                <span className="float-end me-3 mt-2">{transformMoney(eventProduct.price)}</span>
                            </td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
        </MDBCardBody>
        <MDBModalFooter className="sticky-bottom bg-white">
            <MDBBtn color="secondary" outline size="sm" onClick={() => setShowAddMarketingProductModal(false)}>{t("Close")}</MDBBtn>
            <MDBBtn color="primary" style={{width: "200px"}} onClick={handleInitBooking}>{t("Book")}</MDBBtn>
        </MDBModalFooter>
    </MDBModalContent>
  </MDBModalDialog>
</MDBModal>

<MDBModal open={showSelectCarpetModal} onClose={() => setShowSelectCarpetModal(false)}>
  <MDBModalDialog centered size="xl">
    <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t('Select Carpet')}</MDBModalTitle>
        </MDBModalHeader>
        <MDBCardBody className="p-2 p-lg-3">
            <MDBRow>
                {eventCarpets
                .sort((a, b) => a.eventCarpet.order - b.eventCarpet.order)
                .map((eventCarpet, index) => (
                    <MDBCol key={index} size="4" md="3" xl="2" className="mb-4">
                        <MDBCard>
                            <img
                                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/carpet/"+  eventCarpet.eventCarpet.image.fileName}
                                className="img-fluid"
                                alt="color_carpet"
                            />
                            <MDBCardBody>
                                <MDBTypography tag="h6">{getLocalizedText(eventCarpet.eventCarpet.name)}</MDBTypography>
                                <MDBTypography tag="p">{getLocalizedText(eventCarpet.eventCarpet.description)}</MDBTypography>
                                <MDBBtn color="primary" onClick={() => {
                                    updateBoothEquipment(boothEquipmentIdForSelectCarpet, eventCarpet.eventCarpet.id);
                                    setShowSelectCarpetModal(false);
                                }}>{t("Select")}</MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                ))}
            </MDBRow>
        </MDBCardBody>


      
    </MDBModalContent>
  </MDBModalDialog>
</MDBModal>

<MDBModal open={showBoothDetails} onClose={() => setShowBoothDetails(false)}>
    <MDBModalDialog scrollable size="xl">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Product Details")}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>{getLocalizedText(booth?.orderLineItem?.product?.name)}</MDBCardTitle>
                        <MDBCardText>{getLocalizedText(booth?.orderLineItem?.product?.description)}</MDBCardText>
                        <MDBCardImage src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+booth?.orderLineItem?.product?.image?.main?.fileName} alt={booth?.orderLineItem?.product?.image?.main?.alt} position='top' />
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>

<MDBModal open={showUploadDisplayFileModal} onClose={() => setShowUploadDisplayFileModal(false)}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Upload File")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => {
                    setDisplayFileUploadStatus({});
                    setBoothEquipmentForUploadDisplayFile(null);
                    inputFieldRef.current.value = "";
                    setShowUploadDisplayFileModal(false);
                }}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
                {/* <h6>
                   
                  {boothEquipmentForUploadDisplayFile?.orderLineItem.product.name.find(t => t.language === i18n.language)?.text || boothEquipmentForUploadDisplayFile?.orderLineItem.product.name.find(t => t.language === i18n.language)?.[0]?.text}
                </h6> */}
               <div className="text-start mb-3">
               {boothEquipmentForUploadDisplayFile && parse(getLocalizedText(boothEquipmentForUploadDisplayFile?.orderLineItem.product.description))}
               </div>
                <MDBRow>
                    <MDBCol size="11">
                        <MDBInput
                        type="file" 
                        onChange={e => uploadFile(e.target.files[0], boothEquipmentForUploadDisplayFile?.id)}
                        ref={inputFieldRef}
                        />
                    </MDBCol>
                    <MDBCol size="1">
                        <MDBIcon icon="trash" color="danger" className="mt-2" style={{cursor: "pointer"}} onClick={() => updateBoothEquipment(boothEquipmentForUploadDisplayFile.id, "")} />
                    </MDBCol>

                </MDBRow>
                {
                    bookedProducts.find(p => p.id === boothEquipmentForUploadDisplayFile?.id)?.data &&
                    <>
                    <span className="text-success">{t("Uploaded successfully")}</span>
                    <br />
                    <MDBBtn className="mt-2" color="primary" size="sm" onClick={() => downloadFile(bookedProducts.find(p => p.id === boothEquipmentForUploadDisplayFile?.id)?.data)}>{t("Download")}</MDBBtn>
                    </>
                    

                }
               
               
                {displayFileUploadStatus[boothEquipmentForUploadDisplayFile?.id] && <>
                    <MDBProgress height="20" className="mt-4">
                        <MDBProgressBar striped bgColor='success' width={displayFileUploadStatus[boothEquipmentForUploadDisplayFile.id].percent} valuemin={0} valuemax={100}>
                            
                        </MDBProgressBar>
                    </MDBProgress>
                    <p>
                        {t("Uploaded")}: {displayFileUploadStatus[boothEquipmentForUploadDisplayFile.id].percent}% ({displayFileUploadStatus[boothEquipmentForUploadDisplayFile.id].speed})
                    </p>
                </>}
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>

<MDBModal open={showProductDetailsModal} onClose={() => setShowProductDetailsModal(false)}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Product Details")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => setShowProductDetailsModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow>
                    
                      {selectedProduct?.image?.main?.fileName &&
                      <MDBCol size="12" md="6">
                        <img
                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+  selectedProduct?.image?.main?.fileName}
                            className="img-fluid"
                            alt={selectedProduct?.image?.alt}
                        />
                        </MDBCol>
                      }
                    
                    <MDBCol size="12" md={selectedProduct?.image?.main?.fileName ? "6" : "12"}>
                        <MDBTypography tag="h5">{getLocalizedText(selectedProduct?.name)}</MDBTypography>
                        <MDBTypography tag="p">{selectedProduct?.description && parse(getLocalizedText(selectedProduct?.description))}</MDBTypography>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
  )
}
