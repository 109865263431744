import React from 'react';
import { MDBRow, MDBCol, MDBTypography, MDBListGroup, MDBListGroupItem, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useBooths } from './hooks/useBooths';
import { transformMoney } from '../../../utils/money';
import { getLocalizedText } from "../../../utils/localizedText";

const BoothTab = ({ eventId }) => {
  const { t, i18n } = useTranslation();
  const { 
    isLoading,
    eventBooths,
    booths,
    addBoothToEvent,
    removeBoothFromEvent
  } = useBooths(eventId);


  if (isLoading) {
    return <div className="text-center">{t("Loading")}</div>;
  }

  // Filter out booths that are already selected
  const availableBooths = booths.filter(booth => 
    !eventBooths.some(eb => eb.product.id === booth.id)
  );

  return (
    <MDBRow className="mb-4">
      <MDBCol size="12" md="6">
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>{t("Available Booths")}</MDBCardTitle>
            <MDBListGroup style={{ maxHeight: '600px', overflowY: 'auto' }}>
              {availableBooths.map((booth) => (
                <MDBListGroupItem key={booth.id} className="d-flex justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{getLocalizedText(booth.name)}</div>
                    <div className="text-muted">{booth.sku}</div>
                    <div>{transformMoney(booth.price)}</div>
                  </div>
                  <MDBBtn 
                    color="primary" 
                    size="sm"
                    onClick={() => addBoothToEvent(booth.id)}
                  >
                    {t("Add")}
                  </MDBBtn>
                </MDBListGroupItem>
              ))}
            </MDBListGroup>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

      <MDBCol size="12" md="6">
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>{t("Selected Booths for Event")}</MDBCardTitle>
            <MDBListGroup style={{ maxHeight: '600px', overflowY: 'auto' }}>
              {eventBooths
              .sort((a, b) => getLocalizedText(a.product.name).localeCompare(getLocalizedText(b.product.name), undefined, { numeric: true }))
              .map((eventBooth) => (
                <MDBListGroupItem key={eventBooth.id} className="d-flex justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{getLocalizedText(eventBooth.product.name)}</div>
                    <div className="text-muted">{eventBooth.product.sku}</div>
                    <div>{transformMoney(eventBooth.product.price)}</div>
                  </div>
                  <MDBBtn 
                    color="danger" 
                    size="sm"
                    onClick={() => removeBoothFromEvent(eventBooth.id)}
                  >
                    {t("Remove")}
                  </MDBBtn>
                </MDBListGroupItem>
              ))}
            </MDBListGroup>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
};

export default BoothTab;