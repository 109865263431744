import { useState, useEffect, useCallback } from 'react';
import { generateClient } from 'aws-amplify/api';
import { v4 as uuidv4 } from 'uuid';

const client = generateClient();

export const useLecture = (lectureId = null, eventId = null) => {
  const [lecture, setLecture] = useState(null);
  const [company, setCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  useEffect(() => {
    if (lectureId) {
      fetchLecture();
    } else {
      setIsLoading(false);
      setLecture({
        status: 'created',
        lengthInSeconds: 1800,
        presentationLanguage: 'DE',
      });
    }
  }, [lectureId]);

  const fetchLecture = async () => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query GetLecture($id: ID!) {
            getLecture(id: $id) {
              id
              type
              title {
                language
                text
              }
              content {
                language
                text
              }
              image {
                fileName
                alt
              }
              presentationLanguage
              lengthInSeconds
              status
              company {
                id
                name
                image {
                  main {
                    fileName
                  }
                }
              }
            }
          }
        `,
        variables: { id: lectureId }
      });
      
      setLecture(response.data.getLecture);
      setCompany(response.data.getLecture.company);
    } catch (error) {
      console.error('Error fetching lecture:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchCompanies = useCallback(async (searchQuery) => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchCompanies(
            $filter: SearchableCompanyFilterInput
            $sort: [SearchableCompanySortInput]
            $limit: Int
          ) {
            searchCompanies(
              filter: $filter
              sort: $sort
              limit: $limit
            ) {
              items {
                id
                name
                image {
                  main {
                    fileName
                  }
                }
                status
              }
            }
          }
        `,
        variables: {
          filter: {
            or: [
              { name: { matchPhrasePrefix: searchQuery } }
            ],
            status: { eq: "active" }
          },
          limit: 5
        }
      });

      return response.data.searchCompanies.items;
    } catch (error) {
      console.error('Error searching companies:', error);
      return [];
    }
  }, []);

  const uploadImage = async (file) => {
    if (!file) return null;
    
    setIsUploadingImage(true);
    setUploadError(null);
    
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      const uploadResult = await new Promise((resolve, reject) => {
        reader.onload = async () => {
          try {
            const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
            const response = await client.graphql({
              query: /* GraphQL */ `
                mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
                  uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
                }
              `,
              variables: {
                imageBuffer: base64FileBuffer,
                imageType: "lectureImage"
              }
            });
            
            resolve(response.data.uploadCompanyImage);
          } catch (error) {
            reject(error);
          }
        };
        
        reader.onerror = reject;
      });

      const fileName = uploadResult.replace(/{|}|fileName=/g, '');
      return {
        fileName,
        alt: "Lecture Image"
      };
    } catch (error) {
      setUploadError(error.message);
      return null;
    } finally {
      setIsUploadingImage(false);
    }
  };

  const saveLecture = async (lectureData) => {
    try {
      setIsLoading(true);
      // Prepare base lecture input
      let lectureInput = {
        industryLecturesId: lectureData.industryId,
        conditions: lectureData.conditions,
        ...(lectureData.content ? {content: lectureData.content} : {}),
        subjects: lectureData.tempSubjects?.map(subjectTexts => ({
          id: uuidv4(),
          name: [
            { language: "de", text: subjectTexts.de },
            { language: "en", text: subjectTexts.en }
          ]
        })).filter(Boolean) || lectureData.subjects || null,
        title: lectureData.title,
        type: lectureData.type,
        companyLecturesId: lectureData.companyId,
        presentationLanguage: lectureData.presentationLanguage || "DE",
        ...(lectureId ? {} : { status: "created" }),
        ...(eventId ? { eventLecturesId: eventId } : {}),
      };

      // Add optional fields
      if (lectureData.lectureMainSpeakerId) {
        lectureInput.lectureMainSpeakerId = lectureData.lectureMainSpeakerId;
      }
      if (lectureData.lectureMainSpeakerUserId) {
        lectureInput.lectureMainSpeakerUserId = lectureData.lectureMainSpeakerUserId;
      }
      if (lectureData.image?.fileName) {
        lectureInput.image = lectureData.image;
      }
      if (lectureData.pdfDe?.fileName) {
        lectureInput.pdfDe = lectureData.pdfDe;
      }
      if (lectureData.pdfEn?.fileName) {
        lectureInput.pdfEn = lectureData.pdfEn;
      }

      // If lecture ID exists, add it to the input
      if (lectureId) {
        lectureInput.id = lectureId;
      }

      // Save/update lecture
      const lectureResponse = await client.graphql({
        query: lectureId 
          ? /* GraphQL */ `
            mutation UpdateLecture($input: UpdateLectureInput!) {
              updateLecture(input: $input) {
                id
              }
            }
          `
          : /* GraphQL */ `
            mutation CreateLecture($input: CreateLectureInput!) {
              createLecture(input: $input) {
                id
                speakers {
                  items {
                      employee {
                          image {
                          fileName
                          alt
                          }
                      }
                    }
                }
                mainSpeakerUser {
                  image {
                    fileName
                    alt
                    }
                    firstName
                    lastName
                }
                subjects {
                    id
                    name {
                        language
                        text
                    }
                } 
                topics {
                    items {
                      topic {
                        id
                        topicGroupTopicsId
                        name {
                          language
                          text
                        }
                      }
                    }
                }
                adminTopic {
                  id
                }   
              }
            }
          `,
        variables: { input: lectureInput }
      });

      const savedLectureId = lectureId 
        ? lectureId 
        : lectureResponse.data.createLecture.id;

      // Handle Speakers
      if (lectureData.speakers && lectureData.speakers.length > 0) {
        await updateLectureSpeakers(lectureData.speakers, savedLectureId);
      }

      // Handle Speaker Users
      if (lectureData.speakerUsers && lectureData.speakerUsers.length > 0) {
        await updateLectureSpeakerUsers(lectureData.speakerUsers, savedLectureId);
      }

      // Handle Topics
      if (lectureData.topics && lectureData.topics.length > 0) {
        await updateLectureTopics(lectureData.topics, savedLectureId);
      }

      return lectureId ? lectureResponse.data.updateLecture : lectureResponse.data.createLecture;
    } catch (error) {
      console.error('Error saving lecture:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const updateLectureSpeakers = async (speakerIds, lectureId) => {
    try {
      // Fetch existing speakers for this lecture
      const existingSpeakersResponse = await client.graphql({
        query: /* GraphQL */ `
          query ListLecturesSpeakers(
            $filter: ModelLecturesSpeakersFilterInput
            $limit: Int
          ) {
            listLecturesSpeakers(
              filter: $filter
              limit: $limit
            ) {
              items {
                id
                employeeId
              }
            }
          }
        `,
        variables: { 
          filter: { lectureId: { eq: lectureId } },
          limit: 1000
        }
      });

      const existingSpeakers = existingSpeakersResponse.data.listLecturesSpeakers.items;
      const existingSpeakerIds = existingSpeakers.map(s => s.employeeId);

      // Identify speakers to add and remove
      const speakersToAdd = speakerIds.filter(id => !existingSpeakerIds.includes(id));
      const speakersToRemove = existingSpeakers.filter(s => !speakerIds.includes(s.employeeId));

      // Add new speakers
      await Promise.all(speakersToAdd.map(async (speakerId) => {
        await client.graphql({
          query: /* GraphQL */ `
            mutation CreateLecturesSpeakers(
              $input: CreateLecturesSpeakersInput!
            ) {
              createLecturesSpeakers(input: $input) {
                id
              }
            }
          `,
          variables: { 
            input: { 
              lectureId: lectureId, 
              employeeId: speakerId 
            } 
          }
        });
      }));

      // Remove old speakers
      await Promise.all(speakersToRemove.map(async (speaker) => {
        await client.graphql({
          query: /* GraphQL */ `
            mutation DeleteLecturesSpeakers(
              $input: DeleteLecturesSpeakersInput!
            ) {
              deleteLecturesSpeakers(input: $input) {
                id
              }
            }
          `,
          variables: { 
            input: { 
              id: speaker.id 
            } 
          }
        });
      }));
    } catch (error) {
      console.error('Error updating lecture speakers:', error);
      throw error;
    }
  };

    const updateLectureSpeakerUsers = async (speakerUsers, lectureId) => {
    try {
        // Fetch existing speakers for this lecture
        const existingSpeakersResponse = await client.graphql({
            query: /* GraphQL */ `
            query ListLecturesSpeakerUsers(
                $filter: ModelLecturesSpeakerUsersFilterInput
                $limit: Int
            ) {
                listLecturesSpeakerUsers(
                filter: $filter
                limit: $limit
                ) {
                    items {
                        id
                        userId
                    }
                }
            }
            `,
            variables: {
            filter: { lectureId: { eq: lectureId } },
            limit: 1000
            }
        });

        const existingSpeakers = existingSpeakersResponse.data.listLecturesSpeakerUsers.items;
        const existingSpeakerIds = existingSpeakers.map(s => s.userId);

        // Identify speakers to add and remove
        const speakersToAdd = speakerUsers.filter(id => !existingSpeakerIds.includes(id));
        const speakersToRemove = existingSpeakers.filter(s => !speakerUsers.includes(s.userId));
        
        // Add new speakers
        await Promise.all(speakersToAdd.map(async (speakerId) => {
            await client.graphql({
            query: /* GraphQL */ `
                mutation CreateLecturesSpeakerUsers(
                $input: CreateLecturesSpeakerUsersInput!
                ) {
                createLecturesSpeakerUsers(input: $input) {
                    id
                }
                }
            `,
            variables: {
                input: {
                lectureId: lectureId,
                userId: speakerId
                }

            }
            });
        }));

        // Remove old speakers
        await Promise.all(speakersToRemove.map(async (speaker) => {
            await client.graphql({
            query: /* GraphQL */ `
                mutation DeleteLecturesSpeakerUsers(
                $input: DeleteLecturesSpeakerUsersInput!
                ) {
                deleteLecturesSpeakerUsers(input: $input) {
                    id
                }
                }
            `,
            variables: {
                input: {
                id: speaker.id
                }
            }
            });
        }
        ));
    }
    catch (error) {
        console.error('Error updating lecture speakers:', error);
        throw error;
    }
    }




  const updateLectureTopics = async (topicIds, lectureId) => {
    try {
      // Fetch existing topics for this lecture
      const existingTopicsResponse = await client.graphql({
        query: /* GraphQL */ `
          query ListLecturesTopics(
            $filter: ModelLecturesTopicsFilterInput
            $limit: Int
          ) {
            listLecturesTopics(
              filter: $filter
              limit: $limit
            ) {
              items {
                id
                topicId
              }
            }
          }
        `,
        variables: { 
          filter: { lectureId: { eq: lectureId } },
          limit: 1000
        }
      });

      const existingTopics = existingTopicsResponse.data.listLecturesTopics.items;
      const existingTopicIds = existingTopics.map(t => t.topicId);

      // Identify topics to add and remove
      const topicsToAdd = topicIds.filter(id => !existingTopicIds.includes(id));
      const topicsToRemove = existingTopics.filter(t => !topicIds.includes(t.topicId));

      // Add new topics
      await Promise.all(topicsToAdd.map(async (topicId) => {
        await client.graphql({
          query: /* GraphQL */ `
            mutation CreateLecturesTopics(
              $input: CreateLecturesTopicsInput!
            ) {
              createLecturesTopics(input: $input) {
                id
              }
            }
          `,
          variables: { 
            input: { 
              lectureId: lectureId, 
              topicId: topicId 
            } 
          }
        });
      }));

      // Remove old topics
      await Promise.all(topicsToRemove.map(async (topic) => {
        await client.graphql({
          query: /* GraphQL */ `
            mutation DeleteLecturesTopics(
              $input: DeleteLecturesTopicsInput!
            ) {
              deleteLecturesTopics(input: $input) {
                id
              }
            }
          `,
          variables: { 
            input: { 
              id: topic.id 
            } 
          }
        });
      }));
    } catch (error) {
      console.error('Error updating lecture topics:', error);
      throw error;
    }
  };

  return {
    lecture,
    setLecture,
    company,
    companies,
    searchCompanies,
    isLoading,
    isUploadingImage,
    uploadError,
    saveLecture,
    uploadImage
  };
};