import React, { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  MDBCard, 
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBSpinner,
  MDBTextArea,
  MDBCheckbox,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink
} from 'mdb-react-ui-kit';
import { getLocalizedText } from '../../utils/localizedText';
import countryList from 'react-select-country-list';
import { transformMoney } from '../../utils/money';
import { fetchAuthSession } from "aws-amplify/auth";

async function getIdToken() {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  console.log("idToken", idToken)
  return idToken;
}

const client = generateClient();

const ITEMS_PER_PAGE = 10;

const CreateOrder = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);

  // Form state
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companySearch, setCompanySearch] = useState('');
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [customerType, setCustomerType] = useState('business');

  // Address state
  const [billingAddress, setBillingAddress] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [useProfileAddressForBilling, setUseProfileAddressForBilling] = useState(true);
  const [vatInfo, setVatInfo] = useState({ vatNumber: '' });
  const [buyerOrderNumber, setBuyerOrderNumber] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [notes, setNotes] = useState('');

  // Products state
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [productSearch, setProductSearch] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [taxRate, setTaxRate] = useState(19);

  // Connection state
  const [showEventModal, setShowEventModal] = useState(false);
  const [showBoothModal, setShowBoothModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [booths, setBooths] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedBooth, setSelectedBooth] = useState(null);
  const [eventSearch, setEventSearch] = useState('');
  const [boothSearch, setBoothSearch] = useState('');

   // Pagination state
   const [productPage, setProductPage] = useState(1);
   const [productTotalPages, setProductTotalPages] = useState(0);
   const [boothPage, setBoothPage] = useState(1);
   const [boothTotalPages, setBoothTotalPages] = useState(0);
   const [isLoadingProducts, setIsLoadingProducts] = useState(false);
   const [isLoadingBooths, setIsLoadingBooths] = useState(false);

  // Load initial data
  useEffect(() => {
    fetchIndustries();
  }, []);

  const fetchIndustries = async () => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query ListIndustries {
            listIndustries {
              items {
                id
                name {
                  language
                  text
                }
              }
            }
          }
        `
      });
      setIndustries(response.data.listIndustries.items);
      setSelectedIndustry(response.data.listIndustries.items[0].id);
    } catch (error) {
      console.error('Error fetching industries:', error);
    }
  };

  // Search companies
  useEffect(() => {
    if (companySearch.length > 2) {
      searchCompanies();
    }
  }, [companySearch]);

  const searchCompanies = async () => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchCompanies(
            $filter: SearchableCompanyFilterInput
          ) {
            searchCompanies(
              filter: $filter
            ) {
              items {
                id
                name
                billingContact {
                  email
                  address {
                    street
                    streetNumber
                    zip
                    city
                    countryCode
                  }
                }
              }
            }
          }
        `,
        variables: {
            filter: {
                name: { regexp: `.*${companySearch.toLowerCase()}.*` } 
            }
        }
      });
      setCompanies(response.data.searchCompanies.items);
    } catch (error) {
      console.error('Error searching companies:', error);
    }
  };

  // Load employees when company is selected
  useEffect(() => {
    if (selectedCompany) {
      fetchEmployees();
      // Auto-fill billing address
      if (selectedCompany.billingContact) {
        setBillingAddress({
          ...selectedCompany.billingContact.address,
          company: selectedCompany.name
        });
        setBillingEmail(selectedCompany.billingContact.email || '');
      }
    }
  }, [selectedCompany]);

  const fetchEmployees = async () => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query GetCompany($id: ID!) {
            getCompany(id: $id) {
              adminEmployees {
                items {
                  user {
                    id
                    firstName
                    lastName
                    contact {
                      address {
                        street
                        streetNumber
                        zip
                        city
                        countryCode
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { id: selectedCompany.id }
      });
      setEmployees(response.data.getCompany.adminEmployees.items);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const loadProducts = async (page = 1) => {
    setIsLoadingProducts(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchProducts(
            $filter: SearchableProductFilterInput
            $sort: [SearchableProductSortInput]
            $limit: Int
            $nextToken: String
            $from: Int
          ) {
            searchProducts(
              filter: $filter
              sort: $sort
              limit: $limit
              nextToken: $nextToken
              from: $from
            ) {
              items {
                id
                sku
                name_text
                name {
                  language
                  text
                }
                price
                type
              }
              total
            }
          }
        `,
        variables: {
          limit: ITEMS_PER_PAGE,
          from: (page - 1) * ITEMS_PER_PAGE,
          filter: productSearch ? {
            or: {
              sku: { match: `${productSearch}*` },
              name_text: { wildcard: `${productSearch}*` }
            }
             
          } : null
        }
      });
      
      setProducts(response.data.searchProducts.items);
      setProductTotalPages(Math.ceil(response.data.searchProducts.total / ITEMS_PER_PAGE));
    } catch (error) {
      console.error('Error loading products:', error);
    } finally {
      setIsLoadingProducts(false);
    }
  };

  // Filter products based on search
  useEffect(() => {
    if (products.length > 0) {
      const filtered = products.filter(product => {
        const localizedName = getLocalizedText(product.name);
        return (
          localizedName.toLowerCase().includes(productSearch.toLowerCase()) ||
          product.sku.toLowerCase().includes(productSearch.toLowerCase())
        );
      });
      setFilteredProducts(filtered);
    }
  }, [productSearch, products]);

  const addProduct = (product) => {
    setSelectedProducts([...selectedProducts, {
      ...product,
      qty: 1,
      price: product.price,
      taxRate
    }]);
    setShowAddProductModal(false);
  };

  const removeProduct = (productId) => {
    setSelectedProducts(selectedProducts.filter(p => p.id !== productId));
  };

  const updateProductQuantity = (productId, qty) => {
    setSelectedProducts(selectedProducts.map(p => 
      p.id === productId ? { ...p, qty: parseInt(qty) } : p
    ));
  };

  const updateProductPrice = (productId, price) => {
    setSelectedProducts(selectedProducts.map(p => 
      p.id === productId ? { ...p, price: parseFloat(price) } : p
    ));
  };

  const calculateTotal = () => {
    return selectedProducts.reduce((total, product) => {
      const productTotal = product.price * product.qty;
      const tax = productTotal * (product.taxRate / 100);
      return total + productTotal + tax;
    }, 0);
  };


  useEffect(() => {
    // Set Tax rate, if customer is business - if country is Germany, set 19% tax rate, otherwise 0%. country is personalAddress.countryCode or billingAddress.countryCode if useProfileAddressForBilling is false
    if(customerType === "business") {
      const country = billingAddress.countryCode;
      if(country === "DE") {
        setTaxRate(19);
      } else {
        setTaxRate(0);
      }
    } else {
      // If customer is private and country is in EU, set 19% tax rate, otherwise 0%
      const country = billingAddress.countryCode;
      const euCountries = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK"];
      if(euCountries.includes(country)) {
        setTaxRate(19);
      } else {
        setTaxRate(0);
      }
    }
  }, [customerType, billingAddress]);

  // Handle order creation
  const createOrder = async () => {
    setIsPlacingOrder(true);
    try {
      const idToken = await getIdToken();

      const productsForOrder = selectedProducts.map(product => ({
        id: product.id,
        qty: product.qty || 1,
        price: product.price,
        discount: 0,
        discountType: "",
        taxRate
      }));

      const totalAmount = selectedProducts.reduce((acc, product) => 
        acc + (product.qty * product.price), 0);

      let data = {};
      if (selectedEvent) {
        data.eventId = selectedEvent.id;
      }
      if (selectedBooth) {
        data.eventBoothId = selectedBooth.id;
      }

      const orderPayload = {
        isAdminOrder: true,
        industryOrdersId: selectedIndustry,
        companyOrdersId: selectedCompany?.id,
        userOrdersId: selectedEmployee?.user?.id,
        buyerOrderNumber: buyerOrderNumber || null,
        notes,
        deliveryAddress: useProfileAddressForBilling ? billingAddress : shippingAddress,
        billingAddress,
        billingEmail,
        products: productsForOrder,
        isBusiness: customerType === "business",
        vatInfo: vatInfo.vatNumber ? vatInfo : null,
        currency: "EUR",
        totalAmount: totalAmount + (totalAmount * taxRate / 100),
        invoiceAmount: totalAmount + (totalAmount * taxRate / 100),
        invoiceAmountNet: totalAmount,
        taxRate,
        paymentProvider: "invoice",
        ...(Object.keys(data).length > 0 && { data: JSON.stringify(data) })
      };

      const response = await fetch('https://y3vayhw2i5bqrm6fpwqz7fzfwe.appsync-api.eu-central-1.amazonaws.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': idToken
        },
        body: JSON.stringify({
          query: /* GraphQL */ `
            mutation PlaceOrder(
              $order: String
            ) {
              placeOrder(
                order: $order
              )
            }
          `,
          variables: {
            order: JSON.stringify(orderPayload)
          }
        })
      });

      const responseData = await response.json();
      if (responseData.errors) {
        throw new Error(responseData.errors[0].message);
      }

      // Navigate to orders page after successful creation
      navigate('/admin/orders');
    } catch (error) {
      console.error('Error creating order:', error);
      // Implement error handling/user notification here
    } finally {
      setIsPlacingOrder(false);
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query ListEvents {
            listEvents {
              items {
                id
                name {
                  language
                  text
                }
              }
            }
          }
        `
      });
      setEvents(response.data.listEvents.items);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };
  
  const fetchBooths = async (page = 1) => {
    setIsLoadingBooths(true);
    try {
      // First, search for companies matching the search term
      let matchingCompanyIds = [];
      if (boothSearch) {
        const companiesResponse = await client.graphql({
          query: /* GraphQL */ `
            query SearchCompanies(
              $filter: SearchableCompanyFilterInput
              $limit: Int
            ) {
              searchCompanies(
                filter: $filter
                limit: $limit
              ) {
                items {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            filter: {
              name: {
                matchPhrasePrefix: boothSearch.toLowerCase()
              }
            },
            limit: 100
          }
        });
        matchingCompanyIds = companiesResponse.data.searchCompanies.items.map(company => company.id);
      }

      // Then search for booths with the matching company IDs
      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchBookedBooths(
            $filter: SearchableBookedBoothFilterInput
            $sort: [SearchableBookedBoothSortInput]
            $limit: Int
            $nextToken: String
            $from: Int
          ) {
            searchBookedBooths(
              filter: $filter
              sort: $sort
              limit: $limit
              nextToken: $nextToken
              from: $from
            ) {
              items {
                id
                company {
                  id
                  name
                }
                orderLineItem {
                  product {
                    name {
                      language
                      text
                    }
                  }
                }
              }
              total
            }
          }
        `,
        variables: {
          limit: ITEMS_PER_PAGE,
          from: (page - 1) * ITEMS_PER_PAGE,
          filter: boothSearch && matchingCompanyIds.length > 0 ? {
            or: matchingCompanyIds.map(companyId => ({
              companyBookedBoothsId: {
                eq: companyId
              }
            }))
          } : null
        }
      });

      
      setBooths(response.data.searchBookedBooths.items);
      setBoothTotalPages(Math.ceil(response.data.searchBookedBooths.total / ITEMS_PER_PAGE));
    } catch (error) {
      console.error('Error loading booths:', error);
    } finally {
      setIsLoadingBooths(false);
    }
  };

  useEffect(() => {
    if (showAddProductModal) {
      loadProducts(productPage);
    }
  }, [productPage, productSearch, showAddProductModal]);

  useEffect(() => {
    if (showBoothModal) {
      fetchBooths(boothPage);
    }
  }, [boothPage, boothSearch, showBoothModal]);

  const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    return (
      <MDBPagination className='mb-0 justify-content-center'>
        <MDBPaginationItem disabled={currentPage === 1}>
          <MDBPaginationLink onClick={() => onPageChange(currentPage - 1)}>
            {t('Previous')}
          </MDBPaginationLink>
        </MDBPaginationItem>

        {[...Array(totalPages)].map((_, index) => (
          <MDBPaginationItem key={index + 1} active={currentPage === index + 1}>
            <MDBPaginationLink onClick={() => onPageChange(index + 1)}>
              {index + 1}
            </MDBPaginationLink>
          </MDBPaginationItem>
        ))}

        <MDBPaginationItem disabled={currentPage === totalPages}>
          <MDBPaginationLink onClick={() => onPageChange(currentPage + 1)}>
            {t('Next')}
          </MDBPaginationLink>
        </MDBPaginationItem>
      </MDBPagination>
    );
  };

  const [showUserModal, setShowUserModal] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [userPage, setUserPage] = useState(1);
  const [userTotalPages, setUserTotalPages] = useState(0);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const loadUsers = async (page = 1) => {
    setIsLoadingUsers(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchUsers(
            $filter: SearchableUserFilterInput
            $sort: [SearchableUserSortInput]
            $limit: Int
            $nextToken: String
            $from: Int
          ) {
            searchUsers(
              filter: $filter
              sort: $sort
              limit: $limit
              nextToken: $nextToken
              from: $from
            ) {
              items {
                id
                firstName
                lastName
                email
                image {
                  fileName
                }
              }
              total
            }
          }
        `,
        variables: {
          limit: ITEMS_PER_PAGE,
          from: (page - 1) * ITEMS_PER_PAGE,
          filter: userSearch ? {
            or: [
              { firstName: { wildcard: `${userSearch}*` } },
              { lastName: { wildcard: `${userSearch}*` } },
              { email: { wildcard: `${userSearch}*` } }
            ]
          } : null
        }
      });
      
      setUsers(response.data.searchUsers.items);
      setUserTotalPages(Math.ceil(response.data.searchUsers.total / ITEMS_PER_PAGE));
    } catch (error) {
      console.error('Error loading users:', error);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  useEffect(() => {
    if (showUserModal) {
      loadUsers(userPage);
    }
  }, [userPage, userSearch, showUserModal]);


  return (
    <>
    <MDBCard>
      <MDBCardBody>
        <h4>{t('Create New Order')}</h4>
        
        {/* Industry Selection */}
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <MDBSelect
              label={t('Select Industry')}
              data={industries.map(industry => ({
                text: getLocalizedText(industry.name),
                value: industry.id
              }))}
              value={selectedIndustry}
              onChange={(value) => setSelectedIndustry(value.value)}
            />
          </MDBCol>
        </MDBRow>

        {/* Company Selection */}
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <MDBInput
              label={t('Search Company')}
              value={companySearch}
              onChange={(e) => setCompanySearch(e.target.value)}
            />
            {companies.length > 0 && (
              <div className="mt-2">
                {companies.map(company => (
                  <div
                    key={company.id}
                    className="p-2 border rounded mb-2 cursor-pointer"
                    onClick={() => setSelectedCompany(company)}
                  >
                    {company.name}
                  </div>
                ))}
              </div>
            )}
          </MDBCol>
        </MDBRow>

        {/* <MDBRow className="mb-4">
            <MDBCol md="6">
              <MDBBtn color="primary" onClick={() => setShowUserModal(true)}>
                {t('Select User')}
              </MDBBtn>
              {selectedUser && (
                <div className="mt-2 p-2 border rounded">
                  {selectedUser.firstName} {selectedUser.lastName} ({selectedUser.email})
                  <MDBIcon 
                    fas 
                    icon="times" 
                    className="float-end cursor-pointer" 
                    onClick={() => setSelectedUser(null)} 
                  />
                </div>
              )}
            </MDBCol>
          </MDBRow> */}

        {/* Employee Selection */}
        {selectedCompany && (
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <MDBSelect
                label={t('Select Employee')}
                data={employees.map(emp => ({
                  text: `${emp.user.firstName} ${emp.user.lastName}`,
                  value: emp.user.id
                }))}
                value={selectedEmployee?.user?.id}
                onChange={(value) => {
                  const employee = employees.find(emp => emp.user.id === value.value);
                  setSelectedEmployee(employee);
                  if (employee?.user?.contact?.address) {
                    setShippingAddress(employee.user.contact.address);
                  }
                }}
              />
            </MDBCol>
          </MDBRow>
        )}

        {/* Customer Type */}
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <MDBSelect
              label={t('Customer Type')}
              data={[
                { text: t('Business'), value: 'business' },
                { text: t('Consumer'), value: 'consumer' }
              ]}
              value={customerType}
              onChange={(value) => setCustomerType(value.value)}
            />
          </MDBCol>
        </MDBRow>

        {/* Address Section */}
<h5 className="mb-3">{t('Addresses')}</h5>

<div className="mb-4 text-start">
  <MDBCheckbox 
    label={t("Use the same address for billing")} 
    checked={useProfileAddressForBilling} 
    onChange={(e) => {
      setUseProfileAddressForBilling(e.target.checked);
      if (!e.target.checked) {
        setShippingAddress({ ...shippingAddress, countryCode: billingAddress.countryCode });
      }
    }} 
  />
</div>

<MDBRow>
  {/* Billing Address */}
  <MDBCol md="6">
    <h6>{t('Billing Address')}</h6>
    <MDBRow className="mb-2">
      <MDBCol size="12">
        <MDBInput 
          label={t("Company")} 
          value={billingAddress?.company || ""} 
          onChange={(e) => setBillingAddress({ ...billingAddress, company: e.target.value })}
        />
      </MDBCol>
    </MDBRow>

    <MDBRow className="mb-2">
      <MDBCol size="6">
        <MDBInput 
          label={t("First Name")} 
          value={billingAddress?.firstName || ""} 
          onChange={(e) => setBillingAddress({ ...billingAddress, firstName: e.target.value })}
        />
      </MDBCol>
      <MDBCol size="6">
        <MDBInput 
          label={t("Last Name")} 
          value={billingAddress?.lastName || ""} 
          onChange={(e) => setBillingAddress({ ...billingAddress, lastName: e.target.value })}
        />
      </MDBCol>
    </MDBRow>

    <MDBRow className="mb-2">
      <MDBCol size="8">
        <MDBInput 
          label={t("Street")} 
          value={billingAddress?.street || ""} 
          onChange={(e) => setBillingAddress({ ...billingAddress, street: e.target.value })}
        />
      </MDBCol>
      <MDBCol size="4">
        <MDBInput 
          label={t("Number")} 
          value={billingAddress?.streetNumber || ""} 
          onChange={(e) => setBillingAddress({ ...billingAddress, streetNumber: e.target.value })}
        />
      </MDBCol>
    </MDBRow>

    <MDBRow className="mb-2">
      <MDBCol size="4">
        <MDBInput 
          label={t("ZIP")} 
          value={billingAddress?.zip || ""} 
          onChange={(e) => setBillingAddress({ ...billingAddress, zip: e.target.value })}
        />
      </MDBCol>
      <MDBCol size="8">
        <MDBInput 
          label={t("City")} 
          value={billingAddress?.city || ""} 
          onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })}
        />
      </MDBCol>
    </MDBRow>

    <MDBRow className="mb-2">
      <MDBCol>
        <MDBSelect
          search
          data={[
            {text: t("Select"), value: "", disabled: true, defaultSelected:true},
            ...countryList().getData().map(c => ({text: t(c.label), value: c.value})).sort((a, b) => {
              const order = ['DE', 'AT', 'CH'];
              const indexA = order.indexOf(a.value);
              const indexB = order.indexOf(b.value);
              if (indexA > -1 && indexB > -1) {
                return indexA - indexB;
              } else if (indexA > -1) {
                return -1;
              } else if (indexB > -1) {
                return 1;
              }
              return a.value.localeCompare(b.value);
            })
          ]}
          value={billingAddress?.countryCode || ""}
          size="sm"
          label={t("Country")}
          onChange={(e) => setBillingAddress({ ...billingAddress, countryCode: e?.value })}
        />
      </MDBCol>
    </MDBRow>

    {customerType === 'business' && (
      <MDBRow className="mb-2">
        <MDBCol>
          <MDBInput 
            label={t("VAT ID")} 
            value={vatInfo?.vatNumber || ""} 
            onChange={(e) => setVatInfo({ ...vatInfo, vatNumber: e.target.value })}
          />
        </MDBCol>
      </MDBRow>
    )}

    <MDBRow className="mb-2">
      <MDBCol>
        <MDBInput 
          label={t("Billing Email")} 
          value={billingEmail} 
          onChange={(e) => setBillingEmail(e.target.value)}
        />
      </MDBCol>
    </MDBRow>

    {customerType === 'business' && (
      <MDBRow className="mb-2">
        <MDBCol>
          <MDBInput 
            label={t("Order Number")} 
            value={buyerOrderNumber} 
            onChange={(e) => setBuyerOrderNumber(e.target.value)}
          />
        </MDBCol>
      </MDBRow>
    )}
  </MDBCol>

{/* Shipping Address */}
{!useProfileAddressForBilling && (
    <MDBCol md="6">
    <h6>{t('Shipping Address')}</h6>
    <MDBRow className="mb-2">
        <MDBCol size="12">
        <MDBInput 
            label={t("Company")} 
            value={shippingAddress?.company || ""} 
            onChange={(e) => setShippingAddress({ ...shippingAddress, company: e.target.value })}
        />
        </MDBCol>
    </MDBRow>

    <MDBRow className="mb-2">
        <MDBCol size="6">
        <MDBInput 
            label={t("First Name")} 
            value={shippingAddress?.firstName || ""} 
            onChange={(e) => setShippingAddress({ ...shippingAddress, firstName: e.target.value })}
        />
        </MDBCol>
        <MDBCol size="6">
        <MDBInput 
            label={t("Last Name")} 
            value={shippingAddress?.lastName || ""} 
            onChange={(e) => setShippingAddress({ ...shippingAddress, lastName: e.target.value })}
        />
        </MDBCol>
    </MDBRow>

    <MDBRow className="mb-2">
        <MDBCol size="8">
        <MDBInput 
            label={t("Street")} 
            value={shippingAddress?.street || ""} 
            onChange={(e) => setShippingAddress({ ...shippingAddress, street: e.target.value })}
        />
        </MDBCol>
        <MDBCol size="4">
        <MDBInput 
            label={t("Number")} 
            value={shippingAddress?.streetNumber || ""} 
            onChange={(e) => setShippingAddress({ ...shippingAddress, streetNumber: e.target.value })}
        />
        </MDBCol>
    </MDBRow>

    <MDBRow className="mb-2">
        <MDBCol size="4">
        <MDBInput 
            label={t("ZIP")} 
            value={shippingAddress?.zip || ""} 
            onChange={(e) => setShippingAddress({ ...shippingAddress, zip: e.target.value })}
        />
        </MDBCol>
        <MDBCol size="8">
        <MDBInput 
            label={t("City")} 
            value={shippingAddress?.city || ""} 
            onChange={(e) => setShippingAddress({ ...shippingAddress, city: e.target.value })}
        />
        </MDBCol>
    </MDBRow>

    <MDBRow className="mb-2">
        <MDBCol>
        <MDBSelect
            search
            data={[
            {text: t("Select"), value: "", disabled: true, defaultSelected:true},
            ...countryList().getData().map(c => ({text: t(c.label), value: c.value})).sort((a, b) => {
                const order = ['DE', 'AT', 'CH'];
                const indexA = order.indexOf(a.value);
                const indexB = order.indexOf(b.value);
                if (indexA > -1 && indexB > -1) {
                return indexA - indexB;
                } else if (indexA > -1) {
                return -1;
                } else if (indexB > -1) {
                return 1;
                }
                return a.value.localeCompare(b.value);
            })
            ]}
            value={shippingAddress?.countryCode || ""}
            size="sm"
            label={t("Country")}
            onChange={(e) => setShippingAddress({ ...shippingAddress, countryCode: e.value })}
        />
        </MDBCol>
    </MDBRow>
    </MDBCol>
)}
</MDBRow>

        {/* Products */}
        <h5 className="mb-3">{t('Products')}</h5>
        <MDBTable>
          <MDBTableHead>
            <tr>
              <th>{t('SKU')}</th>
              <th>{t('Name')}</th>
              <th>{t('Quantity')}</th>
              <th>{t('Price')}</th>
              <th>{t('Tax Rate')}</th>
              <th>{t('Total')}</th>
              <th></th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {selectedProducts.map(product => (
              <tr key={product.id}>
                <td>{product.sku}</td>
                <td>{getLocalizedText(product.name)}</td>
                <td>
                  <MDBInput
                    type="number"
                    value={product.qty}
                    onChange={(e) => updateProductQuantity(product.id, e.target.value)}
                  />
                </td>
                <td>
                  <MDBInput
                    type="number"
                    value={product.price}
                    onChange={(e) => updateProductPrice(product.id, e.target.value)}
                  />
                </td>
                <td>
                <MDBInput
                    type="number"
                    value={product.taxRate}
                    
                    onChange={(e) => updateProductPrice(product.id, e.target.value)}
                  >
                    <i className="fas fa-percentage trailing"></i>
                  </MDBInput>
                </td>
                <td>{transformMoney(product.price * product.qty)}
                <br />
                <small>{transformMoney(product.price * product.qty * (1 +(product.taxRate / 100)))}</small>
                
                </td>
                <td><MDBBtn color="danger" size="sm" onClick={() => removeProduct(product.id)}><MDBIcon fas icon="trash" /></MDBBtn></td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>

      <div className="text-center mb-4">
        <MDBBtn color="primary" onClick={() => {
          setShowAddProductModal(true);
        }}>
          <MDBIcon fas icon="plus" className="me-2" />
          {t('Add Product')}
        </MDBBtn>
      </div>

      {/* Connection Section */}
      <h5 className="mb-3">{t('Connect with')}</h5>
      <MDBRow className="mb-4">
        <MDBCol md="6">
          <MDBBtn color="primary" onClick={() => {
            fetchEvents();
            setShowEventModal(true);
          }}>
            {t('Connect with Event')}
          </MDBBtn>
          {selectedEvent && (
            <div className="mt-2 p-2 border rounded">
              {getLocalizedText(selectedEvent.name)}
              <MDBIcon fas icon="times" className="float-end cursor-pointer" onClick={() => setSelectedEvent(null)} />
            </div>
          )}
        </MDBCol>
        <MDBCol md="6">
          <MDBBtn color="primary" onClick={() => {
            setShowBoothModal(true);
          }}>
            {t('Connect with Booth')}
          </MDBBtn>
          {selectedBooth && (
            <div className="mt-2 p-2 border rounded">
              {selectedBooth.company?.name} - {getLocalizedText(selectedBooth.orderLineItem?.product?.name)}
              <MDBIcon fas icon="times" className="float-end cursor-pointer" onClick={() => setSelectedBooth(null)} />
            </div>
          )}
        </MDBCol>
      </MDBRow>

      {/* Notes */}
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBTextArea label={t('Notes')} value={notes} onChange={(e) => setNotes(e.target.value)} rows={4} />
        </MDBCol>
      </MDBRow>

      {/* Total Amount */}
      <MDBRow className="mb-4">
        <MDBCol>
          <h5>{t('Total')}: {transformMoney(calculateTotal())}</h5>
        </MDBCol>
      </MDBRow>

      {/* Action Buttons */}
      <div className="text-end">
        <MDBBtn color="secondary" className="me-2" onClick={() => navigate('/admin/orders')}>
          {t('Cancel')}
        </MDBBtn>
        <MDBBtn 
          color="primary" 
          onClick={createOrder} 
          disabled={isPlacingOrder || selectedProducts.length === 0}
        >
          {isPlacingOrder ? <MDBSpinner size="sm" /> : t('Create Order')}
        </MDBBtn>
      </div>

      
    </MDBCardBody>
  </MDBCard>
  {/* Add Product Modal */}
  <MDBModal open={showAddProductModal} toggle={() => setShowAddProductModal(false)}>
  <MDBModalDialog size="lg">
    <MDBModalContent>
      <MDBModalHeader>
        <MDBModalTitle>{t('Add Product')}</MDBModalTitle>
        <MDBBtn className="btn-close" color="none" onClick={() => setShowAddProductModal(false)} />
      </MDBModalHeader>
      <MDBModalBody>
      <MDBRow className="mb-4">
      <MDBCol>
        <MDBInput
          label={t('Search Product')}
          value={productSearch}
          onChange={(e) => setProductSearch(e.target.value)}
        />
      </MDBCol>
    </MDBRow>
    <MDBTable>
      <MDBTableHead>
        <tr>
          <th>{t('SKU')}</th>
          <th>{t('Name')}</th>
          <th>{t('Price')}</th>
          <th></th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {isLoadingProducts ? (
          <tr>
            <td colSpan="4" className="text-center">
              <MDBSpinner size="sm" />
            </td>
          </tr>
        ) : (
          products.map(product => (
            <tr key={product.id}>
              <td>{product.sku}</td>
              <td>{getLocalizedText(product.name)}</td>
              <td>{transformMoney(product.price)}</td>
              <td>
                <MDBBtn color="primary" size="sm" onClick={() => addProduct(product)}>
                  {t('Add')}
                </MDBBtn>
              </td>
            </tr>
          ))
        )}
      </MDBTableBody>
    </MDBTable>
    <Pagination
      currentPage={productPage}
      totalPages={productTotalPages}
      onPageChange={setProductPage}
    />
      </MDBModalBody>
    </MDBModalContent>
  </MDBModalDialog>
</MDBModal>

    {/* Event Modal */}
    <MDBModal open={showEventModal} onClose={() => setShowEventModal(false)}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{t('Select Event')}</MDBModalTitle>
            <MDBBtn className="btn-close" color="none" onClick={() => setShowEventModal(false)} />
          </MDBModalHeader>
          <MDBModalBody>
            <MDBInput
              label={t('Search Event')}
              value={eventSearch}
              onChange={(e) => setEventSearch(e.target.value)}
              className="mb-4"
            />
            {events.filter(event => 
              getLocalizedText(event.name).toLowerCase().includes(eventSearch.toLowerCase())
            ).map(event => (
              <div
                key={event.id}
                className="p-2 border rounded mb-2 cursor-pointer"
                onClick={() => {
                  setSelectedEvent(event);
                  setShowEventModal(false);
                }}
              >
                {getLocalizedText(event.name)}
              </div>
            ))}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>

    {/* Booth Modal */}
    <MDBModal open={showBoothModal} onClose={() => setShowBoothModal(false)}>
  <MDBModalDialog>
    <MDBModalContent>
      <MDBModalHeader>
        <MDBModalTitle>{t('Select Booth')}</MDBModalTitle>
        <MDBBtn className="btn-close" color="none" onClick={() => setShowBoothModal(false)} />
      </MDBModalHeader>
      <MDBModalBody>
        <MDBInput
          label={t('Search Company')}
          value={boothSearch}
          onChange={(e) => setBoothSearch(e.target.value)}
          className="mb-4"
        />
        {isLoadingBooths ? (
          <div className="text-center">
            <MDBSpinner size="sm" />
          </div>
        ) : (
          booths.map(booth => (
            <div
              key={booth.id}
              className="p-2 border rounded mb-2 cursor-pointer"
              onClick={() => {
                setSelectedBooth(booth);
                setShowBoothModal(false);
              }}
            >
              {booth.company?.name} - {getLocalizedText(booth.orderLineItem?.product?.name)}
            </div>
          ))
        )}
        <Pagination
          currentPage={boothPage}
          totalPages={boothTotalPages}
          onPageChange={setBoothPage}
        />
      </MDBModalBody>
    </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
<MDBModal open={showUserModal} toggle={() => setShowUserModal(false)}>
<MDBModalDialog size="lg">
  <MDBModalContent>
    <MDBModalHeader>
      <MDBModalTitle>{t('Select User')}</MDBModalTitle>
      <MDBBtn 
        className="btn-close" 
        color="none" 
        onClick={() => setShowUserModal(false)} 
      />
    </MDBModalHeader>
    <MDBModalBody>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBInput
            label={t('Search User')}
            value={userSearch}
            onChange={(e) => setUserSearch(e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBTable>
        <MDBTableHead>
          <tr>
            <th></th>
            <th>{t('Name')}</th>
            <th>{t('Email')}</th>
            <th></th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {isLoadingUsers ? (
            <tr>
              <td colSpan="4" className="text-center">
                <MDBSpinner size="sm" />
              </td>
            </tr>
          ) : (
            users.map(user => (
              <tr key={user.id}>
                <td>
                  {user.image?.fileName ? (
                    <img
                      src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${user.image.fileName}`}
                      alt={`${user.firstName} ${user.lastName}`}
                      style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                    />
                  ) : (
                    <MDBIcon fas icon="user-circle" size="2x" />
                  )}
                </td>
                <td>{user.firstName} {user.lastName}</td>
                <td>{user.email}</td>
                <td>
                  <MDBBtn 
                    color="primary" 
                    size="sm" 
                    onClick={() => {
                      setSelectedUser(user);
                      setShowUserModal(false);
                    }}
                  >
                    {t('Select')}
                  </MDBBtn>
                </td>
              </tr>
            ))
          )}
        </MDBTableBody>
      </MDBTable>
      <Pagination
        currentPage={userPage}
        totalPages={userTotalPages}
        onPageChange={setUserPage}
      />
    </MDBModalBody>
  </MDBModalContent>
</MDBModalDialog>
</MDBModal>
</>
  );
};

export default CreateOrder;