import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generateClient } from "aws-amplify/api";
import { 
  MDBBtn, 
  MDBCard, 
  MDBCardBody, 
  MDBCardTitle, 
  MDBIcon, 
  MDBSelect,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBRow,
  MDBCol,
  MDBCollapse
} from "mdb-react-ui-kit";
import { Link, useNavigate } from "react-router-dom";
import { getLocalizedText } from "../../utils/localizedText";

const client = generateClient();

export default function CompanyEvents({ companyId }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [bookedBooths, setBookedBooths] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [showBookingForm, setShowBookingForm] = useState(false);

  // Lade aktive Events
  const getEvents = async () => {
    try {
      const eventsData = await client.graphql({
        query: /* GraphQL */ `
          query ListEvents {
            listEvents(filter: { type: { eq: "fair" }, status: { eq: "active" } }) {
              items {
                id
                name {
                  language
                  text
                }
                startDate
                endDate
                status
              }
            }
          }
        `
      });
      setEvents(eventsData.data.listEvents.items.filter(event => 
        new Date(event.endDate) >= new Date()
      ));
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  // Lade Buchungen der Firma
  const getCompanyBookings = async () => {
    try {
      setLoading(true);
      const bookedBoothsData = await client.graphql({
        query: /* GraphQL */ `
          query SearchBookedBooths($filter: SearchableBookedBoothFilterInput) {
            searchBookedBooths(filter: $filter) {
              items {
                id
                type
                boothNumber
                event {
                  id
                  name {
                    language
                    text
                  }
                  startDate
                  endDate
                }
                orderLineItem {
                  order {
                    id
                    status
                  }
                  product {
                    name {
                      language
                      text
                    }
                  }
                }
                equipment {
                  items {
                    orderLineItem {
                      order {
                        id
                        status
                      }
                      product {
                        name {
                          language
                          text
                        }
                        type
                        subType
                      }
                      qty
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          filter: {
            companyBookedBoothsId: { eq: companyId }
          }
        }
      });

      setBookedBooths(bookedBoothsData.data.searchBookedBooths.items);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEvents();
    if (companyId) {
      getCompanyBookings();
    }
  }, [companyId]);

  const handleBookBooth = () => {
    if (selectedEventId) {
      navigate(`/mycompany/${companyId}/select-booth/${selectedEventId}`);
    }
  };

  // Gruppiere Buchungen nach Events
  const bookingsByEvent = bookedBooths.reduce((acc, booking) => {
    const eventId = booking.event.id;
    if (!acc[eventId]) {
      acc[eventId] = {
        event: booking.event,
        bookings: []
      };
    }
    acc[eventId].bookings.push(booking);
    return acc;
  }, {});

  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle>
          {t("Event Bookings")}
          <MDBBtn 
            color='tertiary' 
            className='mx-2 my-0 py-0' 
            onClick={() => setShowBookingForm(!showBookingForm)}
          >
            {t("Book Booth")}
          </MDBBtn>
        </MDBCardTitle>

        <MDBCollapse open={showBookingForm}>
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <MDBSelect
                label={t("Select Event")}
                data={[
                  { text: t("Select an event..."), value: "" },
                  ...events.map(event => ({
                    text: getLocalizedText(event.name),
                    value: event.id
                  }))
                ]}
                value={selectedEventId}
                onChange={e => setSelectedEventId(e.value)}
              />
            </MDBCol>
            <MDBCol md="6" className="d-flex align-items-end">
              <MDBBtn 
                color="primary" 
                disabled={!selectedEventId}
                onClick={handleBookBooth}
              >
                {t("Continue Booking")}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCollapse>

        {loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">{t("Loading")}</span>
            </div>
          </div>
        ) : (
          Object.values(bookingsByEvent).map(({ event, bookings }) => (
            <div key={event.id} className="mb-4">
              <h5>{getLocalizedText(event.name)}</h5>
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>{t("Booth Type")}</th>
                    <th>{t("Booth Number")}</th>
                    <th>{t("Equipment")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {bookings.map(booking => (
                    <tr 
                      key={booking.id}
                      className={booking.orderLineItem.order.status === "canceled" ? "text-danger" : ""}
                    >
                      <td>
                        {getLocalizedText(booking.orderLineItem.product.name)}
                      </td>
                      <td>{booking.boothNumber}</td>
                      <td>
                        {booking.equipment.items
                          .filter(item => 
                            item.orderLineItem?.product.type !== "event_booth" &&
                            item.orderLineItem?.order.status !== "canceled"
                          )
                          .map((item, index) => (
                            <div key={index}>
                              {item.orderLineItem.qty}x {getLocalizedText(item.orderLineItem.product.name)}
                            </div>
                          ))
                        }
                      </td>
                      <td>
                        <Link to={`/mycompany/${companyId}/edit-booth/${booking.id}`}>
                          <MDBBtn size="sm" color="primary">
                            {t("View")}
                          </MDBBtn>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>
          ))
        )}

        {!loading && Object.keys(bookingsByEvent).length === 0 && (
          <div className="text-center text-muted">
            {t("No bookings found")}
          </div>
        )}
      </MDBCardBody>
    </MDBCard>
  );
}