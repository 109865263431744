import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { generateClient, get } from "aws-amplify/api"
import { MDBTable, MDBTableHead, MDBTableBody, MDBIcon, MDBCollapse, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, 
    MDBModalTitle, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBBadge, 
    MDBInput} from "mdb-react-ui-kit"
import { Link, useParams } from "react-router-dom"
import { getLocalizedText } from "../../../utils/localizedText"

export default function SelectBooth({company}) {

    const client = generateClient()
    const { t, i18n } = useTranslation()

    const { companyid, eventId } = useParams()

    const [event, setEvent] = useState({})
    const[products, setProducts] = useState([])
    const [stockReservations, setStockReservations] = useState([])
    const [usersStockReservations, setUsersStockReservations] = useState([])
    const[loading, setLoading] = useState(true)

    const [selectedProducts, setSelectedProducts] = useState([])
    const [productEquipmentCollapsed, setProductEquipmentCollapsed] = useState({})

    const [selectedProduct, setSelectedProduct] = useState(null)
    const [showProductDetails, setShowProductDetails] = useState(false)

    const [notice, setNotice] = useState(null)
    const [desiredBoothNumbers, setDesiredBoothNumbers] = useState([])


    const handleViewProduct = (productId) => {
        setSelectedProduct(products.find(product => product.id === productId))
        setShowProductDetails(true)
    }


    const getEvent = async () => {
        try {
            const event = await client.graphql({
                query: /* GraphQL */ `
                query GetEvent($id: ID!) {
                  getEvent(id: $id) {
                    id
                    name {
                      language
                      text
                    }
                    bookingNotice {
                        language
                        text
                    }
                    startDate
                    endDate
                    image {
                        fileName
                        alt
                    }
                  }
                }
              `,
                variables: {
                    id: eventId
                }
            })
            console.log("event", event)
            setEvent(event.data.getEvent)
            return event.data.getEvent
        } catch (error) {
            console.warn("error fetching event", error)
        }
    }

    const getProducts = async (eventId) => {
        try {
            const products = await client.graphql({
                query: /* GraphQL */ `
                query ListAvailableProductsInEvents(
                  $filter: ModelAvailableProductsInEventsFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listAvailableProductsInEvents(
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                  ) {
                    items {
                    product {
                      id
                      name {
                        language
                        text
                        __typename
                      }
                      invoiceName {
                        language
                        text
                        __typename
                      }
                      description {
                        language
                        text
                        __typename
                      }
                      shortDescription {
                        language
                        text
                        __typename
                      }
                      includes {
                        language
                        text
                      }
                      sku
                      stock
                      ean
                      type
                      price
                      image {
                        main {
                            fileName
                            alt
                            __typename
                        }
                        __typename
                      }
                      features {
                        __typename
                      }
                      files {
                        url
                        s3Path
                        fileName
                        type
                        name
                        __typename
                      }
                      components {
                        items {
                            qty
                            componentProduct {
                                id
                                name {
                                language
                                    text
                                }
                            }
                        }
                    }
                      allowedBuyerGroups
                      requiredShipping
                      icon
                      createdAt
                      updatedAt
                      bundleBoothAvaiableAccessoriesId
                      bundleBoothIncludedAccessoriesId
                      __typename
                    }
                   
                    __typename
                }
                nextToken
                  }
                }
              `,
                variables: {
                    filter: {
                       eventId: {eq: eventId},
                    },
                    limit: 1000
                }
            })
           
            const resProducts = products.data.listAvailableProductsInEvents.items.map(item => item.product).filter(product => (product.type === "event_booth_partner" || product.type === "event_booth" || product.type === "event_space"))
            setProducts(resProducts.sort((a, b) => a.name[0].text.localeCompare(b.name[0].text)))
            setLoading(false)
            return resProducts
        } catch (error) {
            console.warn("error fetching products", error)
        }
    }

    const getStockReservations = async () => {
        try {
            const stockReservations = await client.graphql({
                query: /* GraphQL */ `
                query ListStockReservations(
                  $filter: ModelStockReservationFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listStockReservations(
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                  ) {
                    items {
                      id
                      companyStockReservationsId
                      productStockReservationsId
                      qty
                    }
                    nextToken
                    __typename
                  }
                }
              `
              ,
                variables: {
                    limit: 1000
                }
            })
            console.log("stockReservations", stockReservations)
            return stockReservations.data.listStockReservations.items
        } catch (error) {
            console.warn("error fetching stockReservations", error)
        }
    }
    
    const loadData = async () => {
        const event = await getEvent()
        const products = await getProducts(event.id)
        const stockReservations = await getStockReservations()
        setEvent(event)
        setUsersStockReservations(stockReservations.filter(stockReservation => stockReservation.companyStockReservationsId === companyid))
        setSelectedProducts(stockReservations.filter(stockReservation => stockReservation.companyStockReservationsId === companyid).map(stockReservation => products.find(product => product.id === stockReservation.productStockReservationsId)))
        setStockReservations(stockReservations)
    }


    useEffect(() => {
        loadData()
    }, [])

    const parsePriceIntToMoney = (price) => {
        return price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
    }

    const handleChangeDesiredBoothNumber = (e, productId) => {
        const value = e.target.value
        setDesiredBoothNumbers({...desiredBoothNumbers, [productId]: value})
    }

  return (
<>
    <div className="container">
        <div className="row">
            <div className="col-12">
            <Link to={`/checkout?${selectedProducts.map((selectedProduct) => `productId=${selectedProduct.id}`).join("&")}&companyId=${companyid}&eventId=${eventId}` + (notice ? `&notes=${notice}` : "")
                + (Object.values(desiredBoothNumbers).filter(Boolean).length > 0 ? `&notes=${Object.keys(desiredBoothNumbers).map((productId) => `Stand[${productId}]:${desiredBoothNumbers[productId]}`).join("&")}` : "")
            } className="float-end">
            <MDBBtn className="float-end" outline color="success" >{t("To Checkout")}
                <MDBBadge className='ms-2' color='primary'>
                    {selectedProducts.length}
                </MDBBadge>
            </MDBBtn>
            </Link>
            <div className="float-start">
            <h1>{t("Select your booth for")} {getLocalizedText(event?.name)}</h1>
            <p>
                {getLocalizedText(event?.bookingNotice)}
            </p>
            </div>
               
            </div>
               
               
            
        </div>
        <div className="row">
            {loading ? (
                <div className="col-12">
                    <p>{t("Loading")}...</p>
                </div>
            ) : (
                <MDBTable>
                    <MDBTableHead>
                        <tr>
                            <th>{t("Booth variant")}</th>
                            <th>{t("Equipment")}</th>
                            <th>{t("Price")}</th>
                            <th>{t("Available")}</th>
                            <th>{t("Action")}</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {products.filter(p => p.type !== "event_booth_partner").sort((a, b) => {
                            // Check if product 'a' is selected
                            const aSelected = selectedProducts.some(selectedProduct => selectedProduct.id === a.id);
                            // Check if product 'b' is selected
                            const bSelected = selectedProducts.some(selectedProduct => selectedProduct.id === b.id);

                            if (aSelected && !bSelected) {
                                return -1; // 'a' is selected, 'b' is not, 'a' should come first
                            } else if (!aSelected && bSelected) {
                                return 1; // 'b' is selected, 'a' is not, 'b' should come first
                            } else {
                                // If both are selected or both are unselected, sort alphabetically by name
                                return getLocalizedText(a.name).localeCompare(getLocalizedText(b.name), undefined, { numeric: true });
                            }
                        }).map((product) => (
                            <tr key={product.id} 
                            className={selectedProducts.find((selectedProduct) => selectedProduct.id === product.id) ? "border-success" : ""}
                            style={selectedProducts.find((selectedProduct) => selectedProduct.id === product.id) ? {borderWidth: "2px", borderColor: "rgba(var(--mdb-success-rgb) !important", backgroundColor: "#E8F5E9"} : {borderWidth: "1px"}}
                           >
                                <td>
                                <div className='d-flex align-items-center' onClick={
                                    () => handleViewProduct(product.id)
                                
                                }>
                                    {product.image?.main?.fileName ?
                                        <img
                                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+product.image.main.fileName}
                                            className=""
                                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                            alt={product.image.alt}
                                        />
                                    :
                                        <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon={product.icon} />
                                                
                                    }
                                    <div className='ms-3'>
                                    <p className='mb-1'>{getLocalizedText(product.name)}</p>
                                    </div>
                                    </div>
                                    {usersStockReservations.find((stockReservation) => stockReservation.productStockReservationsId === product.id) && (
                                        <>
                                        
                                        <MDBBadge color='danger' className="text-break" style={{position: "absolute", fontSize: "13px"}}>{t("This stand has been reserved for your company.")}</MDBBadge>
                                        <div className="my-3"></div>
                                        </>
                                    )}
                                </td>
                                <td>
                                  <MDBBtn color="link" onClick={() => setProductEquipmentCollapsed({...productEquipmentCollapsed, [product.id]: !productEquipmentCollapsed[product.id]})}>
                                    {t("Show equipment")}
                                    </MDBBtn>
                                    <MDBCollapse open={productEquipmentCollapsed[product.id]}>
                                        <ul>
                                            {product.components?.items.map((component) => (
                                                <li key={component.componentProduct.id}>{component.qty}x {component.componentProduct.name[0].text}</li>
                                            ))}
                                            {product.includes?.map((include) => (
                                               
                                                <li key={include.language}> {include.text.split("\n").map((text, index) => (
                                                    <><span key={index}>{text}</span>{index < include.text.split("\n").length - 1 && (<br/>)}</>
                                                ))
                                                }</li>
                                            ))}
                                        </ul>
                                    </MDBCollapse>
                                </td>
                                <td>{parsePriceIntToMoney(product.price)}</td>
                                <td>{product.stock - stockReservations.filter((stockReservation) => stockReservation.productStockReservationsId === product.id).length}</td>
                                <td>
                                {selectedProducts.find((selectedProduct) => selectedProduct.id === product.id) ? 
                                <>
                                    <MDBInput className="mb-2" label={t("Desired booth number")} onChange={(e) => handleChangeDesiredBoothNumber(e, product.id)} />
                                    <MDBBtn color="danger"
                                        onClick={() => {
                                            setSelectedProducts(selectedProducts.filter((selectedProduct) => selectedProduct.id !== product.id))
                                        }}
                                    >
                                        {t("Remove from cart")}
                                    </MDBBtn>
                                </>
                                :
                                product.stock - stockReservations.filter((stockReservation) => stockReservation.productStockReservationsId === product.id).length > 0 || usersStockReservations.find((stockReservation) => stockReservation.productStockReservationsId === product.id) ?

                                <MDBBtn color="success"
                                    onClick={() => {
                                        setSelectedProducts([...selectedProducts, product])
                                    }}

                                >
                                    {t("Add to cart")}
                                </MDBBtn>
                                :
                                <MDBBtn color="grey" disabled>
                                    {t("Sold out")}
                                </MDBBtn>
                            }

                                    
                                   
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            )}
            {loading ? (
                <div className="col-12">
                    <p>{t("Loading")}...</p>
                </div>
            ) : (
                <MDBTable>
                    <MDBTableHead>
                        <tr>
                            <th>{t("Booth variant")}</th>
                            <th>{t("Price")}</th>
                            <th>{t("Notice")}</th>
                            <th>{t("Action")}</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {products.filter(p => p.type === "event_booth_partner").map((product) => (
                            <tr key={product.id} 
                            className={selectedProducts.find((selectedProduct) => selectedProduct.id === product.id) ? "border-success" : ""}
                            style={selectedProducts.find((selectedProduct) => selectedProduct.id === product.id) ? {borderWidth: "2px", borderColor: "rgba(var(--mdb-success-rgb) !important", backgroundColor: "#E8F5E9"} : {borderWidth: "1px"}}
                           >
                                <td>
                                <div className='d-flex align-items-center' onClick={
                                    () => handleViewProduct(product.id)
                                
                                }>
                                    {product.image?.main?.fileName ?
                                        <img
                                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+product.image.main.fileName}
                                            className=""
                                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                            alt={product.image.alt}
                                        />
                                    :
                                        <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon={product.icon} />
                                                
                                    }
                                    <div className='ms-3'>
                                    <p className='mb-1'>{getLocalizedText(product.name)}</p>
                                    </div>
                                    </div>
                                    {usersStockReservations.find((stockReservation) => stockReservation.productStockReservationsId === product.id) && (
                                        <>
                                        
                                        <MDBBadge color='danger' className="text-break" style={{position: "absolute", fontSize: "13px"}}>{t("This stand has been reserved for your company.")}</MDBBadge>
                                        <div className="my-3"></div>
                                        </>
                                    )}
                                </td>
                                
                                <td>{parsePriceIntToMoney(product.price)}</td>
                                <td>
                                    <MDBInput label={t("Notice")} onChange={(e) => setNotice(e.target.value)} />
                                </td>
                                
                                <td>
                                {selectedProducts.find((selectedProduct) => selectedProduct.id === product.id) ? 
                                    <MDBBtn color="danger"
                                            onClick={() => {
                                                setSelectedProducts(selectedProducts.filter((selectedProduct) => selectedProduct.id !== product.id))
                                            }}
                                        >
                                            {t("Remove from cart")}
                                        </MDBBtn>

                                        :
                                        product.stock - stockReservations.filter((stockReservation) => stockReservation.productStockReservationsId === product.id).length > 0 || usersStockReservations.find((stockReservation) => stockReservation.productStockReservationsId === product.id) ?

                                        <MDBBtn color="success"
                                            onClick={() => {
                                                setSelectedProducts([...selectedProducts, product])
                                            }}

                                        >
                                            {t("Add to cart")}
                                        </MDBBtn>
                                        :
                                        <MDBBtn color="grey" disabled>
                                            {t("Sold out")}
                                        </MDBBtn>
                                    }

                                    
                                   
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            )}
        </div>
    </div>
    <MDBModal open={showProductDetails} onClose={() => setShowProductDetails(false)}>
    <MDBModalDialog scrollable size="xl">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Product Details")}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>{getLocalizedText(selectedProduct?.name)}</MDBCardTitle>
                        <MDBCardText>{getLocalizedText(selectedProduct?.description)}</MDBCardText>
                        <MDBCardImage src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+selectedProduct?.image?.main?.fileName} alt={selectedProduct?.image?.main?.alt} position='top' />
                        
                        <MDBCardText>{t("Equipment")}:</MDBCardText>
                        
                        <ul>
                        {selectedProduct?.components?.items.map((component, index) => (
                            <li key={index}>{component.qty}x {component.componentProduct.name[0].text}</li>
                        ))}
                        {selectedProduct?.includes?.filter(i => i.language === i18n.language).map((include, index) => (
                            <li key={index}>{include?.text}</li>
                        ))}
                        </ul>
                       
                       
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
  )
}
